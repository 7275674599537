import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../lib-shared/services/base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WmTaskCommentsService = /** @class */ (function (_super) {
    tslib_1.__extends(WmTaskCommentsService, _super);
    function WmTaskCommentsService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapWorkEndPoint + "/WmTaskComments") || this;
    }
    WmTaskCommentsService.prototype.Gets = function (key, idTask, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "?key=" + key + "&idTask=" + idTask + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    WmTaskCommentsService.prototype.GetDetail = function (Id) {
        var queryString = this.serviceUri + "/GetDetail?id=" + Id;
        return this.defaultGet(queryString);
    };
    // Save(item: any): Promise<ResponseResult> {
    //   return this._http
    //     .post<ResponseResult>(this.serviceUri, item)
    //     .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    // }
    WmTaskCommentsService.prototype.Save2 = function (item) {
        var _this = this;
        return this._http
            .post(this.serviceUri, item)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    WmTaskCommentsService.ngInjectableDef = i0.defineInjectable({ factory: function WmTaskCommentsService_Factory() { return new WmTaskCommentsService(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR)); }, token: WmTaskCommentsService, providedIn: "root" });
    return WmTaskCommentsService;
}(BaseService));
export { WmTaskCommentsService };
