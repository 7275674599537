import * as tslib_1 from "tslib";
import { Subject } from 'rxjs/internal/Subject';
import { environment } from '../../../../environments/environment';
var ComponentBase = /** @class */ (function () {
    function ComponentBase() {
        this._unsubscribeAll = new Subject();
    }
    /**
    * On destroy
    */
    ComponentBase.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    ComponentBase.prototype.getSelectedItemLabel = function (data, value) {
        var item = data.find(function (i) { return i.value === value; });
        if (!item) {
            return '';
        }
        return item.label;
    };
    ComponentBase.prototype.onCopy = function () {
        this._notifierService.showSuccess('Đã copy !');
    };
    ComponentBase.prototype.urlFile = function (path) {
        if (path == null || path == undefined)
            return "/assets/images/no_image.jpg";
        return environment.apiDomain.fileEndpoint + "/" + path;
    };
    ComponentBase.prototype.urlImageAvatar = function (path) {
        if (path == null || path == undefined)
            return "/assets/images/no_image.jpg";
        return environment.apiDomain.fileEndpoint + "/Avatar" + path;
    };
    ComponentBase.prototype.urlImageMedium = function (path) {
        if (path == null || path == undefined)
            return "/assets/images/no_image.jpg";
        return environment.apiDomain.fileEndpoint + "/Medium" + path;
    };
    ComponentBase.prototype.urlImageLarge = function (path) {
        if (path == null || path == undefined)
            return "/assets/images/no_image.jpg";
        return environment.apiDomain.fileEndpoint + "/Large" + path;
    };
    ComponentBase.prototype.urlImageOriginal = function (path) {
        if (path == null || path == undefined)
            return "/assets/images/no_image.jpg";
        return environment.apiDomain.fileEndpoint + "/Original" + path;
    };
    ComponentBase.prototype.getImageAvatar = function (id) {
        return id ? environment.apiDomain.fileEndpoint + "/files/image/Avatar/" + id : null;
    };
    ComponentBase.prototype.getImageMedium = function (id) {
        if (id == null || id == undefined)
            return "/assets/images/no_image.jpg";
        return environment.apiDomain.fileEndpoint + "/files/image/Medium/" + id;
    };
    ComponentBase.prototype.getImageLarge = function (id) {
        if (id == null || id == undefined)
            return "/assets/images/no_image.jpg";
        return environment.apiDomain.fileEndpoint + "/files/image/Large/" + id;
    };
    ComponentBase.prototype.getImageOriginal = function (id) {
        if (id == null || id == undefined)
            return "/assets/images/no_image.jpg";
        return environment.apiDomain.fileEndpoint + "/files/image/Original/" + id;
    };
    ComponentBase.prototype.getAvatar = function (id) {
        if (id) {
            return this.getImageAvatar(id);
        }
    };
    ComponentBase.prototype.GetStatus = function (item) {
        if (item.idStatus === 1000) {
            return "<span class=\"order don-moi\">" + item.status + "</span>";
        }
        if (item.idStatus === 31 || item.idStatus === 32) {
            return "<span class=\"order don-thanhcong\">" + item.status + "</span>";
        }
        if (item.idStatus === 22 || item.idStatus === 33) {
            return "<span class=\"order don-nguyhiem\">" + item.status + "</span>";
        }
        if (item.idStatus === 4 || item.idStatus === 40 || item.idStatus === 41 || item.idStatus === 999) {
            return "<span class=\"order don-thatbai\">" + item.status + "</span>";
        }
        else {
            return "<span class=\"order don-danggiao\">" + item.status + "</span>";
        }
    };
    ComponentBase.prototype.GetActions = function (item) {
        if (!item.idAction) {
            return "";
        }
        if (item.idAction === 1 || item.idAction === 13) {
            return "<span class=\"order don-moi\">" + item.actions + "</span>";
        }
        if (item.idAction === 2 || item.idStatus === 7 || item.idStatus === 3) {
            return "<span class=\"order don-thanhcong\">" + item.actions + "</span>";
        }
        if (item.idAction === 4 || item.idStatus === 8) {
            return "<span class=\"order don-nguyhiem\">" + item.actions + "</span>";
        }
        if (item.idAction === 5 || item.idStatus === 9 || item.idAction === 14 || item.idAction === 10) {
            return "<span class=\"order don-thatbai\">" + item.actions + "</span>";
        }
        else {
            return "<span class=\"order don-danggiao\">" + item.actions + "</span>";
        }
    };
    ComponentBase.prototype.getPriorityOption = function () {
        return [
            { label: 'Chọn độ ưu tiên', value: 0 },
            { label: 'Rất cao', value: 5 },
            { label: 'Cao', value: 4 },
            { label: 'Trung bình', value: 3 },
            { label: 'Thấp', value: 2 },
            { label: 'Rất thấp', value: 1 }
        ];
    };
    ComponentBase.prototype.getStarOption = function () {
        return [
            { label: 'Đánh dấu sao', value: null },
            { label: 'Có', value: true },
            { label: 'Không', value: false }
        ];
    };
    ComponentBase.prototype.getTypeOption = function () {
        return [
            { label: 'Chọn loại công việc', value: 0 },
            { label: 'Task', value: 1 },
            { label: 'Bug', value: 2 }
        ];
    };
    ComponentBase.prototype.GetDate = function () {
        // Lấy ngày hôm nay và ngày hôm qua
        var today = new Date();
        var yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        // Lấy ngày đầu tuần và cuối tuần
        var firstDayOfWeek = new Date(today);
        firstDayOfWeek.setDate(today.getDate() - today.getDay() + 1);
        var lastDayOfWeek = new Date(today);
        lastDayOfWeek.setDate(today.getDate() + (7 - today.getDay()));
        // Lấy ngày đầu tháng và cuối tháng
        var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        // Lấy ngày đầu và cuối của tuần trước
        var firstDaysWeekAgo = new Date(firstDayOfWeek);
        firstDaysWeekAgo.setDate(firstDayOfWeek.getDate() - 7);
        var lastDaysWeekAgo = new Date(lastDayOfWeek);
        lastDaysWeekAgo.setDate(lastDaysWeekAgo.getDate() - 7);
        // Lấy ngày đầu và cuối của tháng trước
        var firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        var lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0); // Ngày cuối của tháng trước
        // Lấy ngày đầu và cuối của 7 ngày trước
        var fSevenDayAgo = new Date(today);
        fSevenDayAgo.setDate(today.getDate() - 7);
        var lSevenDayAgo = new Date(today);
        // Lấy ngày đầu và cuối của 14 ngày trước
        var ftDayAgo = new Date(fSevenDayAgo);
        ftDayAgo.setDate(fSevenDayAgo.getDate() - 7);
        var ltDayAgo = new Date(today);
        // Lấy ngày đầu và cuối của 30 ngày trước
        var fttDayAgo = new Date(today);
        fttDayAgo.setDate(fttDayAgo.getDate() - 30);
        var lttDayAgo = new Date(today);
        return [
            { label: 'Thời gian', formDate: null, toDate: null },
            { label: 'Hôm nay', fromDate: today, toDate: today },
            { label: 'Tuần này', fromDate: firstDayOfWeek, toDate: lastDayOfWeek },
            { label: 'Tháng này', fromDate: firstDayOfMonth, toDate: lastDayOfMonth },
            { label: 'Tuần trước', fromDate: firstDaysWeekAgo, toDate: lastDaysWeekAgo },
            { label: 'Tháng trước', fromDate: firstDayOfLastMonth, toDate: lastDayOfLastMonth }
        ];
    };
    ComponentBase.prototype.downloadFile = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = environment.apiDomain.fileEndpoint + "/" + item.path;
                //if (item.extention == ".jpeg" || item.extention == ".png" || item.extention == ".jpg" || item.extention == ".bmp" || item.extention == ".gif" || item.extention == ".bmp" || item.extention == ".apng" || item.extention == ".ico" || item.extention == ".webp" || item.extention == ".avif")
                if (item.type == 1)
                    url = environment.apiDomain.fileEndpoint + "/Original/" + item.path;
                fetch(url)
                    .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var blob, urlDownload, link;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, response.blob()];
                            case 1:
                                blob = _a.sent();
                                urlDownload = window.URL.createObjectURL(blob);
                                link = document.createElement("a");
                                link.href = urlDownload;
                                link.setAttribute("download", item.fileName);
                                link.click();
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/, false];
            });
        });
    };
    return ComponentBase;
}());
export { ComponentBase };
