import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { BaseService } from '../../lib-shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
var LogSmsService = /** @class */ (function (_super) {
    tslib_1.__extends(LogSmsService, _super);
    function LogSmsService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapFoodEndPoint + "/LogSms") || this;
    }
    LogSmsService.prototype.Gets = function (key, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "?key=" + key + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    LogSmsService.prototype.GetOtp = function (type) {
        var queryString = this.serviceUri + "/GetOtp?type=" + type;
        return this.defaultGet(queryString);
    };
    LogSmsService.prototype.SendSMS = function (topCtv, topOrder, idsProducts, idProduct, name) {
        var queryString = this.serviceUri + "/SendSMS?topCtv=" + topCtv + "&topOrder=" + topOrder + "&idsProducts=" + idsProducts + "&idProduct=" + idProduct + "&name=" + name;
        return this.defaultGet(queryString);
    };
    return LogSmsService;
}(BaseService));
export { LogSmsService };
