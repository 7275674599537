import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Utilities } from '../shared/utilities';
import { SecondPageEditBase } from '../lib-shared/classes/base/second-page-edit-base';
import { UserService } from '../lib-shared/services/user.service';
import { BanksService } from '../dapfood/services/banks.service';
import { LogSmsService } from '../dapfood/services/logsms.service';
import { ProfileCmtComponent } from './profile-cmt.component';
import { ProfileAddressComponent } from './profile-address.component';
import { UserAddressService } from '../dapfood/services/useraddress.service';
var ProfileComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProfileComponent, _super);
    function ProfileComponent(_injector, formBuilder, _userRoleService, _BanksService, _LogSmsService, _UserAddressService) {
        var _this = _super.call(this, null, _injector) || this;
        _this._injector = _injector;
        _this.formBuilder = formBuilder;
        _this._userRoleService = _userRoleService;
        _this._BanksService = _BanksService;
        _this._LogSmsService = _LogSmsService;
        _this._UserAddressService = _UserAddressService;
        _this.USER_INFO_KEY = 'user_info';
        _this.total = 0;
        _this.page = 1;
        _this.limit = 100;
        _this.limitAll = 10000;
        _this.modelEdit = {};
        _this.modelAddress = {};
        _this.isLoading = false;
        _this.isSaving = false;
        _this.isDeleting = false;
        _this.isView = false;
        _this.userTypeDataSource = [];
        _this.captchaUrl = '';
        _this.captchaInvalid = false;
        _this.listAddress = [];
        _this.cols = [];
        _this.idTab = 0;
        return _this;
    }
    ProfileComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.formGroup = new FormGroup({
                    captcha: new FormControl(''),
                    avatar: new FormControl(''),
                    bankNumber: new FormControl(''),
                    bankCardNumber: new FormControl(''),
                    bankFullName: new FormControl(''),
                    bankBranch: new FormControl(''),
                    idBank: new FormControl(''),
                    clientName: new FormControl(''),
                    code: new FormControl(''),
                    name: new FormControl('', Validators.compose([Validators.required])),
                    userName: new FormControl({ value: '', disabled: this.isView }, Validators.compose([Validators.required])),
                    phone: new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^((84|0[3|5|7|8|9])+([0-9]{8})\b)$/)])),
                    email: new FormControl('', Validators.compose([null, Validators.pattern(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/)]))
                });
                this.cols = [
                    {
                        field: 'address',
                        header: 'Địa chỉ',
                        visible: true,
                        width: '50%',
                        sort: true,
                    },
                    {
                        field: 'idWard',
                        header: 'Phường/Xã',
                        visible: true,
                        width: '20%',
                        sort: true
                    },
                    {
                        field: 'idDistrict',
                        header: 'Quận/Huyện',
                        visible: true,
                        width: '20%',
                        sort: true
                    },
                    {
                        field: 'idProvince',
                        header: 'Tỉnh/Thành phố',
                        visible: true,
                        width: '20%',
                        sort: true
                    }, {
                        field: 'isPrimary',
                        header: 'Địa chỉ chính',
                        align: 'center',
                        visible: true,
                        width: '10%',
                        sort: true
                    },
                ];
                return [2 /*return*/];
            });
        });
    };
    ProfileComponent.prototype.loadBanks = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.banks = [];
                this.banks.push({ label: '-- Chọn ngân hàng --', value: -1 });
                this._BanksService.GetShort('').then(function (rs) {
                    if (rs.status) {
                        rs.data.forEach(function (item) {
                            _this.banks.push({ label: item.name, value: item.id });
                        });
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    ProfileComponent.prototype.save = function () {
        // if (!this.item && (!this.modelEdit.password || this.modelEdit.password === '')) {
        //     this._notifierService.showWarning('Vui lòng nhập mật khẩu!');
        //     return;
        // }
        // if (
        //     (!this.modelEdit.password || this.modelEdit.password !== '') &&
        //     this.modelEdit.passwordRepeat !== this.modelEdit.password
        // ) {
        //     this._notifierService.showWarning('Mật khẩu không khớp!');
        //     return;
        // }
        var _this = this;
        this.isSaving = true;
        // if (this.modelEdit.idClient > 0) {
        //     // Update doanh nghiệp
        //     this._userRoleService.UpdateGeneral(this.modelEdit)
        //         .then(response => {
        //             if (response.status) {
        //                 localStorage.removeItem(this._userRoleService.USER_INFO_KEY);
        //                 window.location.reload();
        //                 this._notifierService.showSuccess('Cập nhật người dùng thành công!');
        //                 this.isShow = false;
        //                 this.closePopup.emit();
        //             } else {
        //                 this.isSaving = false;
        //                 this._notifierService.showWarning('Cập nhật người dùng thất bại.\nNội dung lỗi: ' + response.message);
        //             }
        //         })
        //         .catch(error => {
        //             this.isSaving = false;
        //             this._notifierService.showWarning('Có lỗi xảy ra: ' + Utilities.getErrorDescription(error));
        //         });
        // } else {
        // Update CTV
        this._userRoleService.Update(this.modelEdit).then(function (response) {
            if (response.status) {
                localStorage.removeItem(_this._userRoleService.USER_INFO_KEY);
                localStorage.removeItem("access_token");
                localStorage.removeItem("id_token");
                window.location.reload();
                _this._notifierService.showSuccess('Cập nhật người dùng thành công!');
                _this.isShow = false;
                _this.closePopup.emit();
            }
            else {
                _this.isSaving = false;
                _this._notifierService.showWarning('Cập nhật người dùng thất bại.\nNội dung lỗi: ' + response.message);
            }
        })
            .catch(function (error) {
            _this.isSaving = false;
            _this._notifierService.showWarning('Có lỗi xảy ra: ' + Utilities.getErrorDescription(error));
        });
        // }
    };
    ProfileComponent.prototype.saveBank = function () {
        var _this = this;
        this.isSaving = true;
        if (this.modelEdit.userId > 0) {
            this._userRoleService.UpdateBank(this.modelEdit, this.code)
                .then(function (response) {
                if (response.status) {
                    localStorage.removeItem(_this._userRoleService.USER_INFO_KEY);
                    window.location.reload();
                    _this._notifierService.showSuccess('Cập nhật người dùng thành công!');
                    _this.isShow = false;
                    _this.closePopup.emit();
                }
                else {
                    _this.isSaving = false;
                    _this._notifierService.showWarning('Cập nhật người dùng thất bại.\nNội dung lỗi: ' + response.message);
                }
            })
                .catch(function (error) {
                _this.isSaving = false;
                _this._notifierService.showWarning('Có lỗi xảy ra: ' + Utilities.getErrorDescription(error));
            });
        }
    };
    ProfileComponent.prototype.showPopup = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isShow = true;
                this.loadBanks();
                if (id) {
                    this.isLoading = true;
                    this.item = id;
                    this.isView = true;
                    this.formGroup.controls['userName'].disable();
                    this.formGroup.controls['phone'].disable();
                    this.formGroup.controls['email'].disable();
                    this._userRoleService
                        .getCurrent()
                        .then(function (response) {
                        _this.isLoading = false;
                        if (response.status) {
                            _this.modelEdit = response.data;
                            // this.onLoadAddress();
                            // this.modelEdit.isSuperUserBool = this.modelEdit.isSuperUser();
                        }
                        else {
                            if (response.message === 'ERR_USER_USERNAME_NOT_ALLOWED') {
                                _this._notifierService.showWarning('Tên tài khoản không hợp lệ (Tối thiểu 4 ký tự, không chứa ký tự đặc biệt trừ _ và .)');
                            }
                            else {
                                _this._notifierService.showWarning('Xảy ra lỗi không xác định.');
                            }
                        }
                    })
                        .catch(function (error) {
                        _this.isLoading = false;
                        _this._notifierService.showWarning('Có lỗi xảy ra: ' + Utilities.getErrorDescription(error));
                    });
                }
                else {
                    this.captchaUrl = this._userRoleService.getCaptchaUrl();
                    this.togglePopupDelete();
                }
                return [2 /*return*/];
            });
        });
    };
    ProfileComponent.prototype.onLoadAddress = function () {
        var _this = this;
        this._UserAddressService
            .GetsByUserId(this.modelEdit.userId)
            .then(function (rs) {
            _this.isLoading = false;
            if (rs.status) {
                _this.listAddress = rs.data;
            }
        })
            .catch(function (error) {
            _this.isLoading = false;
            _this._notifierService.showWarning('Có lỗi xảy ra: ' + Utilities.getErrorDescription(error));
        });
    };
    ProfileComponent.prototype.togglePopupDelete = function () {
        this.modelEdit = {};
    };
    ProfileComponent.prototype.refreshCaptcha = function () {
        this.captchaUrl = this._userRoleService.getCaptchaUrl();
    };
    ProfileComponent.prototype.GetOTP = function () {
        var _this = this;
        this._LogSmsService.GetOtp(4).then(function (rs) {
            if (rs.status) {
                _this._notifierService.showSuccess(rs.message);
            }
            else {
                _this._notifierService.showError(rs.message);
            }
        });
    };
    ProfileComponent.prototype.onCmt = function (event) {
        event.preventDefault();
        this.cmt.showPopup(this.item);
    };
    ProfileComponent.prototype.onRemoveAddress = function (id) {
        var _this = this;
        this._notifierService.showConfirm('Bạn có chắc muốn xóa bản ghi này?', 'Xóa bản ghi?').then(function (rs) {
            _this._UserAddressService.delete(id).then(function (re) {
                if (re.status) {
                    _this._notifierService.showDeleteDataSuccess();
                    _this.listAddress = _this.listAddress.filter(function (obj) { return obj.id !== id; });
                }
            });
        }).catch(function (err) {
            _this._notifierService.showDeleteDataError();
        });
    };
    ProfileComponent.prototype.handleChange = function (e) {
        this.idTab = e.index;
    };
    ProfileComponent.prototype.onAddress = function (id) {
        var item = {};
        item.id = id;
        item.userId = this.item;
        this.address.showPopup(item);
    };
    return ProfileComponent;
}(SecondPageEditBase));
export { ProfileComponent };
