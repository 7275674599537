/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hq-file-view-comment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/tooltip/tooltip";
import * as i3 from "@angular/common";
import * as i4 from "../file-viewer/file-viewer.component.ngfactory";
import * as i5 from "../file-viewer/file-viewer.component";
import * as i6 from "@angular/forms";
import * as i7 from "./hq-file-view-comment.component";
import * as i8 from "primeng/components/common/confirmationservice";
import * as i9 from "../services/file-upload.service";
import * as i10 from "primeng/components/dynamicdialog/dialogservice";
var styles_HqFileViewCommentComponent = [i0.styles];
var RenderType_HqFileViewCommentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HqFileViewCommentComponent, data: {} });
export { RenderType_HqFileViewCommentComponent as RenderType_HqFileViewCommentComponent };
function View_HqFileViewCommentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.urlImageAvatar(_v.parent.context.$implicit.path), ""); _ck(_v, 1, 0, currVal_0); }); }
function View_HqFileViewCommentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "/assets/images/file-ext/icon", _v.parent.context.$implicit.extention, ".png"); _ck(_v, 1, 0, currVal_0); }); }
function View_HqFileViewCommentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "vs-btn delete ml-5"], ["pTooltip", "X\u00F3a \u1EA3nh"], ["type", "button"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteFile(_v.parent.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.Tooltip, [i1.ElementRef, i1.NgZone], { text: [0, "text"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "__icon fa fa-times"]], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = "X\u00F3a \u1EA3nh"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewOnly; _ck(_v, 0, 0, currVal_0); }); }
function View_HqFileViewCommentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HqFileViewCommentComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HqFileViewCommentComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "overlay"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "fileName"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "tool"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "vs-btn ml-5"], ["pTooltip", "T\u1EA3i xu\u1ED1ng"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 4341760, null, 0, i2.Tooltip, [i1.ElementRef, i1.NgZone], { text: [0, "text"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [["class", "__icon fa fa-download"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HqFileViewCommentComponent_4)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.type == 1); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type != 1); _ck(_v, 4, 0, currVal_1); var currVal_3 = "T\u1EA3i xu\u1ED1ng"; _ck(_v, 10, 0, currVal_3); var currVal_4 = !_co.noDelete; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.fileName; _ck(_v, 7, 0, currVal_2); }); }
export function View_HqFileViewCommentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { validationSummary: 0 }), i1.ɵqud(402653184, 2, { dialog: 0 }), i1.ɵqud(402653184, 3, { formElement: 0 }), i1.ɵqud(402653184, 4, { pUpload: 0 }), i1.ɵqud(402653184, 5, { _FileViewerComponent: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "box-file-upload"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HqFileViewCommentComponent_1)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "file-viewer", [], null, [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onKeydownHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_FileViewerComponent_0, i4.RenderType_FileViewerComponent)), i1.ɵdid(9, 245760, [[5, 4]], 0, i5.FileViewerComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listUploaded; _ck(_v, 7, 0, currVal_0); _ck(_v, 9, 0); }, null); }
export function View_HqFileViewCommentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-hq-file-view-comment", [], null, null, null, View_HqFileViewCommentComponent_0, RenderType_HqFileViewCommentComponent)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.HqFileViewCommentComponent]), i1.ɵdid(2, 245760, null, 0, i7.HqFileViewCommentComponent, [i1.Injector, i8.ConfirmationService, i9.VsFileUploadService, i10.DialogService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var HqFileViewCommentComponentNgFactory = i1.ɵccf("app-hq-file-view-comment", i7.HqFileViewCommentComponent, View_HqFileViewCommentComponent_Host_0, { noDelete: "noDelete", chooseLabel: "chooseLabel", multiple: "multiple", styleButton: "styleButton", defaultImage: "defaultImage", showIconOnly: "showIconOnly", icon: "icon", viewOnly: "viewOnly", fileType: "fileType" }, { closePopup: "closePopup" }, []);
export { HqFileViewCommentComponentNgFactory as HqFileViewCommentComponentNgFactory };
