import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../lib-shared/services/base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WmTasksService = /** @class */ (function (_super) {
    tslib_1.__extends(WmTasksService, _super);
    function WmTasksService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapWorkEndPoint + "/WmTasks") || this;
    }
    // Gets(key: string, memberProject: string, typeWork?: number, isMyTask?: boolean, id?: number, completed?: boolean, idProject?: number, star?: boolean, type?: number, priority?: number, idProjectCol?: number, offset?: number, limit?: number, sortField?: string, isAsc: boolean = false) {
    //    const queryString = `${this.serviceUri}?key=${key}&isMyTask=${isMyTask}&id=${id}&completed=${completed}&idProject=${idProject}&star=${star}&type=${type}&priority=${priority}&idProjectCol=${idProjectCol}&memberProject=${memberProject}&typeWork=${typeWork}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
    //    return this.defaultGet(queryString);
    // }
    WmTasksService.prototype.Gets = function (key, memberProject, typeWork, fromDate, toDate, isMyTask, id, completed, idProject, star, type, priority, idProjectCol, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var fDate;
        var tDate;
        fDate = fromDate ? fromDate.toISOString() : null;
        tDate = toDate ? toDate.toISOString() : null;
        var queryString = this.serviceUri + "?key=" + key + "&isMyTask=" + isMyTask + "&id=" + id + "&completed=" + completed + "&idProject=" + idProject + "&star=" + star + "&type=" + type + "&priority=" + priority + "&idProjectCol=" + idProjectCol + "&memberProject=" + memberProject + "&typeWork=" + typeWork + "&fromDate=" + fDate + "&toDate=" + tDate + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    // GetMyTasks(key: string, UserId?: number, id?: number, completed?: boolean, idProject?: number, star?: boolean, type?: number, priority?: number, idProjectCol?: number, idSprint?: number, offset?: number, limit?: number, sortField?: string, isAsc: boolean = false) {
    //   const queryString = `${this.serviceUri}/GetMyTasks?key=${key}&UserId=${UserId}&id=${id}&completed=${completed}&idProject=${idProject}&star=${star}&type=${type}&priority=${priority}&idProjectCol=${idProjectCol}&idSprint=${idSprint}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
    //   return this.defaultGet(queryString);
    // }
    WmTasksService.prototype.GetsGantts = function (idProject, idSprint) {
        var queryString = this.serviceUri + "/GetsGantts?idProject=" + idProject + "&idSprint=" + idSprint;
        return this.defaultGet(queryString);
    };
    // GetsBoard(key: string, idProject?: number, star?: boolean, type?: number, priority?: number, offset?: number, limit?: number, sortField?: string, isAsc: boolean = false) {
    //    const queryString = `${this.serviceUri}/GetsBoard?key=${key}&idProject=${idProject}&star=${star}&type=${type}&priority=${priority}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
    //    return this.defaultGet(queryString);
    // }
    WmTasksService.prototype.GetsTotalTask = function (id) {
        var queryString = this.serviceUri + "/GetsTotalTask?id=" + id;
        return this.defaultGet(queryString);
    };
    WmTasksService.prototype.ChangeActive = function (Id) {
        var _this = this;
        return this._http
            .post(this.serviceUri + "/ChangeActive?id=" + Id, Id)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    WmTasksService.prototype.ChangeComplete = function (Id) {
        var _this = this;
        return this._http
            .post(this.serviceUri + "/ChangeComplete?id=" + Id, Id)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    WmTasksService.prototype.ChangeStar = function (Id) {
        var _this = this;
        return this._http
            .post(this.serviceUri + "/ChangeStar?id=" + Id, Id)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    WmTasksService.prototype.ChangeSortRow = function (Id, Sort, idSort, type, idProject, idProjectCol) {
        var _this = this;
        return this._http
            .post(this.serviceUri + "/ChangeSortRow?id=" + Id + "&sort=" + Sort + "&idSort=" + idSort + "&idProject=" + idProject + "&idProjectCol=" + idProjectCol + "&type=" + type, null)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    WmTasksService.prototype.ChangeSortColumn = function (Id, Sort, idSort, idProjectCol, type) {
        var _this = this;
        return this._http
            .post(this.serviceUri + "/ChangeSortColumn?id=" + Id + "&sort=" + Sort + "&idSort=" + idSort + "&idProjectCol=" + idProjectCol + "&type=" + type, null)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    WmTasksService.prototype.Save = function (item) {
        var _this = this;
        return this._http
            .post(this.serviceUri, item)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    WmTasksService.ngInjectableDef = i0.defineInjectable({ factory: function WmTasksService_Factory() { return new WmTasksService(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR)); }, token: WmTasksService, providedIn: "root" });
    return WmTasksService;
}(BaseService));
export { WmTasksService };
