import * as tslib_1 from "tslib";
import { EventEmitterService } from './../../../services/eventemitter.service';
import { OnInit, EventEmitter } from "@angular/core";
import { SignalRService } from "../../services/signalr.service";
import { Router } from "@angular/router";
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';
import { ComponentBase } from '../../classes/base/component-base';
import * as moment from 'moment';
var VsAppNotificationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(VsAppNotificationComponent, _super);
    function VsAppNotificationComponent(_signalRService, _userService, _notificationService, _EventEmitterService, _router) {
        var _this = _super.call(this) || this;
        _this._signalRService = _signalRService;
        _this._userService = _userService;
        _this._notificationService = _notificationService;
        _this._EventEmitterService = _EventEmitterService;
        _this._router = _router;
        _this.maxNotification = 10;
        _this.totalUnRead = 0;
        _this.data = [];
        _this.url = "";
        _this.urldetail = "";
        _this.notifications = [];
        _this.pageIndex = 0;
        _this.pageSize = 10;
        _this.infiniteScrollThrottle = 150;
        _this.infiniteScrollDistance = 2;
        _this.direction = '';
        _this.topic = ['friends'];
        _this.openMenu = new EventEmitter();
        return _this;
    }
    VsAppNotificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userId = this._userService.getBasicUserInfo().userId;
        this.topic.push(this.userId.toString());
        var ra = this._userService.getBasicUserInfo().roleassign;
        if (ra) {
            var roleassign = JSON.parse(ra);
            roleassign.forEach(function (element) {
                _this.topic.push('role_' + element.toLowerCase());
            });
        }
        this._EventEmitterService.notification.subscribe(function (item) { return _this.notifyTrigger(item); });
        this.refreshNotification();
    };
    VsAppNotificationComponent.prototype.notifyTrigger = function (data) {
        if (data.type === 1) {
            this.notifications.unshift(data);
            // if (this.notifications.length > this.maxNotification) {
            //     this.notifications.pop();
            // }
            this.totalUnRead++;
        }
        else if (data.type === 3) {
            if (data.data) {
                if (data.data.topic)
                    this._signalRService.subscribeViewCode(data.data.topic, this.notifyTrigger.bind(this));
            }
        }
    };
    VsAppNotificationComponent.prototype.refreshNotification = function () {
        var _this = this;
        this._notificationService.getsByUserId()
            .then(function (result) {
            _this.notifications = result.data;
            _this.totalUnRead = result.totalRecord;
        });
    };
    VsAppNotificationComponent.prototype.onScrollDown = function () {
        this.pageIndex += 1;
        this.direction = 'down';
        this.addItems();
    };
    VsAppNotificationComponent.prototype.addItems = function () {
        var _this = this;
        this._notificationService.getsByUserId(this.pageIndex * this.pageSize)
            .then(function (result) {
            result.data.forEach(function (element) {
                _this.notifications.push(element);
            });
            _this.totalUnRead = result.totalRecord;
        });
    };
    VsAppNotificationComponent.prototype.readAll = function (event) {
        var _this = this;
        event.preventDefault();
        event.stopPropagation();
        this._notificationService.readAll().then(function (rs) {
            _this.notifications.forEach(function (element) {
                if (element.read != null) {
                    element.read.push({ userId: _this.userId });
                }
                else {
                    element.read = [{ userId: _this.userId }];
                }
            });
        });
        this.totalUnRead = 0;
    };
    VsAppNotificationComponent.prototype.readById = function (data) {
        var _this = this;
        this._notificationService.read(data.id).then(function (rs) {
            if (data.read != null) {
                data.read.push({ userId: _this.userId });
            }
            else {
                data.read = [{ userId: _this.userId }];
            }
        });
    };
    VsAppNotificationComponent.prototype.getClassRead = function (item) {
        var _this = this;
        var classRead = "un-read";
        if (item.read != null) {
            item.read.forEach(function (x) {
                if (x.userId.toString() === _this.userId.toString()) {
                    classRead = "";
                }
            });
        }
        return classRead;
    };
    VsAppNotificationComponent.prototype.isReaded = function (item) {
        var _this = this;
        if (item.read != null) {
            item.read.forEach(function (x) {
                if (x.userId.toString() === _this.userId.toString()) {
                    return true;
                }
            });
        }
        return false;
    };
    VsAppNotificationComponent.prototype.goToLink = function (item) {
        if (!this.isReaded(item)) {
            this.readById(item);
            this.totalUnRead--;
        }
        var link = item.message.link;
        if (!link)
            return;
        var rawLink = "";
        var queryString = {};
        var hash = "";
        if (link.indexOf("?") > -1) {
            var linkArr = link.split("?");
            rawLink = linkArr[0];
            if (linkArr[0].indexOf("#") > -1) {
                var linkArrHash = linkArr[0].split("#");
                queryString = linkArrHash[0];
                hash = linkArrHash[1];
            }
            else {
                queryString = linkArr[0];
            }
        }
        else {
            if (link.indexOf("#") > -1) {
                var linkArr = link.split("#");
                rawLink = linkArr[0];
                hash = linkArr[1];
            }
            else {
                rawLink = link;
            }
        }
        rawLink = rawLink + '?' + moment().unix(); //  (new Date().getUTCMilliseconds());    // Hoặc dùng moment().valueOf()      moment().unix()
        this._router.navigate([rawLink], {
            queryParams: queryString,
            fragment: hash,
        });
    };
    VsAppNotificationComponent.prototype.headerClick = function (event) {
        event.preventDefault();
        event.stopPropagation();
    };
    VsAppNotificationComponent.prototype.openMenuEvent = function ($event) {
        this.openMenu.next($event);
        this._notificationService.viewAll();
        this.totalUnRead = 0;
    };
    return VsAppNotificationComponent;
}(ComponentBase));
export { VsAppNotificationComponent };
