import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { VsBaseService } from './psbase.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { retry, catchError } from 'rxjs/operators';
var TNThietLapCauHinhService = /** @class */ (function (_super) {
    tslib_1.__extends(TNThietLapCauHinhService, _super);
    function TNThietLapCauHinhService(http, injector, httpModule) {
        var _this = _super.call(this, http, environment.apiDomain.dapFoodEndPoint + "/ThietLapCauHinh", injector) || this;
        _this.httpModule = httpModule;
        return _this;
    }
    TNThietLapCauHinhService.prototype.getCauHinh = function (ma) {
        var _this = this;
        var url = this.svUrl + "/getCauHinh/" + ma;
        return this.http
            .get(url)
            .pipe(retry(3), catchError(function (err) { return _this.handleError(err, _this.injector); })).map(function (rs) { return rs; }).toPromise();
    };
    TNThietLapCauHinhService.prototype.setCauHinh = function (cauhinh) {
        var _this = this;
        var url = this.svUrl + "/setCauHinh";
        return this.http
            .post(url, cauhinh)
            .pipe(retry(3), catchError(function (err) { return _this.handleError(err, _this.injector); })).map(function (rs) { return rs; }).toPromise();
    };
    return TNThietLapCauHinhService;
}(VsBaseService));
export { TNThietLapCauHinhService };
