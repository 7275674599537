import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ComponentBase } from '../../../classes/base/component-base';
var FileViewerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FileViewerComponent, _super);
    function FileViewerComponent() {
        var _this = _super.call(this) || this;
        _this.showFileDialog = false;
        _this.listFile = [];
        _this.file = {
            filenName: '',
            path: '',
            type: 1,
            url: ''
        };
        return _this;
    }
    FileViewerComponent.prototype.ngOnInit = function () {
    };
    FileViewerComponent.prototype.showPopup = function (FileName) {
        // this.file.path = FileName;
        this.file.fileName = '';
        this.file.type = 1;
        this.file.url = this.getImageOriginal(FileName);
        // const fileType = this.getFileType(this.FileName);
        // const documentType = this.getDocumentType(fileType);
        // if (documentType == 'image') {
        // this.isImage = true;
        // }
        // else {
        //     this.isImage = false;
        //     this.initDocumentViewer(fileType, documentType);
        // }
        this.showFileDialog = true;
    };
    FileViewerComponent.prototype.showPopupObject = function (file, data) {
        if (data === void 0) { data = []; }
        if (data.length > 0) {
            this.listFile = data.filter(function (x) { return x.type == 1; }); // Chỉ view file image và video
        }
        this.file = file;
        if (this.file.type == 3)
            this.file.url = "" + environment.apiDomain.fileEndpoint + this.file.path;
        else
            this.file.url = this.urlImageOriginal(this.file.path);
        this.showFileDialog = true;
    };
    FileViewerComponent.prototype.onNext = function () {
        if (this.file == null || this.file.path == "")
            return;
        var currentIndex = this.listFile.indexOf(this.file);
        var nextIndex = (currentIndex + 1) % this.listFile.length;
        this.file = this.listFile[nextIndex];
        this.file.url = this.urlImageOriginal(this.file.path);
    };
    FileViewerComponent.prototype.onPrev = function () {
        if (this.file == null || this.file.path == "")
            return;
        var currentIndex = this.listFile.indexOf(this.file);
        var prevIndex = 0;
        if (currentIndex <= 0)
            prevIndex = this.listFile.length - 1;
        else
            prevIndex = currentIndex - 1;
        // prevIndex = (currentIndex - 1) % this.listFile.length;
        this.file = this.listFile[prevIndex];
        this.file.url = this.urlImageOriginal(this.file.path);
    };
    FileViewerComponent.prototype.onKeydownHandler = function (event) {
        // event.preventDefault();
        if (event.key === 'ArrowLeft') {
            this.onPrev();
        }
        if (event.key === 'ArrowRight') {
            this.onNext();
        }
    };
    return FileViewerComponent;
}(ComponentBase));
export { FileViewerComponent };
