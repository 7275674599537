import * as tslib_1 from "tslib";
import { EventEmitter, Injector, ElementRef, OnInit } from '@angular/core';
import { ComponentBase } from './component-base';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidationSummaryComponent } from '../../components/validation-summary/validation-summary.component';
import { NotifierService } from '../../services/notifier.service';
import { Dialog } from 'primeng/dialog';
import { CommonService } from '../../services/common.service';
import { FileUpload } from 'primeng/primeng';
import { CONFIG_CALENDAR_VIETNAMESE } from '../../../config/calendar.config';
import { VsMySettingService } from '../../../services/ccmysetting.service';
import { VsMySetting } from '../../../models/ccmysetting';
var SecondPageEditBase = /** @class */ (function (_super) {
    tslib_1.__extends(SecondPageEditBase, _super);
    function SecondPageEditBase(_baseService, _injector) {
        var _this = _super.call(this) || this;
        _this._baseService = _baseService;
        _this._injector = _injector;
        _this.vi = CONFIG_CALENDAR_VIETNAMESE;
        _this.isShow = false;
        _this.submitting = false;
        _this.openPopupDelete = false;
        _this.itemDetail = {};
        _this.formSchema = [];
        _this.uploadedFiles = [];
        _this.popupHeader = '';
        _this.formGroup = new FormGroup({});
        _this.limitAll = 10000;
        _this.arrTrangThai = [
            { label: 'Sử dụng', value: 1 },
            { label: 'Không Sử dụng', value: 2 }
        ];
        _this.closePopup = new EventEmitter();
        _this.mySetting = new VsMySetting();
        _this._mySettingService = _this._injector.get(VsMySettingService);
        _this._notifierService = _this._injector.get(NotifierService);
        _this.popupId = _this._injector.get(CommonService).guid();
        _this.mySetting = _this._mySettingService.getCurrentSetting();
        return _this;
        // this._masterDataService.getConfigByCode('SizeOfFile')
        //     .then(response => {
        //         if (response) {
        //             this.limitSize = Number(response) * 1000 * 1000;
        //         }
        //     });
    }
    SecondPageEditBase.prototype.ngOnInit = function () {
        // this._masterDataService.getConfigByCode('SizeOfFile')
        //     .then(response => {
        //         if (response) {
        //             this.limitSize = Number(response) * 1000 * 1000;
        //         }
        //     });
    };
    SecondPageEditBase.prototype.save = function () {
        this.submitting = true;
        if (this.formGroup.invalid) {
            this.validationSummary.showValidationSummary();
            this.showValidateMessage();
            this.submitting = false;
            this.scrollToTop();
            return;
        }
        this.onBeforeSave();
        if (this.itemDetail['id']) {
            this.onUpdate();
        }
        else {
            this.onInsert();
        }
    };
    SecondPageEditBase.prototype.showValidateMessage = function () {
    };
    SecondPageEditBase.prototype.onBeforeSave = function () {
    };
    SecondPageEditBase.prototype.onAfterSave = function () {
    };
    SecondPageEditBase.prototype.showPopup = function (data) {
        if (data === void 0) { data = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isShow = true;
                        this.openPopupDelete = false;
                        return [4 /*yield*/, this.onShowPopup(data)];
                    case 1:
                        _a.sent();
                        setTimeout(function () {
                            var viewRef = document.getElementById(_this.popupId);
                            if (viewRef != null) {
                                var input = viewRef.querySelector('input');
                                if (input) {
                                    input.focus();
                                }
                                else {
                                    var texateara = viewRef.querySelector('textarea');
                                    if (texateara) {
                                        texateara.focus();
                                    }
                                }
                            }
                            if (_this.dialog) {
                                _this.dialog.center();
                            }
                        }, 500);
                        return [2 /*return*/];
                }
            });
        });
    };
    SecondPageEditBase.prototype.onShowPopup = function (data) {
        var _this = this;
        this.onReset();
        this.onBeforeShowPopUp();
        if (data > 0) {
            this._baseService.getDetail(data)
                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.itemDetail = response.data;
                    this.onAfterShowPopUp();
                    return [2 /*return*/];
                });
            }); }, function (error) {
                _this._notifierService.showHttpUnknowError();
            });
        }
    };
    SecondPageEditBase.prototype.onReset = function () {
        this.itemDetail = {};
    };
    SecondPageEditBase.prototype.onAfterShowPopUp = function () {
    };
    SecondPageEditBase.prototype.onBeforeShowPopUp = function () {
    };
    SecondPageEditBase.prototype.closePopupMethod = function (data) {
        this.isShow = false;
        this.closePopup.next(data);
    };
    SecondPageEditBase.prototype.getMaxWidthDialog = function () {
        return (window.innerHeight - 200).toString() + 'px';
    };
    SecondPageEditBase.prototype.onInsert = function () {
        var _this = this;
        this._baseService.post(this.itemDetail)
            .then(function (response) {
            _this.closePopupMethod(true);
            _this._notifierService.showInsertDataSuccess();
            _this.onAfterSave();
            _this.submitting = false;
        }, function (error) {
            _this._notifierService.showInsertDataFailed();
            _this.submitting = false;
        });
    };
    SecondPageEditBase.prototype.onUpdate = function () {
        var _this = this;
        this._baseService.put(this.itemDetail['id'].toString(), this.itemDetail)
            .then(function (response) {
            _this.closePopupMethod(true);
            _this._notifierService.showUpdateDataSuccess();
            _this.onAfterSave();
            _this.submitting = false;
        }, function (error) {
            _this._notifierService.showUpdateDataFailed();
            _this.submitting = false;
        });
    };
    SecondPageEditBase.prototype.cancel = function () {
        this.closePopupMethod(null);
    };
    SecondPageEditBase.prototype.buildFormGroup = function (formGroup, formSchema) {
        var formGroupD = formGroup || this.formGroup;
        var formSchemaD = formSchema || this.formSchema;
        for (var i = 0; i < formSchemaD.length; i++) {
            var schemaItem = formSchemaD[i];
            var formControl = new FormControl('');
            if (schemaItem.control && schemaItem.control.defaultValue !== undefined) {
                this.itemDetail[schemaItem.field] = schemaItem.control.defaultValue;
                if (!schemaItem.visible && schemaItem.control.defaultValue) {
                    formControl.setValue(schemaItem.control.defaultValue);
                }
            }
            if (schemaItem.validators && schemaItem.validators.required) {
                formControl.setValidators(Validators.required);
            }
            formGroupD.addControl(schemaItem.field, formControl);
        }
    };
    SecondPageEditBase.prototype.resetForm = function () {
        if (this.validationSummary) {
            this.validationSummary.resetErrorMessages();
        }
        if (this.pUpload) {
            this.pUpload.clear();
        }
        this.formGroup.reset();
        this.itemDetail = {};
        this.uploadedFiles = [];
        this.submitting = false;
    };
    SecondPageEditBase.prototype.delete = function (id) {
        var _this = this;
        this._notifierService.showDeleteConfirm().then(function (rs) {
            _this._baseService.delete(id)
                .then(function (response) {
                _this.closePopupMethod({});
                _this._notifierService.showDeleteDataSuccess();
            }, function (error) {
                _this._notifierService.showDeleteDataError();
            });
        });
    };
    SecondPageEditBase.prototype.scrollToTop = function () {
        var _this = this;
        if (this.formElement) {
            setTimeout(function () {
                _this.formElement.nativeElement.scrollIntoView();
            }, 500);
        }
    };
    SecondPageEditBase.prototype.isValid = function (submitting) {
        if (submitting === void 0) { submitting = true; }
        if (this.formGroup.invalid) {
            this.validationSummary.showValidationSummary();
            this.submitting = false;
            return;
        }
        this.validationSummary.resetErrorMessages();
        return this.formGroup.valid;
    };
    SecondPageEditBase.prototype.togglePopupDelete = function () {
        this.openPopupDelete = !this.openPopupDelete;
    };
    SecondPageEditBase.prototype.resetDialogPosition = function () {
        var _this = this;
        window.setTimeout(function () {
            _this.dialog.center();
        });
    };
    SecondPageEditBase.prototype.getMaxDialogHeight = function () {
        return (window.innerHeight - 200).toString() + 'px';
    };
    SecondPageEditBase.prototype.handleTabChange = function (event) {
        this.resetDialogPosition();
    };
    SecondPageEditBase.prototype.convertDataToOptions = function (options, data, fieldName) {
        if (data.length > 0) {
            data.map(function (item) {
                return options.push({ label: item[fieldName], value: item.id });
            });
        }
    };
    return SecondPageEditBase;
}(ComponentBase));
export { SecondPageEditBase };
