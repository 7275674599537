<app-comment-form-g [item]="item" (handleSubmit)="addComment({form: $event, parentId: null})"></app-comment-form-g>

<app-comment-g
  *ngFor="let comment of getRootComments()"
  [comment]="comment"
  [activeComment]="activeComment"
  [replies]="getReplies(comment.id)"
  (setActiveComment)="setActiveComment($event)"
  (deleteComment)="deleteComment($event)"
  (updateComment)="updateComment($event)"
  (addComment)="addComment($event)"
  [currentUser]="currentUser">
</app-comment-g>


