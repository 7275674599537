import * as tslib_1 from "tslib";
import { OnInit, Injector } from '@angular/core';
import { SecondPageEditBase } from '../lib-shared/classes/base/second-page-edit-base';
import { UserService } from '../lib-shared/services/user.service';
import { WmTaskCommentsService } from '../work/services/WmTaskComments.service';
var CommentsGComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommentsGComponent, _super);
    function CommentsGComponent(_injector, _userService, _WmTaskCommentsService) {
        var _this = _super.call(this, null, _injector) || this;
        _this._injector = _injector;
        _this._userService = _userService;
        _this._WmTaskCommentsService = _WmTaskCommentsService;
        _this.activeComment = null;
        _this.modelEdit = {
            id: 0,
            content: '',
            idParent: null,
        };
        _this.listComment = {
            id: 0,
            message: '',
            idParent: null,
        };
        return _this;
    }
    CommentsGComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this._userService.getCurrentUser()];
                    case 1:
                        _a.currentUser = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CommentsGComponent.prototype.showData = function (idTask) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.item.id = idTask;
                        return [4 /*yield*/, this.getComments()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getRootComments()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CommentsGComponent.prototype.getComments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._WmTaskCommentsService.Gets('', this.item.id, 0, 100, '', false)
                            .then(function (res) {
                            if (res.status) {
                                _this.item.comments = res.data.reverse();
                                _this.item.remainingComments = res.totalRecord - 1;
                            }
                            else {
                                _this._notifierService.showError(res.message);
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CommentsGComponent.prototype.getRootComments = function () {
        if (this.item && this.item.comments) {
            return this.item.comments.filter(function (comment) { return comment.idParent === null; });
        }
        return [];
    };
    CommentsGComponent.prototype.getReplies = function (commentId) {
        return this.item.comments
            .filter(function (comment) { return comment.idParent === commentId; })
            .sort(function (a, b) {
            return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
        });
    };
    CommentsGComponent.prototype.setActiveComment = function (activeComment) {
        this.activeComment = activeComment;
    };
    CommentsGComponent.prototype.deleteComment = function (commentId) {
        var _this = this;
        this._notifierService.showConfirm('Xoá bình luận này', 'Bạn có chắc muốn xóa ?').then(function (rs) {
            _this._WmTaskCommentsService.delete(commentId).then(function (re) {
                if (re.status) {
                    _this.item.comments = _this.item.comments.filter(function (comment) { return comment.id !== commentId; });
                    _this._notifierService.showSuccess(re.message);
                }
                else
                    _this._notifierService.showError(re.message);
            });
        });
    };
    CommentsGComponent.prototype.updateComment = function (_a) {
        var _this = this;
        var form = _a.form, commentId = _a.commentId;
        var index = this.item.comments.findIndex(function (x) { return x.id === commentId; });
        if (index !== -1) {
            this.item.comments[index].message = form.message;
            this.item.comments[index].image = form.image;
            this.modelEdit.id = commentId;
            this.modelEdit.message = form.message;
            this.modelEdit.image = form.image;
            this._WmTaskCommentsService.Save2(this.modelEdit).then(function (re) {
                if (re.status) {
                    _this.item.comments[index].updatedDate = re.data.commentInfo.updatedDate;
                    _this.activeComment = null;
                }
                else {
                    console.log('Error:', re.message);
                }
            });
        }
        else {
            console.log('ko bình luận được trên id:', commentId);
        }
    };
    CommentsGComponent.prototype.addComment = function (_a) {
        var _this = this;
        var form = _a.form, parentId = _a.parentId;
        this.modelEdit = {};
        this.modelEdit.idTask = this.item.id;
        this.modelEdit.message = form.message;
        this.modelEdit.idParent = parentId;
        this.modelEdit.image = form.image;
        this._WmTaskCommentsService.Save2(this.modelEdit).then(function (re) {
            if (re.status) {
                _this.item.comments.push(tslib_1.__assign({}, re.data.commentInfo, { countLike: 0, userLiked: false, likeUser: [] }));
                _this.activeComment = null;
                _this._notifierService.showSuccess('Cập nhật thành công');
            }
            else {
                _this._notifierService.showError(re.message);
            }
        });
    };
    return CommentsGComponent;
}(SecondPageEditBase));
export { CommentsGComponent };
