import * as i0 from "@angular/core";
var ListHelperService = /** @class */ (function () {
    function ListHelperService() {
    }
    ListHelperService.prototype.getLimitByScreen = function () {
        var windowHeight = window.innerHeight;
        if (windowHeight > 707) {
            return 15;
        }
        if (windowHeight > 1920) {
            return 50;
        }
        return 10;
    };
    ListHelperService.ngInjectableDef = i0.defineInjectable({ factory: function ListHelperService_Factory() { return new ListHelperService(); }, token: ListHelperService, providedIn: "root" });
    return ListHelperService;
}());
export { ListHelperService };
