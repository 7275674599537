import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../services/base.service';
import { environment } from '../../../../../environments/environment';
import { catchError } from 'rxjs/operators';
var FoldersService = /** @class */ (function (_super) {
    tslib_1.__extends(FoldersService, _super);
    function FoldersService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.fileEndpoint + "/Folders") || this;
    }
    FoldersService.prototype.GetRootFolder = function () {
        var queryString = this.serviceUri + "/GetRootFolder";
        return this.defaultGet(queryString);
    };
    FoldersService.prototype.GetByParentId = function (parentId, objectId, tableName, service, isPrivated) {
        var queryString = this.serviceUri + "/GetByParentId/" + parentId + "?objectId=" + objectId + "&tableName=" + tableName + "&service=" + service + "&isPrivated=" + isPrivated;
        return this.defaultGet(queryString);
    };
    FoldersService.prototype.Gets = function (key, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "?key=" + key + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    FoldersService.prototype.public = function (id) {
        var _this = this;
        return this._http
            .post(this.serviceUri + "/Public/" + id, null)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    return FoldersService;
}(BaseService));
export { FoldersService };
