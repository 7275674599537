import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../lib-shared/services/base.service';
var OmiCallsService = /** @class */ (function (_super) {
    tslib_1.__extends(OmiCallsService, _super);
    function OmiCallsService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapFoodEndPoint + "/OmiCalls") || this;
    }
    OmiCallsService.prototype.OmicallAgentDelete = function (identify_info) {
        var queryString = this.serviceUri + "/AgentDelete/" + identify_info;
        return this.defaultGet(queryString);
    };
    OmiCallsService.prototype.OmicallAgentInvite = function (item) {
        var _this = this;
        return this._http
            .post(this.serviceUri + '/AgentInvite', item)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    OmiCallsService.prototype.SaveOmiCallLogs = function (item) {
        var _this = this;
        return this._http
            .post(this.serviceUri + '/SaveOmiCallLogs', item)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    OmiCallsService.prototype.ContactsAdd = function (item) {
        var _this = this;
        return this._http
            .post(this.serviceUri + '/ContactsAdd', item)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    OmiCallsService.prototype.ContactsPhone = function (phone) {
        var queryString = this.serviceUri + "/ContactsPhone/" + phone;
        return this.defaultGet(queryString);
    };
    OmiCallsService.prototype.ContactsDelete = function (contactid) {
        var queryString = this.serviceUri + "/ContactsDelete/" + contactid;
        return this.defaultGet(queryString);
    };
    OmiCallsService.prototype.UpdateCallTransactionsById = function (Uuid) {
        var queryString = this.serviceUri + "/UpdateCallTransactionsById/" + Uuid;
        return this.defaultGet(queryString);
    };
    OmiCallsService.prototype.UpdateNoteOmicallLog = function (Uuid, Note) {
        var queryString = this.serviceUri + "/UpdateNoteOmicallLog/" + Uuid + "/" + Note;
        return this.defaultGet(queryString);
    };
    OmiCallsService.prototype.MultiUpdateCallTransactions = function (item) {
        var _this = this;
        return this._http
            .post(this.serviceUri + '/MultiUpdateCallTransactions', item)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    return OmiCallsService;
}(BaseService));
export { OmiCallsService };
