import * as tslib_1 from "tslib";
import { FormBuilder, Validators } from '@angular/forms';
import { OnInit, EventEmitter, Injector, ElementRef } from '@angular/core';
import { SecondPageEditBase } from '../../lib-shared/classes/base/second-page-edit-base';
import { User } from '../../lib-shared/models/user';
import { UserService } from '../../lib-shared/services/user.service';
import { ConfigurationService } from '../../lib-shared/services/configuration.service';
var CommentFormGComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommentFormGComponent, _super);
    function CommentFormGComponent(_userService, _injector, _configurationService, fb) {
        var _this = _super.call(this, null, _injector) || this;
        _this._userService = _userService;
        _this._injector = _injector;
        _this._configurationService = _configurationService;
        _this.fb = fb;
        _this.initialText = '';
        _this.handleSubmit = new EventEmitter();
        _this.isOpenEmoji = false;
        _this.apiUrl = '';
        _this.maxFileSize = 0;
        _this.currentUser = new User;
        return _this;
    }
    CommentFormGComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentUser = this._userService.getBasicUserInfo();
        this.form = this.fb.group({
            message: [this.initialText],
            image: [this.fileAttachment]
        });
        this.form.get('image').valueChanges.subscribe(function (attachment) {
            if (attachment) {
                _this.form.get('message').clearValidators();
                _this.form.get('message').updateValueAndValidity();
            }
            else {
                _this.form.get('message').setValidators(Validators.required);
                _this.form.get('message').updateValueAndValidity();
            }
        });
    };
    CommentFormGComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.textareaRef.nativeElement.addEventListener('input', function () {
            _this.adjustTextareaHeight();
        });
    };
    CommentFormGComponent.prototype.openEmoji = function () {
        console.log(!this.isOpenEmoji);
    };
    CommentFormGComponent.prototype.adjustTextareaHeight = function () {
        var textarea = this.textareaRef.nativeElement;
        if (!this.form.get('message').value && !this.initialText) {
            textarea.style.height = 'auto';
        }
        else {
            textarea.style.height = textarea.scrollHeight + "px";
        }
    };
    CommentFormGComponent.prototype.submit = function (event) {
        event.preventDefault();
        this.onSubmit();
    };
    CommentFormGComponent.prototype.onSubmit = function () {
        this.handleSubmit.emit(this.form.value);
        this.form.reset();
    };
    CommentFormGComponent.prototype.onPaste = function (file) {
        this.filePaste = file;
    };
    return CommentFormGComponent;
}(SecondPageEditBase));
export { CommentFormGComponent };
