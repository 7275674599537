<!-- <div class="circle" [ngStyle]="{'background-color':  model.color, 'width': size + 'px', 'height': size + 'px'}">  -->
<div (mouseenter)="showPopup[indexTable] = true" (mouseleave)="showPopup[indexTable] = false" class="circle" [ngStyle]="{'background-color':  model.color, 'width': size + 'px', 'height': size + 'px'}">
	<img *ngIf="!showInitials" src="{{getImageAvatar(model.avatar)}}" [ngStyle]="{'width': size + 'px', 'height': size + 'px'}">
	<div *ngIf="showInitials" class="initials" [ngStyle]="{'font-size': fontsize + 'px'}">
		{{ model.initName }}
	</div>
    <div class="popup_user" *ngIf="showPopup[indexTable] && !mouseLeave && isShowMess" [ngClass]="{'popup_top': indexTable > 3, 'popup_bottom': indexTable <= 3}">
		<popup-user-info [userId]="model.userId" [showData]="showPopup[indexTable]"></popup-user-info>
    </div>
</div> 
