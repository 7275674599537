import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../../lib-shared/services/base.service';
var DistrictsService = /** @class */ (function (_super) {
    tslib_1.__extends(DistrictsService, _super);
    function DistrictsService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapFoodEndPoint + "/Districts") || this;
    }
    DistrictsService.prototype.GetShort = function (idProvince) {
        var queryString = this.serviceUri + "/GetShort?idProvince=" + idProvince;
        return this.defaultGet(queryString);
    };
    return DistrictsService;
}(BaseService));
export { DistrictsService };
