import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
var NotificationService = /** @class */ (function (_super) {
    tslib_1.__extends(NotificationService, _super);
    function NotificationService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.notificationEndpoint + "/Notifications") || this;
    }
    NotificationService.prototype.search = function (type, time) {
        var url = this.serviceUri + "/" + type + "/" + time;
        return this.defaultGet(url);
    };
    NotificationService.prototype.getFavourite = function (id, type, time) {
        var url = this.serviceUri + "/Favourite/" + type + "/" + time + "/" + id;
        return this.defaultGet(url);
    };
    NotificationService.prototype.unRead = function (type) {
        var url = this.serviceUri + "/UnRead/" + type;
        return this.defaultGet(url);
    };
    NotificationService.prototype.getById = function (id) {
        var url = this.serviceUri + "/Read/" + id;
        return this.defaultGet(url);
    };
    NotificationService.prototype.readAll = function () {
        var url = this.serviceUri + "/ReadAll";
        return this.defaultGet(url);
    };
    NotificationService.prototype.viewAll = function () {
        var url = this.serviceUri + "/viewAll";
        return this.defaultGet(url);
    };
    NotificationService.prototype.getsByUserId = function (offset, limit, type, fromDate, toDate) {
        if (offset === void 0) { offset = 0; }
        if (limit === void 0) { limit = 10; }
        if (type === void 0) { type = 1; }
        if (fromDate === void 0) { fromDate = ''; }
        if (toDate === void 0) { toDate = ''; }
        var apiUrl = this.serviceUri + "/GetsByUserId?type=" + type + "&fromDate=" + fromDate + "&toDate=" + toDate + "&offset=" + offset + "&limit=" + limit;
        return this.defaultGet(apiUrl);
    };
    NotificationService.prototype.read = function (id) {
        var apiUrl = this.serviceUri + "/Read/" + id;
        return this.defaultPost(apiUrl, {});
    };
    return NotificationService;
}(BaseService));
export { NotificationService };
