/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./comments-g.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./comment-g/comment-g.component.ngfactory";
import * as i3 from "./comment-g/comment-g.component";
import * as i4 from "../work/services/WmTaskCommentLikes.service";
import * as i5 from "./comment-form-g/comment-form-g.component.ngfactory";
import * as i6 from "./comment-form-g/comment-form-g.component";
import * as i7 from "../lib-shared/services/user.service";
import * as i8 from "../lib-shared/services/configuration.service";
import * as i9 from "@angular/forms";
import * as i10 from "@angular/common";
import * as i11 from "./comments-g.component";
import * as i12 from "../work/services/WmTaskComments.service";
var styles_CommentsGComponent = [i0.styles];
var RenderType_CommentsGComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommentsGComponent, data: {} });
export { RenderType_CommentsGComponent as RenderType_CommentsGComponent };
function View_CommentsGComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-comment-g", [], null, [[null, "setActiveComment"], [null, "deleteComment"], [null, "updateComment"], [null, "addComment"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("setActiveComment" === en)) {
        var pd_0 = (_co.setActiveComment($event) !== false);
        ad = (pd_0 && ad);
    } if (("deleteComment" === en)) {
        var pd_1 = (_co.deleteComment($event) !== false);
        ad = (pd_1 && ad);
    } if (("updateComment" === en)) {
        var pd_2 = (_co.updateComment($event) !== false);
        ad = (pd_2 && ad);
    } if (("addComment" === en)) {
        var pd_3 = (_co.addComment($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_CommentGComponent_0, i2.RenderType_CommentGComponent)), i1.ɵdid(1, 245760, null, 0, i3.CommentGComponent, [i1.Injector, i4.WmTaskCommentLikesService], { comment: [0, "comment"], replies: [1, "replies"], activeComment: [2, "activeComment"], currentUser: [3, "currentUser"] }, { deleteComment: "deleteComment", updateComment: "updateComment", addComment: "addComment", setActiveComment: "setActiveComment" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.getReplies(_v.context.$implicit.id); var currVal_2 = _co.activeComment; var currVal_3 = _co.currentUser; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_CommentsGComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { validationSummary: 0 }), i1.ɵqud(402653184, 2, { dialog: 0 }), i1.ɵqud(402653184, 3, { formElement: 0 }), i1.ɵqud(402653184, 4, { pUpload: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-comment-form-g", [], null, [[null, "handleSubmit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("handleSubmit" === en)) {
        var pd_0 = (_co.addComment({ form: $event, parentId: null }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_CommentFormGComponent_0, i5.RenderType_CommentFormGComponent)), i1.ɵdid(5, 4440064, null, 0, i6.CommentFormGComponent, [i7.UserService, i1.Injector, i8.ConfigurationService, i9.FormBuilder], { item: [0, "item"] }, { handleSubmit: "handleSubmit" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CommentsGComponent_1)), i1.ɵdid(7, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.getRootComments(); _ck(_v, 7, 0, currVal_1); }, null); }
export function View_CommentsGComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-comments-g", [], null, null, null, View_CommentsGComponent_0, RenderType_CommentsGComponent)), i1.ɵdid(1, 245760, null, 0, i11.CommentsGComponent, [i1.Injector, i7.UserService, i12.WmTaskCommentsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommentsGComponentNgFactory = i1.ɵccf("app-comments-g", i11.CommentsGComponent, View_CommentsGComponent_Host_0, { item: "item" }, { closePopup: "closePopup" }, []);
export { CommentsGComponentNgFactory as CommentsGComponentNgFactory };
