import * as tslib_1 from "tslib";
import { OnInit, Injector } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ConfirmationService, DialogService, DynamicDialogRef } from 'primeng/primeng';
import { environment } from '../../../../../environments/environment';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { VsFileUploadService } from '../services/file-upload.service';
import { SecondPageEditBase } from '../../../classes/base/second-page-edit-base';
import { VsFileViewerComponent } from '../vs-file-viewer/vs-file-viewer.component';
var HqFileViewCommentComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HqFileViewCommentComponent, _super);
    function HqFileViewCommentComponent(_injector, confirmationService, _fileUploadService, dialogService) {
        var _this = _super.call(this, null, _injector) || this;
        _this._injector = _injector;
        _this.confirmationService = confirmationService;
        _this._fileUploadService = _fileUploadService;
        _this.dialogService = dialogService;
        _this.disabled = false;
        _this.progressValue = 0;
        _this.noDelete = false;
        _this.chooseLabel = 'Chọn file';
        _this.multiple = false;
        _this.styleButton = false;
        _this.defaultImage = false;
        _this.showIconOnly = false;
        _this.icon = 'fa fa-upload';
        _this.viewOnly = false;
        _this.fileType = ''; // '' , image/* , video/* , 
        _this.onChangeControl = function (obj) { };
        _this.onTouched = function () { };
        return _this;
    }
    HqFileViewCommentComponent.prototype.ngOnInit = function () {
    };
    HqFileViewCommentComponent.prototype.writeValue = function (obj) {
        var _this = this;
        this.listUploaded = [];
        if (obj) {
            this.listUploaded = obj.split(",");
            this._fileUploadService.GetsByIds(obj).then(function (rs) {
                if (rs.status) {
                    _this.listUploaded = rs.data;
                }
            }).catch(function (err) {
                console.log('Có lỗi xảy ra, vui lòng thử lại ' + err);
            });
        }
    };
    HqFileViewCommentComponent.prototype.registerOnChange = function (fn) {
        this.onChangeControl = fn;
    };
    HqFileViewCommentComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    HqFileViewCommentComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    HqFileViewCommentComponent.prototype.deleteFile = function (id) {
        var _this = this;
        this.confirmationService.confirm({
            message: 'Bạn có chắc chắn muốn xóa file này không ?',
            accept: function () {
                var fileToRemove = _this.listUploaded.findIndex(function (x) { return x.id === id; });
                _this.listUploaded.splice(fileToRemove, 1);
                var files = _this.listUploaded.map(function (x) { return x.id; }).join(",");
                _this.onChangeControl(files);
                _this.writeValue(files);
            }
        });
    };
    HqFileViewCommentComponent.prototype.viewFile = function (item) {
        // Nếu file là hình ảnh hoặc vido thì dùng FileViewer, Nếu là tài liệu thì dùng VsFileViewer
        if (item.type == 1 || item.type == 3)
            this._FileViewerComponent.showPopupObject(item, this.listUploaded);
        else {
            this.ref = this.dialogService.open(VsFileViewerComponent, {
                data: item,
                showHeader: true,
                header: item.fileName,
                width: '100%',
                height: 'calc(100vh - 0px)',
                styleClass: "vs-modal vs-file-viewer",
                contentStyle: { 'overflow': 'auto' },
                baseZIndex: 1010,
                closeOnEscape: true
            });
        }
    };
    HqFileViewCommentComponent.prototype.downloadFile = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = environment.apiDomain.fileEndpoint + "/" + item.path;
                //if (item.extention == ".jpeg" || item.extention == ".png" || item.extention == ".jpg" || item.extention == ".bmp" || item.extention == ".gif" || item.extention == ".bmp" || item.extention == ".apng" || item.extention == ".ico" || item.extention == ".webp" || item.extention == ".avif")
                if (item.type == 1)
                    url = environment.apiDomain.fileEndpoint + "/Original/" + item.path;
                fetch(url)
                    .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var blob, urlDownload, link;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, response.blob()];
                            case 1:
                                blob = _a.sent();
                                urlDownload = window.URL.createObjectURL(blob);
                                link = document.createElement("a");
                                link.href = urlDownload;
                                link.setAttribute("download", item.fileName);
                                link.click();
                                return [2 /*return*/];
                        }
                    });
                }); });
                return [2 /*return*/, false];
            });
        });
    };
    HqFileViewCommentComponent.prototype.downloadFileAll = function () {
        var _this = this;
        this.listUploaded.forEach(function (element) {
            _this.downloadFile(element);
        });
    };
    return HqFileViewCommentComponent;
}(SecondPageEditBase));
export { HqFileViewCommentComponent };
