import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/primeng';
import { SecondPageIndexBase } from '../lib-shared/classes/base/second-page-index-base';
import { LogActions, Status } from '../work/work.enum';
import { WmLogWorksService } from '../work/services/WmLogWorks.service';
import { TaskViewGComponent } from '../task-view-g/task-view-g.component';

@Component({
  selector: 'app-log-works-g',
  templateUrl: './log-works-g.component.html',
  styleUrls: ['./log-works-g.component.scss']
})
export class LogWorksGComponent extends SecondPageIndexBase implements OnInit {
  @Input() idTask?: number;
  @Input() idProject: number;
  @Input() myTask: boolean;
  @Input() isDashboard: boolean = false;

  searchModel: any = {
    key: '',
    isActive: null,
    isDelete: false
  };

  offset: number = 0;

  typeStatus: number = -1;

  logActions = LogActions;
  Status = Status;
  ref: DynamicDialogRef;
  showPopup: boolean[] = [];

  constructor(
    protected _injector: Injector,
    private _WmLogWorks: WmLogWorksService,
    public dialogService: DialogService,
  ) {
    super(null, _injector);
  }

  async ngOnInit() {
    if (this.isDashboard) {
      this.idTask = 0;
      this.offset = 0;
      this.limit = 50;
    }
    else {
      this.offset = (this.page - 1) * this.limit;
    }
    await this.getData();
  }

  async showData(idTask, idProject, myTask) {
    this.idTask = idTask;
    this.idProject = idProject;
    this.myTask = myTask;
    await this.getData();
  }

  async getData() {
    this.isLoading = true;
    await this._WmLogWorks.Gets(
      this.idProject,
      this.idTask,
      this.offset,
      this.limit,
      this.sortField,
      this.isAsc
    ).then(rs => {
      if (rs.status) {
        this.dataSource = rs.data;
        this.total = rs.totalRecord;
        // for (let i = 0; i < this.total; i++) {
        //   this.showPopup[i] = false;
        // }
      }
    });
    this.resetBulkSelect();
    this.isLoading = false;
  }

  async getDataSort(type) {
    this.isLoading = true;
    await this._WmLogWorks.GetsSort(
      this.idProject,
      this.idTask,
      type,
      (this.page - 1) * this.limit,
      this.limit,
      this.sortField,
      this.isAsc
    ).then(rs => {
      if (rs.status) {
        this.dataSource = rs.data;
        this.total = rs.totalRecord;

        // for (let i = 0; i < this.total; i++) {
        //   this.showPopup[i] = false;
        // }
      }
    });
    this.resetBulkSelect();
    this.isLoading = false;
  }

  onViewTag(type) {
    if (type !== this.typeStatus) {
      this.typeStatus = type;
      if (type !== -1) {
        this.getDataSort(type);
      } else {
        this.getData();
      }
    }
  }

  onSearch() {
    this.getData();
  }


  onDelete(id: number) {
    this._notifierService.showConfirm('Xóa bản ghi?', 'Bạn có chắc muốn xóa bản ghi này?').then(rs => {
      this._WmLogWorks.delete(id).then(re => {
        if (re.status) {
          this._notifierService.showDeleteDataSuccess();
          this.dataSource = this.dataSource.filter(x => x.id !== id);
        }
      });
    }).catch(err => {
      this._notifierService.showDeleteDataError();
    });
  }

  showTaskView(item: any) {
    this.ref = this.dialogService.open(TaskViewGComponent, {
      data: {
        id: item.task.id,
        idProject: item.idProject
      },
      showHeader: false,
      header: '',
      width: '95%',
      height: 'calc(100vh - 50px)',
      styleClass: "vs-modal",
      contentStyle: { 'overflow': 'auto' }, //'max-height': 'calc(100vh - 180px);', 
      baseZIndex: 1001,
      closeOnEscape: true
    });

    this.ref.onClose.subscribe((re: any) => {
      if (re != null) {
        this.isLoading = false;
      }
    });

  }

  getAvatar(avatar) {
    if (avatar != null)
      return this.getImageAvatar(avatar);
    else
      return `/assets/images/rocket.svg`;
  }
  getAvatarUser(avatar) {
    if (avatar != null)
      return this.getImageAvatar(avatar);
    else
      return `/assets/images/avatar.jpg`;
  }
}
