
<div class="box-file-upload">
          <div *ngFor="let item of listUploaded" class="item">
            <a *ngIf="item.type == 1">
                <img src="{{urlImageAvatar(item.path)}}" />
            </a>
            <a *ngIf="item.type != 1">
                <img src="/assets/images/file-ext/icon{{item.extention}}.png" />
            </a>
            <div class="overlay" (click)="viewFile(item) ">
                <div class="fileName">
                    {{item.fileName}}
                </div>
              </div>
              <div class="tool">
                <button type="button" (click)="downloadFile(item)" pTooltip="Tải xuống" class="vs-btn ml-5">
                    <span class="__icon fa fa-download"></span>
                </button>
                <button *ngIf="!noDelete" type="button" (click)="deleteFile(item.id)" pTooltip="Xóa ảnh" [hidden]="viewOnly" class="vs-btn delete ml-5">
                  <span class="__icon fa fa-times"></span>
              </button>
                </div>
          </div>
<file-viewer></file-viewer>
