import { ElementRef, OnInit, AfterContentChecked } from '@angular/core';
import { AuthorizeService } from '../services/authorize.service';
import { PermissionTypes } from '../vs-constants';
import { UserService } from '../services/user.service';
var AuthorizeDirective = /** @class */ (function () {
    function AuthorizeDirective(_el, _authorizeService, _userService) {
        this._el = _el;
        this._authorizeService = _authorizeService;
        this._userService = _userService;
        this._permissionTypes = PermissionTypes.CONTROL;
        this.isNavigationPermission = false;
        this._element = _el.nativeElement;
    }
    Object.defineProperty(AuthorizeDirective.prototype, "authorize", {
        set: function (value) {
            this._permissionRequired = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthorizeDirective.prototype, "permissionType", {
        set: function (value) {
            this._permissionTypes = value;
        },
        enumerable: true,
        configurable: true
    });
    AuthorizeDirective.prototype.ngOnInit = function () {
        if (this._userService.getBasicUserInfo().issuperuser)
            return;
        // Xử lý riêng cho trường hợp navigation
        if (this._permissionRequired && this.isNavigationPermission) {
            if (this._permissionRequired instanceof Array) {
                for (var i = 0; i < this._permissionRequired.length; i++) {
                    if (this._permissionRequired[i]) {
                        this._permissionRequired[i] = JSON.parse(this._permissionRequired[i].replace(/'/g, '"'));
                    }
                }
            }
            else {
                this._permissionRequired = JSON.parse(this._permissionRequired.toString().replace(/'/g, '"'));
            }
        }
        if ((this._permissionRequired instanceof String || typeof this._permissionRequired === 'string') && !this.isNavigationPermission) {
            var inheritedElement = document.querySelector("[key=\"" + this._permissionRequired + "\"]");
            if (inheritedElement == null) {
                if (this.keepHTML) {
                    this._label = document.createElement('span');
                    if (this._element.parentElement) {
                        this._element.parentElement.appendChild(this._label);
                        this._element.remove();
                    }
                }
                else {
                    this._element.remove();
                }
            }
        }
        else if (this._permissionRequired instanceof Array) {
            var isUnValidated = false;
            for (var _i = 0, _a = this._permissionRequired; _i < _a.length; _i++) {
                var item = _a[_i];
                if (this._authorizeService.validated(item, this._permissionTypes)) {
                    isUnValidated = true;
                    break;
                }
            }
            if (!isUnValidated) {
                if (this.keepHTML) {
                    this._label = document.createElement('span');
                    if (this._element.parentElement) {
                        this._element.parentElement.appendChild(this._label);
                        this._element.remove();
                    }
                }
                else {
                    this._element.remove();
                }
            }
        }
        else if (this._permissionRequired instanceof Object || this.isNavigationPermission) {
            if (!this._authorizeService.validated(this._permissionRequired, this._permissionTypes)) {
                if (this.keepHTML) {
                    this._label = document.createElement('span');
                    if (this._element.parentElement) {
                        this._element.parentElement.appendChild(this._label);
                        this._element.remove();
                    }
                }
                else {
                    this._element.remove();
                }
            }
        }
    };
    AuthorizeDirective.prototype.ngAfterContentChecked = function () {
        if (this._label && !this._bind && this._element.innerHTML !== '' && this.keepHTML) {
            this._label.innerHTML = this._element.innerHTML;
            this._bind = true;
        }
    };
    return AuthorizeDirective;
}());
export { AuthorizeDirective };
