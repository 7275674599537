import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { CommonService } from './common.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./common.service";
var EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
var EXCEL_EXTENSION = '.xlsx';
var DOCX_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
var DOCX_EXTENSION = '.docx';
// const apiEndpoint = environment.apiDomain.inspectorateEndPoint + '/Export';
var ExportService = /** @class */ (function () {
    function ExportService(http, _commonService, _injector) {
        this.http = http;
        this._commonService = _commonService;
        this._injector = _injector;
        this._http = http;
    }
    ExportService.prototype.saveAsExcelFile = function (buffer, fileName) {
        var data = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
    };
    ExportService.prototype.export = function (data, cols, fileName) {
        // const obj = {
        //     data: data,
        //     cols: cols
        // };
        // return this._http
        //     .post(`${apiEndpoint}/export`, obj, { responseType: 'blob' as 'json' })
        //     .pipe(catchError((err: HttpErrorResponse) => this._commonService.handleError(err, this._injector)))
        //     .subscribe(res => {
        //         this.saveAsExcelFile(res, fileName);
        //     });
    };
    ExportService.prototype.saveAsDocFile = function (buffer, fileName) {
        var data = new Blob([buffer], {
            type: DOCX_TYPE
        });
        FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + DOCX_EXTENSION);
    };
    ExportService.ngInjectableDef = i0.defineInjectable({ factory: function ExportService_Factory() { return new ExportService(i0.inject(i1.HttpClient), i0.inject(i2.CommonService), i0.inject(i0.INJECTOR)); }, token: ExportService, providedIn: "root" });
    return ExportService;
}());
export { ExportService };
