import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import * as JWT from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseResult } from '../models/response-result';
import { environment } from '../../../environments/environment';
import { VsAuthenService } from '../auth/authen.service';
import { User } from '../models/user';
import { BaseService } from './base.service';
import { VsCommonService } from './vs-common.service';
var UserService = /** @class */ (function (_super) {
    tslib_1.__extends(UserService, _super);
    function UserService(http, injector, _oauthService, _commonService) {
        var _this = _super.call(this, http, injector, environment.apiDomain.dapFoodEndPoint + "/Users") || this;
        _this._oauthService = _oauthService;
        _this._commonService = _commonService;
        _this.USER_INFO_KEY = 'user_info';
        _this.authenticationEndpoint = environment.apiDomain.authenticationEndpoint + "/users";
        _this.authorizationEndpoint = environment.apiDomain.authorizationEndpoint + "/users";
        _this.RETRY_COUNT = 0;
        _this.REPLAY_COUNT = 10;
        _this.tokenReceived = new Observable();
        _this.promises = [];
        _this.isReady = false;
        return _this;
    }
    UserService.prototype.returnPromises = function () {
        while (this.promises.length > 0) {
            var pr = this.promises.pop();
            var accessToken = this._oauthService.getAccessToken();
            var decoded = JWT(accessToken);
            this.getCurrentUser();
        }
        this.isReady = true;
    };
    UserService.prototype.handleError = function (error, injector) {
        // console.error('Có lỗi xảy ra', error);
        if (error.status === 401) {
            var authenService = injector.get(VsAuthenService);
            authenService.logout();
        }
        else {
        }
        return Promise.reject(error);
    };
    UserService.prototype.changePassword = function (item) {
        var apiUrl = this.authenticationEndpoint + "/ChangePassword";
        return this.defaultPost(apiUrl, item);
    };
    UserService.prototype.Create = function (obj) {
        var queryString = this.authenticationEndpoint + "/Create";
        return this.defaultPost(queryString, obj);
    };
    UserService.prototype.Update = function (obj) {
        var queryString = this.authenticationEndpoint + "/Update";
        return this.defaultPost(queryString, obj);
    };
    UserService.prototype.CreateCB = function (obj) {
        var queryString = this.authenticationEndpoint + "/CreateCB";
        return this.defaultPost(queryString, obj);
    };
    UserService.prototype.UpdateCB = function (obj) {
        var queryString = this.authenticationEndpoint + "/UpdateCB";
        return this.defaultPost(queryString, obj);
    };
    UserService.prototype.getUsersByIdClient = function (id) {
        var url = this.serviceUri + "/getUsersByIdClient?idClient=" + id;
        return this.defaultGet(url);
    };
    UserService.prototype.getUsersByIdShop = function (id) {
        var url = this.serviceUri + "/getUsersByIdShop?idShop=" + id;
        return this.defaultGet(url);
    };
    UserService.prototype.UpdateGeneral = function (obj) {
        var queryString = this.serviceUri + "/UpdateGeneral";
        return this.defaultPost(queryString, obj);
    };
    UserService.prototype.UpdateOmicall = function (obj) {
        var queryString = this.serviceUri + "/UpdateOmicall";
        return this.defaultPost(queryString, obj);
    };
    UserService.prototype.UpdateBank = function (obj, code) {
        var queryString = this.serviceUri + "/UpdateBank?code=" + code;
        return this.defaultPost(queryString, obj);
    };
    UserService.prototype.UpdateCode = function (obj) {
        var queryString = this.serviceUri + "/UpdateCode";
        return this.defaultPost(queryString, obj);
    };
    UserService.prototype.UpdateCmt = function (obj) {
        var queryString = this.serviceUri + "/UpdateCmt";
        return this.defaultPost(queryString, obj);
    };
    UserService.prototype.GetsListKOL = function (key) {
        var url = this.serviceUri + "/GetsListKOL?key=" + key;
        return this.defaultGet(url);
    };
    UserService.prototype.getCaptchaUrl = function () {
        return this.authenticationEndpoint + "/captcha?" + Date.now() + "&logSession=" + sessionStorage.getItem('log_session_key');
    };
    UserService.prototype.getBasicUserInfo = function () {
        var crrUser = new User();
        var accessToken = this._oauthService.getAccessToken();
        if (accessToken) {
            var claims = JWT(accessToken);
            if (claims) {
                crrUser.displayName = claims.displayname;
                crrUser.email = claims.email;
                // crrUser.fullName = claims.firstname.concat(' ', claims.lastname);
                crrUser.issuperuser = claims.issuperuser.toLowerCase() === 'true';
                crrUser.permissions = claims.permissions;
                crrUser.roleassign = claims.roleassign;
                crrUser.scope = claims.scope;
                crrUser.userId = claims.sub;
                crrUser.userName = claims.username;
                crrUser.avatar = claims.avatar;
                crrUser.roleassign = claims.roleassign; //list roles
            }
        }
        return crrUser;
    };
    UserService.prototype.getCurrentUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var crrUser, accessToken, claims;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Lấy thông tin từ cache nếu có
                        if (localStorage.getItem(this.USER_INFO_KEY)) {
                            try {
                                return [2 /*return*/, JSON.parse(localStorage.getItem(this.USER_INFO_KEY))];
                            }
                            catch (e) { }
                        }
                        crrUser = new User();
                        accessToken = this._oauthService.getAccessToken();
                        if (!accessToken) return [3 /*break*/, 2];
                        claims = JWT(accessToken);
                        if (!claims) return [3 /*break*/, 2];
                        crrUser.displayName = claims.displayname;
                        crrUser.email = claims.email;
                        // crrUser.fullName = claims.firstname.concat(' ', claims.lastname);
                        crrUser.issuperuser = claims.issuperuser.toLowerCase() === 'true';
                        crrUser.permissions = claims.permissions;
                        crrUser.scope = claims.scope;
                        crrUser.userId = parseInt(claims.sub);
                        crrUser.idClient = claims.idClient;
                        crrUser.idShop = claims.idShop;
                        crrUser.idPortal = claims.idPortal;
                        crrUser.userName = claims.username;
                        crrUser.avatar = claims.avatar; //this._commonService.getFileUrl(claims.avatar);
                        crrUser.roleassign = claims.roleassign; //list roles
                        return [4 /*yield*/, this.getCurrent().then(function (rs) {
                                if (rs.status) {
                                    crrUser.email = rs.data.email;
                                    crrUser.avatar = rs.data.avatar;
                                    crrUser.id = rs.data.id;
                                    crrUser.idClient = rs.data.idClient;
                                    crrUser.idShop = rs.data.idShop;
                                    crrUser.name = rs.data.name;
                                    crrUser.phone = rs.data.phone;
                                    crrUser.idProvince = rs.data.idProvince;
                                    crrUser.idDistrict = rs.data.idDistrict;
                                    crrUser.idWard = rs.data.idWard;
                                    crrUser.address = rs.data.address;
                                    crrUser.isDeleted = rs.data.isDeleted;
                                    crrUser.idBank = rs.data.idBank;
                                    crrUser.bankFullName = rs.data.bankFullName;
                                    crrUser.bankNumber = rs.data.bankNumber;
                                    crrUser.bankCardNumber = rs.data.bankCardNumber;
                                    crrUser.bankBranch = rs.data.bankBranch;
                                    crrUser.idBankNavigation = rs.data.idBankNavigation;
                                    crrUser.isOmiCall = rs.data.isOmiCall;
                                    crrUser.omiCallSipUser = rs.data.omiCallSipUser;
                                    crrUser.omiCallSecretKey = rs.data.omiCallSecretKey;
                                    crrUser.omiCallDomain = rs.data.omiCallDomain;
                                    localStorage.setItem(_this.USER_INFO_KEY, JSON.stringify(crrUser));
                                }
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, crrUser];
                }
            });
        });
    };
    UserService.prototype.PickSupportCTV = function (userId) {
        var queryString = this.serviceUri + "/PickSupportCTV?userId=" + userId;
        return this.defaultGet(queryString);
    };
    UserService.prototype.RemoveSupportCTV = function (userId) {
        var queryString = this.serviceUri + "/RemoveSupportCTV?userId=" + userId;
        return this.defaultGet(queryString);
    };
    UserService.prototype.PickKol = function (userId) {
        var queryString = this.serviceUri + "/PickKol?userId=" + userId;
        return this.defaultGet(queryString);
    };
    UserService.prototype.RemovePickKol = function (userId) {
        var queryString = this.serviceUri + "/RemovePickKol?userId=" + userId;
        return this.defaultGet(queryString);
    };
    UserService.prototype.RemoveSupportCTVForAdmin = function (userId) {
        var queryString = this.serviceUri + "/RemoveSupportCTVForAdmin?userId=" + userId;
        return this.defaultGet(queryString);
    };
    UserService.prototype.onSetLogout = function (userId, approve) {
        var queryString = this.serviceUri + "/SetLogout?userId=" + userId + "&approve=" + approve;
        return this.defaultGet(queryString);
    };
    UserService.prototype.Approved = function (userId, approve) {
        var queryString = this.serviceUri + "/Approved";
        var item = { userId: userId, approve: approve };
        return this.defaultPost(queryString, item);
    };
    UserService.prototype.ApprovedCmt = function (userId, approve) {
        var queryString = this.serviceUri + "/ApprovedCmt";
        var item = { userId: userId, approve: approve };
        return this.defaultPost(queryString, item);
    };
    UserService.prototype.AdminClient = function (userId, approve) {
        var queryString = this.serviceUri + "/AdminClient";
        var item = { userId: userId, approve: approve };
        return this.defaultPost(queryString, item);
    };
    UserService.prototype.GetByListId = function (listId) {
        var queryString = this.serviceUri + "/GetByListId?listId=" + listId;
        return this.defaultGet(queryString);
    };
    UserService.prototype.AutoComplete = function (key, offset, limit) {
        var queryString = this.serviceUri + "/AutoComplete?key=" + key + "&offset=" + offset + "&limit=" + limit;
        return this.defaultGet(queryString);
    };
    UserService.prototype.SearchNotInClient = function (key, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "/SearchNotInClient?key=" + key + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    UserService.prototype.SearchInClient = function (key, idClient, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "/SearchInClient?key=" + key + "&idClient=" + idClient + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    UserService.prototype.gets = function (key, status, offset, limit, sortField, isAsc, isApproved, idClient) {
        if (isAsc === void 0) { isAsc = false; }
        if (isApproved === void 0) { isApproved = -1; }
        if (idClient === void 0) { idClient = -1; }
        var queryString = this.serviceUri + "?key=" + key + "&status=" + status + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc + "&isApproved=" + isApproved + "&idClient=" + idClient;
        return this.defaultGet(queryString);
    };
    UserService.prototype.GetsInvited = function (key, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "/GetsInvited?key=" + key + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    UserService.prototype.GetsInvitedKOL = function (key, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "/GetsInvitedKOL?key=" + key + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    UserService.prototype.GetsInvitedKOLById = function (userId, key, fromDate, toDate, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var fDate;
        var tDate;
        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        if (toDate) {
            tDate = toDate.toISOString();
        }
        var queryString = this.serviceUri + "/GetsInvitedKOLById?userId=" + userId + "&key=" + key + "&fromDate=" + fDate + "&toDate=" + tDate + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    UserService.prototype.GetsForSupport = function (key, idSupport, status, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "/GetsForSupport?key=" + key + "&idSupport=" + idSupport + "&status=" + status + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    UserService.prototype.GetsForSupportCSKH = function (key, fromDate, toDate, idSupport, idProvince, status, isKol, idRef, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var fDate;
        var tDate;
        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        if (toDate) {
            tDate = toDate.toISOString();
        }
        var queryString = this.serviceUri + "/GetsForSupportCSKH?key=" + key + "&fromDate=" + fDate + "&toDate=" + tDate + "&idSupport=" + idSupport + "&idProvince=" + idProvince + "&status=" + status + "&isKol=" + isKol + "&isKol=" + isKol + "&idRef=" + idRef + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    UserService.prototype.GetByRoleName = function (RoleCode, offset, limit) {
        var queryString = this.authorizationEndpoint + "/GetByRoleName?RoleCode=" + RoleCode + "&offset=" + offset + "&limit=" + limit;
        return this.defaultGet(queryString);
    };
    UserService.prototype.managerment = function (term, pageIndex, pageSize, isActive, roleId, isDisable, isSuperUser) {
        var queryString = this.authorizationEndpoint + "/managerment?term=" + term + "&pageIndex=" + pageIndex + "&pageSize=" + pageSize + "&isActive=" + isActive + "&roleId=" + roleId + "&isDisable=" + isDisable + "&isSuperUser=" + isSuperUser;
        return this.defaultGet(queryString);
    };
    UserService.prototype.SearchNotInGroup = function (key, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "/SearchNotInGroup?key=" + key + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    UserService.prototype.getCurrent = function () {
        return this.defaultGet(this.serviceUri + "/getCurrent");
    };
    UserService.prototype.GetByUserId = function (userId) {
        return this.defaultGet(this.serviceUri + "/GetByUserId?userId=" + userId);
    };
    UserService.prototype.GetListTn = function (key, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "/GetListTn?key=" + key + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    UserService.prototype.GetByDomain = function (key, domainStatus, offset, limit, sortField, isAsc) {
        if (sortField === void 0) { sortField = ''; }
        if (isAsc === void 0) { isAsc = 0; }
        var queryString = this.serviceUri + "/GetByDomain?key=" + key + "&domainStatus=" + domainStatus + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    UserService.prototype.LoadConfig = function () {
        return this.defaultGet(this.serviceUri + "/LoadConfig");
    };
    UserService.prototype.UpdateIsLogout = function () {
        return this.defaultGet(this.serviceUri + "/UpdateIsLogout");
    };
    UserService.prototype.GetListUserSip = function () {
        var queryString = this.serviceUri + "/GetListUserSip";
        return this.defaultGet(queryString);
    };
    UserService.prototype.GetListUserInstructor = function () {
        var queryString = this.serviceUri + "/GetListUserInstructor";
        return this.defaultGet(queryString);
    };
    return UserService;
}(BaseService));
export { UserService };
