import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
var RemoteStorage = /** @class */ (function () {
    function RemoteStorage() {
        this._init = false;
        this._connected = false;
        this._requests = {};
        this._count = 0;
        this.OnStorageChanged = new EventEmitter();
    }
    RemoteStorage.prototype.init = function (endpoint) {
        var _this = this;
        var client = this;
        client.Origin = endpoint.substring(0, endpoint.indexOf('/', 8));
        return new Promise(function (resolve) {
            if (_this._init) {
                console.warn('Remote Storage has been inited!');
                return;
            }
            client.RpcFrame = document.createElement('iframe');
            client.RpcFrame.id = client.uuid();
            client.RpcFrame.width = '1px';
            client.RpcFrame.height = '1px';
            client.RpcFrame.style.display = 'none';
            client.RpcFrame.style.position = '-9999px';
            client.RpcFrame.src = endpoint;
            client.RpcFrame.onload = function () {
                client._connected = true;
                client.RpcFrame.contentWindow.postMessage({
                    command: 'connect'
                }, _this.Origin);
                client.Ready = true;
                resolve();
            };
            client._init = true;
            window.addEventListener('message', function (response) {
                if (response.data.event) {
                    // sync
                    if (response.data.payload.key == null && response.data.payload.oldValue == null && response.data.payload.newValue == null) {
                        localStorage.clear();
                        sessionStorage.clear();
                    }
                    else if (response.data.payload.key != null && response.data.payload.newValue == null) {
                        localStorage.removeItem(response.data.payload.key);
                        sessionStorage.removeItem(response.data.payload.key);
                    }
                    else {
                        localStorage.setItem(response.data.payload.key, response.data.payload.newValue);
                        sessionStorage.setItem(response.data.payload.key, response.data.payload.newValue);
                    }
                    client.OnStorageChanged.emit(response);
                }
                else if (response.data.sync) {
                }
                else if (response.data.id) {
                    if (client._requests[response.data.id]) {
                        client._requests[response.data.id](response.data.error, response.data.result);
                    }
                }
            });
            window.document.body.appendChild(client.RpcFrame);
        });
    };
    RemoteStorage.prototype.clear = function () {
        if (!this._connected) {
            console.error('Storage not connected!');
        }
        this.RpcFrame.contentWindow.postMessage({
            command: 'clear'
        }, this.Origin);
    };
    RemoteStorage.prototype.setItem = function (key, value) {
        if (!this._connected) {
            console.error('Storage not connected!');
        }
        this.RpcFrame.contentWindow.postMessage({
            command: 'set',
            payload: {
                key: key,
                value: value
            }
        }, this.Origin);
    };
    RemoteStorage.prototype.getItem = function (key) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var client, requestId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this._connected) {
                    console.error('Storage not connected!');
                }
                client = this;
                requestId = client.RpcFrame.id + ":" + client._count;
                client._count++;
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        client._requests[requestId] = function (err, result) {
                            delete client._requests[requestId];
                            if (err)
                                return reject(new Error(err));
                            resolve(result);
                        };
                        client.RpcFrame.contentWindow.postMessage({
                            command: 'get',
                            id: requestId,
                            payload: {
                                key: key
                            }
                        }, _this.Origin);
                    })];
            });
        });
    };
    RemoteStorage.prototype.syncStorage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!this._connected) {
                    console.error('Storage not connected!');
                }
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.getItem('*').then(function (result) {
                            if (result) {
                                result.forEach(function (item) {
                                    localStorage.setItem(item.key, item.value);
                                    sessionStorage.setItem(item.key, item.value);
                                });
                                resolve();
                            }
                        });
                    })];
            });
        });
    };
    RemoteStorage.prototype.uuid = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };
    return RemoteStorage;
}());
export { RemoteStorage };
