import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var HtMenuService = /** @class */ (function (_super) {
    tslib_1.__extends(HtMenuService, _super);
    function HtMenuService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapFoodEndPoint + "/HtMenu") || this;
    }
    HtMenuService.prototype.getByIdPhanHe = function (idPhanHe, idCha) {
        var url = this.serviceUri + "/GetByIdPhanHe?idPhanHe=" + idPhanHe + "&idCha=" + idCha;
        return this.defaultGet(url);
    };
    HtMenuService.prototype.getDetailById = function (id) {
        var url = this.serviceUri + "/GetDetailById?id=" + id;
        return this.defaultGet(url);
    };
    HtMenuService.prototype.searchTree = function (phanHeId, trangThai) {
        var url = this.serviceUri + "/searchTree?phanHeId=" + phanHeId + "&trangThai=" + trangThai;
        return this.defaultGet(url);
    };
    HtMenuService.prototype.deleteCustom = function (id) {
        var _this = this;
        var url = this.serviceUri + "/deleteCustom/" + id;
        return this._http.put(url, { id: id })
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); }))
            .toPromise();
    };
    HtMenuService.prototype.pinMenuItem = function (idMenu) {
        var _this = this;
        var url = this.serviceUri + "/PinMenuItem?menuId=" + idMenu;
        return this._http
            .post(url, null)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    HtMenuService.prototype.getsPinMenu = function (idPhanHe) {
        var url = this.serviceUri + "/GetsPinMenu?phanHeId=" + idPhanHe;
        return this.defaultGet(url);
    };
    HtMenuService.prototype.unPinMenuItem = function (idMenu) {
        var _this = this;
        var url = this.serviceUri + "/UnPinMenuItem?id=" + idMenu;
        return this._http
            .post(url, null)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    HtMenuService.ngInjectableDef = i0.defineInjectable({ factory: function HtMenuService_Factory() { return new HtMenuService(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR)); }, token: HtMenuService, providedIn: "root" });
    return HtMenuService;
}(BaseService));
export { HtMenuService };
