import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../services/base.service';
import { environment } from '../../../../../environments/environment';
import { ResponseResult } from '../../../models/response-result';
import { catchError } from 'rxjs/operators';
@Injectable()
export class FoldersService extends BaseService {

    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.fileEndpoint}/Folders`);
    }

    GetRootFolder() {
        const queryString = `${this.serviceUri}/GetRootFolder`;
        return this.defaultGet(queryString);
    }

    GetByParentId(parentId: number, objectId?: number, tableName?: string, service?: string, isPrivated?: boolean) {
        const queryString = `${this.serviceUri}/GetByParentId/${parentId}?objectId=${objectId}&tableName=${tableName}&service=${service}&isPrivated=${isPrivated}`;
        return this.defaultGet(queryString);
    }

    Gets(key: string, offset?: number, limit?: number, sortField?: string, isAsc: boolean = false) {
        const queryString = `${this.serviceUri}?key=${key}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}`;
        return this.defaultGet(queryString);
    }

    public(id: number): Promise<ResponseResult> {
        return this._http
            .post<ResponseResult>(`${this.serviceUri}/Public/${id}`, null)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

}

