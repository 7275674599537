import { Component, ElementRef, Injector, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/primeng';
import { SecondPageEditBase } from '../lib-shared/classes/base/second-page-edit-base';
import { CommentsGComponent } from '../comments-g/comments-g.component';
import { WmTasksService } from '../work/services/WmTasks.service';
import { WmProjectColsService } from '../work/services/WmProjectCols.service';
import { WmProjectMembersService } from '../work/services/WmProjectMembers.service';
import { UserService } from '../lib-shared/services/user.service';

// import { CommentsTaskComponent } from '../comments-task/comments-task.component';


@Component({
  selector: 'app-task-view-g',
  templateUrl: './task-view-g.component.html',
  styleUrls: ['./task-view-g.component.scss']
})
export class TaskViewGComponent extends SecondPageEditBase implements OnInit {
  isLoading = false;
  modelEdit: any = {
    percent: 0,
    type: 1,
    priority: 3,
    user: {
      avatar: null
    },
    projectCol: {
      label: ""
    },
    assignee: {
      label: "",
      avatar: ""
    }
  };

  crrUser: any = {};
  participant: any[] = [];
  projectCol_options: any[] = [];
  priority_options = this.getTypeOption();
  type_options = this.getTypeOption();
  star_options = this.getStarOption();
  users_options: any[] = [];

  openDetail: boolean = true;
  openTime: boolean = true;
  activityType: number = 1;
  isEdit = false;
  // @ViewChild(LogWorkComponent) _LogWorkComponent: LogWorkComponent;
  // @ViewChild(LabelComponent) _LabelComponent: LabelComponent; 
  @ViewChild(CommentsGComponent) _CommentsGComponent: CommentsGComponent;

  constructor(
    protected _injector: Injector,
    private formBuilder: FormBuilder,
    private _WmTasksService: WmTasksService,
    private _WmProjectColsService: WmProjectColsService,
    private _WmProjectMembersService: WmProjectMembersService,
    private _UserService: UserService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {
    super(null, _injector);
    this.formGroup = this.formBuilder.group({
      name: [''],
      description: [''],
      detail: [''],
      endDate: [''],
      completedDate: [''],
      startDate: [''],
      percent: [''],
      priority: [''],
      idStatus: [''],
      attachment: [''],
      type: [''],
      project: [''],
      idAssignee: [''],
      participant: ['']
    });
  }

  async ngOnInit() {
    this.crrUser = await this._UserService.getCurrentUser();

    this.modelEdit.user = this.crrUser;
    this.modelEdit.idStatus = this.config.data.idStatus == 0 ? null : this.config.data.idStatus
    this.modelEdit.idProject = this.config.data.idProject; 
    await this.getProjectColOption();
    await this.GetsByIdProject();
    await this.GetDetail(this.config.data.id)

  }

  async GetsByIdProject() {
    this.users_options = [];
    await this._WmProjectMembersService.GetsByIdProject("", this.config.data.idProject, 0, 1000).then(rs => {
      if (rs.status) {
        rs.data.forEach(value => {
          this.users_options.push({ label: value.name, value: value.userId, avatar: value.avatar });
        });
      }
    });
  }

  async getProjectColOption() {
    await this._WmProjectColsService.Gets('', this.config.data.idProject, 0, 100, '', true).then(rs => {
      if (rs.status) {
        rs.data.forEach(value => {
          this.projectCol_options.push({ label: value.name, value: value.id });
        });
      }
    });
  }

  getAvatar(avatar) {
    if (avatar != null)
      return this.getImageAvatar(avatar);
    else
      return `/assets/images/avatar.jpg`;
  }

  async GetDetail(id: any) {
    this.modelEdit = {};
    if (id > 0) {
      await this._WmTasksService.getDetail(id).then(async response => {
        if (response.status) {
          this.modelEdit = response.data;
          console.log(response.data);
          
          this.modelEdit.completedDate = this.modelEdit.completedDate ? new Date(this.modelEdit.completedDate) : null;
          this.modelEdit.endDate = this.modelEdit.endDate ? new Date(this.modelEdit.endDate) : null;
          this.modelEdit.startDate = this.modelEdit.startDate ? new Date(this.modelEdit.startDate) : null;

          this.participant = [];
          this.modelEdit.wmParticipants.forEach(value => {
            this.participant.push(value.userId);
          }); 

          if (this.activityType == 1)
            await this._CommentsGComponent.showData(id);

          // await this._LabelComponent.LoadData(this.modelEdit.idProject);

          // Nếu tab log đang được chọn thì show log
          // if (this.activityType == 2)
          //   await this._LogWorkComponent.showData(this.modelEdit.id, this.modelEdit.idProject, false); // show log

          this.setDataModel();
        }
      }, () => {
        this._notifierService.showHttpUnknowError();
      });
    }
  }

  getMaxDialogHeight() {
    return (window.innerHeight - 200).toString() + 'px';
  }

  getUserNameById(id) {
    let dataUser = this.users_options.filter(x => x.value == id)[0];
    if (dataUser) return dataUser.label;
    else return "";
  }

  getAvatarById(id) {
    let dataUser = this.users_options.filter(x => x.value == id)[0];
    if (dataUser && dataUser.avatar != null)
      return this.getImageAvatar(dataUser.avatar);
    else
      return `/assets/images/avatar.jpg`;
  }

  closePopupMethod() {
    this.ref.close(true);
  }

  setDataModel() {
    this.modelEdit = {
      ...this.modelEdit,
      projectCol: this.projectCol_options.filter(x => x.value == this.modelEdit.idProjectCol)[0],
      assignee: this.users_options.filter(x => x.value == this.modelEdit.idAssignee)[0],
    }
  }

}