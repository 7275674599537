import { VsMySetting } from '../models/ccmysetting';
import * as i0 from "@angular/core";
var VsMySettingService = /** @class */ (function () {
    function VsMySettingService() {
    }
    VsMySettingService.prototype.getCurrentSetting = function () {
        var crrSetting = new VsMySetting();
        if (localStorage.getItem('mySetting')) {
            crrSetting = JSON.parse(localStorage.getItem('mySetting'));
        }
        else {
            crrSetting = new VsMySetting();
            // crrSetting.idHe = 1;
            // crrSetting.idHocKy = 1;
            // crrSetting.idNamHoc = (new Date()).getFullYear();
            // crrSetting.tenNamHoc = (new Date()).getFullYear().toString() + '-' + ((new Date()).getFullYear() + 1).toString();
            // crrSetting.trangThaiKetThucNamHoc = false;
            localStorage.setItem('mySetting', JSON.stringify(crrSetting));
        }
        return crrSetting;
    };
    VsMySettingService.prototype.setCurrentSetting = function (setting) {
        localStorage.setItem('mySetting', JSON.stringify(setting));
    };
    VsMySettingService.ngInjectableDef = i0.defineInjectable({ factory: function VsMySettingService_Factory() { return new VsMySettingService(); }, token: VsMySettingService, providedIn: "root" });
    return VsMySettingService;
}());
export { VsMySettingService };
