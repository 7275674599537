import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { BaseService } from '../../lib-shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
var StatementsService = /** @class */ (function (_super) {
    tslib_1.__extends(StatementsService, _super);
    function StatementsService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapFoodEndPoint + "/Statements") || this;
    }
    StatementsService.prototype.Gets = function (key, dealType, userId, status, fromDate, toDate, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var fDate = undefined;
        if (fromDate)
            fDate = fromDate.toISOString();
        var tDate = undefined;
        if (toDate)
            tDate = toDate.toISOString();
        var queryString = this.serviceUri + "?key=" + key + "&dealType=" + dealType + "&userId=" + userId + "&status=" + status + "&fromDate=" + fDate + "&toDate=" + tDate + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    StatementsService.prototype.GetsCurrentUser = function (key, dealType, status, fromDate, toDate, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var fDate = undefined;
        if (fromDate)
            fDate = fromDate.toISOString();
        var tDate = undefined;
        if (toDate)
            tDate = toDate.toISOString();
        var queryString = this.serviceUri + "/GetsCurrentUser?key=" + key + "&dealType=" + dealType + "&status=" + status + "&fromDate=" + fDate + "&toDate=" + tDate + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    StatementsService.prototype.GetBalance = function () {
        var queryString = this.serviceUri + "/GetBalance";
        return this.defaultGet(queryString);
    };
    StatementsService.prototype.GetBalanceCSKH = function (fromDate, toDate, type) {
        var fDate;
        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        var tDate;
        if (toDate) {
            tDate = toDate.toISOString();
        }
        var queryString = this.serviceUri + "/GetBalanceCSKH?fromDate=" + fDate + "&toDate=" + tDate + "&type=" + type;
        return this.defaultGet(queryString);
    };
    StatementsService.prototype.GetBalanceDN = function (fromDate, toDate, type) {
        var fDate;
        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        var tDate;
        if (toDate) {
            tDate = toDate.toISOString();
        }
        var queryString = this.serviceUri + "/GetBalanceDN?fromDate=" + fDate + "&toDate=" + tDate + "&type=" + type;
        return this.defaultGet(queryString);
    };
    StatementsService.prototype.ThongKeDNTheoTrangThai = function (fromDate, toDate, type) {
        var fDate;
        if (fromDate) {
            fDate = fromDate.toISOString();
        }
        var tDate;
        if (toDate) {
            tDate = toDate.toISOString();
        }
        var queryString = this.serviceUri + "/ThongKeDNTheoTrangThai?fromDate=" + fDate + "&toDate=" + tDate + "&type=" + type;
        return this.defaultGet(queryString);
    };
    StatementsService.prototype.Withdraw = function (deal) {
        var queryString = this.serviceUri + "/Withdraw/" + deal;
        return this.defaultPost(queryString, {});
    };
    StatementsService.prototype.CancelWithdraw = function (id) {
        var queryString = this.serviceUri + "/CancelWithdraw/" + id;
        return this.defaultPost(queryString, {});
    };
    StatementsService.prototype.Process = function (item) {
        var queryString = this.serviceUri + "/Process";
        return this.defaultPost(queryString, item);
    };
    StatementsService.prototype.Transfer = function (item, type) {
        var queryString = this.serviceUri + "/Transfer?type=" + type;
        return this.defaultPost(queryString, item);
    };
    return StatementsService;
}(BaseService));
export { StatementsService };
