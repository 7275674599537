import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../../lib-shared/services/base.service';
var UserAddressService = /** @class */ (function (_super) {
    tslib_1.__extends(UserAddressService, _super);
    function UserAddressService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapFoodEndPoint + "/UserAddress") || this;
    }
    UserAddressService.prototype.GetsByUserId = function (UserId) {
        var queryString = this.serviceUri + "?UserId=" + UserId;
        return this.defaultGet(queryString);
    };
    UserAddressService.prototype.GetIsSyn = function () {
        var queryString = this.serviceUri + "/GetIsSyn?limit=10";
        return this.defaultGet(queryString);
    };
    return UserAddressService;
}(BaseService));
export { UserAddressService };
