import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Utilities } from '../shared/utilities';
import { SecondPageEditBase } from '../lib-shared/classes/base/second-page-edit-base';
import { ProvincesService } from '../dapfood/services/provinces.service';
import { DistrictsService } from '../dapfood/services/districts.service';
import { WardsService } from '../dapfood/services/wards.service';
import { UserAddressService } from '../dapfood/services/useraddress.service';
var ProfileAddressComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProfileAddressComponent, _super);
    function ProfileAddressComponent(_injector, formBuilder, _UserAddressService, _ProvincesService, _DistrictsService, _WardsService) {
        var _this = _super.call(this, null, _injector) || this;
        _this._injector = _injector;
        _this.formBuilder = formBuilder;
        _this._UserAddressService = _UserAddressService;
        _this._ProvincesService = _ProvincesService;
        _this._DistrictsService = _DistrictsService;
        _this._WardsService = _WardsService;
        _this.USER_INFO_KEY = 'user_info';
        _this.modelEdit = {};
        _this.isLoading = false;
        _this.isSaving = false;
        _this.isView = false;
        return _this;
    }
    ProfileAddressComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.formGroup = new FormGroup({
                    idProvince: new FormControl('', Validators.compose([Validators.required])),
                    idDistrict: new FormControl('', Validators.compose([Validators.required])),
                    idWard: new FormControl('', Validators.compose([Validators.required])),
                    address: new FormControl('', Validators.compose([Validators.required])),
                    isPrimary: new FormControl(''),
                });
                return [2 /*return*/];
            });
        });
    };
    ProfileAddressComponent.prototype.save = function () {
        var _this = this;
        this.isSaving = true;
        if (this.modelEdit.userId > 0) {
            this._UserAddressService.post(this.modelEdit)
                .then(function (response) {
                if (response.status) {
                    _this._notifierService.showSuccess('Cập nhật địa chỉ thành công!');
                    _this.isShow = false;
                    _this.closePopup.emit();
                }
                else {
                    _this.isSaving = false;
                    _this._notifierService.showWarning('Cập nhật thông tin địa chỉ thất bại.\nNội dung lỗi: ' + response.message);
                }
            })
                .catch(function (error) {
                _this.isSaving = false;
                _this._notifierService.showWarning('Có lỗi xảy ra: ' + Utilities.getErrorDescription(error));
            });
        }
    };
    ProfileAddressComponent.prototype.showPopup = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isShow = true;
                        this.modelEdit = {};
                        this.modelEdit.userId = item.userId;
                        return [4 /*yield*/, this.onLoadProvinces()];
                    case 1:
                        _a.sent();
                        if (item.id > 0) {
                            this.isLoading = true;
                            this.item = item.id;
                            this.isView = true;
                            this._UserAddressService
                                .getDetail(item.id)
                                .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            this.isLoading = false;
                                            if (!response.status) return [3 /*break*/, 3];
                                            this.modelEdit = response.data;
                                            return [4 /*yield*/, this.onLoadDistricts()];
                                        case 1:
                                            _a.sent();
                                            return [4 /*yield*/, this.onLoadWards()];
                                        case 2:
                                            _a.sent();
                                            _a.label = 3;
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); })
                                .catch(function (error) {
                                _this.isLoading = false;
                                _this._notifierService.showWarning('Có lỗi xảy ra: ' + Utilities.getErrorDescription(error));
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfileAddressComponent.prototype.togglePopupDelete = function () {
        this.modelEdit = {};
    };
    ProfileAddressComponent.prototype.onLoadProvinces = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._ProvincesService.GetShort().then(function (rs) {
                            if (rs.status) {
                                _this.province_options = rs.data;
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfileAddressComponent.prototype.onLoadDistricts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._DistrictsService.GetShort(this.modelEdit.idProvince).then(function (rs) {
                            if (rs.status) {
                                _this.distric_options = rs.data;
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfileAddressComponent.prototype.onLoadWards = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._WardsService.GetShort(this.modelEdit.idDistrict).then(function (rs) {
                            if (rs.status) {
                                _this.ward_options = rs.data;
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return ProfileAddressComponent;
}(SecondPageEditBase));
export { ProfileAddressComponent };
