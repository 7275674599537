import * as tslib_1 from "tslib";
import { ComponentBase } from './component-base';
import { NotifierService } from '../../services/notifier.service';
import { OnInit, Injector, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomRouterService } from '../../services/custom-router.service';
import { takeUntil } from 'rxjs/operators';
import { ListHelperService } from '../../services/list-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common.service';
import { ExportService } from '../../services/export.service';
import { Printd } from 'printd';
import { VsUserSettingsService } from '../../services/vs-user-settings.service';
import { VsMySettingService } from '../../../services/ccmysetting.service';
import { VsMySetting } from '../../../models/ccmysetting';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { User } from '../../models/user';
var cssText = "\n  @media print {\n    table{\n      border-collapse: collapse;\n      width: 100%\n    }\n    .hidden{\n        display: block;\n    }\n    tr > th{\n      color:red;\n      text-align: center;\n      font-size:70%;\n    }\n    tr > td{\n    }\n    td, th {\n      border: 1px solid black;\n    }\n    .vs-hidden-print, .ui-paginator-bottom{\n        display:none;\n    }\n  }\n";
var SecondPageIndexBase = /** @class */ (function (_super) {
    tslib_1.__extends(SecondPageIndexBase, _super);
    function SecondPageIndexBase(_baseService, _injector) {
        var _this = _super.call(this) || this;
        _this._baseService = _baseService;
        _this._injector = _injector;
        _this.currentUser = new User;
        _this.query = '';
        _this.openSearchAdv = false;
        _this.isCheckAll = false;
        _this.isViewActivity = false;
        _this.isViewSetting = false;
        _this.ids = [];
        _this.total = 0;
        _this.page = 1;
        _this.limit = 20;
        _this.limitAll = 10000;
        _this.cols = [];
        _this.isMultiEdit = false;
        _this.dataSource = [];
        _this.dataExport = [];
        _this.dataTotal = [];
        _this.openSelectCheck = false;
        _this.sortField = '';
        _this.isAsc = false;
        _this.isLoading = false;
        _this.selectedItems = [];
        _this.isCollapsed = false;
        _this.isIgnoreClientCache = false;
        _this.openColumnList = false;
        _this.isViewList = true;
        _this.arrTrangThai = [
            { label: 'Sử dụng', value: 1 },
            { label: 'Không Sử dụng', value: 2 },
            { label: '--Tất cả--', value: 0 },
        ];
        _this.trangThai = 1;
        _this.listItemNumberPerPage = [
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: '100', value: 100 },
            { label: '200', value: 200 },
            { label: '500', value: 500 },
            { label: '1000', value: 1000 },
        ];
        _this.exportName = 'dataExport';
        _this.mySetting = new VsMySetting();
        _this.splitExportItems = [
            { label: 'Excel', icon: 'fa fa-file-excel' },
            { label: 'Word', icon: 'fa fa-file-word' }
        ];
        _this._router = _this._injector.get(Router);
        _this._notifierService = _this._injector.get(NotifierService);
        _this._activatedRoute = _this._injector.get(ActivatedRoute);
        _this._customRouterService = _this._injector.get(CustomRouterService);
        _this._listHelperService = _this._injector.get(ListHelperService);
        _this._commonService = _this._injector.get(CommonService);
        _this._translateService = _this._injector.get(TranslateService);
        _this._exportService = _this._injector.get(ExportService);
        _this._tnUserSettingsService = _this._injector.get(VsUserSettingsService);
        _this._mySettingService = _this._injector.get(VsMySettingService);
        _this.onLoadSettings();
        _this.mySetting = _this._mySettingService.getCurrentSetting();
        return _this;
    }
    SecondPageIndexBase.prototype.onClick = function (event, targetElement) {
        if (this.columnListContainerElement) {
            if (!this.columnListContainerElement.nativeElement.contains(targetElement)) {
                this.openColumnList = false;
            }
        }
        if (this.bulkSelectContainerElement) {
            if (!this.bulkSelectContainerElement.nativeElement.contains(targetElement)) {
                this.openSelectCheck = false;
            }
        }
    };
    SecondPageIndexBase.prototype.ngOnInit = function () {
        var _this = this;
        this._activatedRoute.fragment
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (fragments) {
            var query = _this._customRouterService.parseFragment(fragments).searchKey;
            if (query === null || query === undefined) {
                query = '';
            }
            _this.query = query;
            _this.getData();
        });
    };
    SecondPageIndexBase.prototype.onLoadSettings = function () {
        // this._tnUserSettingsService.getSettingByUrl(environment.clientDomain.idPhanhe, this._router.url).then(rs => {
        //     if (rs.status) {
        //         this.mySettings = rs.data;
        //         this.openSearchAdv = this.mySettings.openSearchAdv;
        //         if (this.mySettings.cols) {
        //             this.cols = JSON.parse(this.mySettings.cols);
        //         }
        //         if (this.pSetting !== undefined) {
        //             this.pSetting.onLoadSetting(rs.data);
        //         }
        //     }
        // });
    };
    // tslint:disable-next-line:use-life-cycle-interface
    SecondPageIndexBase.prototype.ngOnDestroy = function () {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    SecondPageIndexBase.prototype.toggleSearch = function () {
        this.openSearchAdv = !this.openSearchAdv;
    };
    SecondPageIndexBase.prototype.toggleSelectCheck = function () {
        this.openSelectCheck = !this.openSelectCheck;
    };
    SecondPageIndexBase.prototype.getData = function () {
        // this.limit = this._listHelperService.getLimitByScreen();
        // this._baseService.get(this.query, ((this.page - 1) * this.limit), this.limit, this.sortField, this.isAsc, this.isIgnoreClientCache)
        //     .then(response => {
        //         this.dataSource = response.data;
        //         if (response.totalRecord || response.totalRecord === 0) {
        //             this.total = response.totalRecord;
        //         }
        //         this.afterGetData();
        //     }, error => {
        //         this._notifierService.showHttpUnknowError();
        //     });
    };
    SecondPageIndexBase.prototype.exportToCSVIds = function () {
        var _this = this;
        if (this.ids.length === 0) {
            this._notifierService.showWarning('Bạn chưa chọn dòng để xuất dữ liệu');
        }
        else {
            var data = this.dataSource.filter(function (x) { return _this.ids.indexOf(x.id) >= 0; });
            this._commonService.exportToCSV(data, this.cols, 'export.csv');
        }
    };
    SecondPageIndexBase.prototype.exportToCSV = function (fileName) {
        var _this = this;
        if (fileName === void 0) { fileName = 'export'; }
        if (this.ids.length === 0) {
            this._notifierService.showWarning('Bạn chưa chọn dòng để xuất dữ liệu');
        }
        else {
            var data = this.dataSource.filter(function (x) { return _this.ids.indexOf(x.id) >= 0; });
            this._commonService.exportToCSV(data, this.cols, fileName + '.csv');
        }
    };
    SecondPageIndexBase.prototype.exportAllToCSV = function () {
        // this._baseService.Gets(this.query, 0, 10000, this.sortField, this.isAsc)
        //     .then(response => {
        //         this._commonService.exportToCSV(
        //             response.data,
        //             this.cols,
        //             'export.csv'
        //         );
        //     }, error => {
        //         this._notifierService.showHttpUnknowError();
        //     });
    };
    SecondPageIndexBase.prototype.exportToExcel = function () {
        if (this.ids.length === 0) {
            this._notifierService.showWarning('Bạn chưa chọn dòng để xuất dữ liệu');
        }
        else {
            this._exportService.export(this.ids, this.cols, 'export');
        }
    };
    SecondPageIndexBase.prototype.exportAllToExcel = function () {
        // this._baseService.Gets(this.query, 0, this.limitAll, this.sortField, this.isAsc)
        //     .then(response => {
        //         if (response.data.length === 0) {
        //             this._notifierService.showWarning('Không có dữ liệu để xuất !');
        //         } else {
        //             this._exportService.export(response.data, this.cols, 'export');
        //         }
        //     }, error => {
        //         this._notifierService.showHttpUnknowError();
        //     });
    };
    // exportPdf() {
    //     import("jspdf").then(jsPDF => {
    //         import("jspdf-autotable").then(x => {
    //             const doc = new jsPDF.default(0,0);
    //             doc.autoTable(this.exportColumns, this.cars);
    //             doc.save('primengTable.pdf');
    //         })
    //     })
    // }
    SecondPageIndexBase.prototype.exportExcel = function () {
        var _this = this;
        var data = [];
        this.dataSource.forEach(function (item) {
            var row = {};
            _this.cols.forEach(function (col) {
                var celValue = item[col.field];
                if (celValue) { // Format Date
                    if (col.dataType === 'date') {
                        celValue = moment.utc(celValue).format('YYYY-MM-DD HH:mm');
                    }
                    if (col.dataType === 'number') {
                        celValue = (celValue).toLocaleString('vi-VN', { maximumFractionDigits: 2 });
                    }
                }
                // if (celValue) {// Format Date
                //     if (col.dataType === 'date') {
                //         const datePipe = new DatePipe('en-US');
                //         celValue = datePipe.transform(celValue, 'yyyy-MM-dd hh:mm');
                //     }
                //     if (col.dataType === 'number') {
                //         celValue = (celValue).toLocaleString('vi-VN', { maximumFractionDigits: 2 });
                //     }
                // }
                row[col.header] = celValue;
            });
            data.push(row);
        });
        var worksheet = XLSX.utils.json_to_sheet(data);
        var workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, this.exportName);
    };
    SecondPageIndexBase.prototype.saveAsExcelFile = function (buffer, fileName) {
        var EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        var EXCEL_EXTENSION = '.xlsx';
        var data = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
    };
    SecondPageIndexBase.prototype.delete = function (id) {
        var _this = this;
        this._notifierService.showDeleteConfirm().then(function (rs) {
            _this._baseService.delete(id)
                .then(function (response) {
                _this.getData();
            }, function (error) {
                _this._notifierService.showDeleteDataSuccess();
            });
        });
    };
    SecondPageIndexBase.prototype.deletelstMany = function () {
        var _this = this;
        var lstId = '';
        for (var _i = 0, _a = this.ids; _i < _a.length; _i++) {
            var item = _a[_i];
            lstId += item.id + ",";
        }
        lstId = lstId.substring(0, lstId.length - 1);
        this._baseService.deleteMany(lstId).then(function (response) {
            _this.getData();
        }, function (error) {
            _this._notifierService.showHttpUnknowError();
        });
    };
    SecondPageIndexBase.prototype.deleteMutiple = function () {
        var _this = this;
        this._notifierService.showDeleteConfirm().then(function (rs) {
            // const obs   = new Subject<number>();
            // let   index = 0;
            // this.selectedItems.forEach(item => {
            //     this._baseService.delete(item.id)
            //         .then(response => {
            //             obs.next(++index);
            //             // this._notifierService.showDeleteDataSuccess();
            //         }, error => {
            //             obs.next(++index);
            //             // this._notifierService.showDeleteDataError();
            //         });
            // });
            // const sub = obs.subscribe(indexItem => {
            //     if (indexItem === this.selectedItems.length) {
            //         this.selectedItems.length = 0;
            //         sub.unsubscribe();
            //         this.getData();
            //         this._notifierService.showDeleteDataSuccess();
            //     }
            // });
            _this.deletelstMany();
            _this._notifierService.showDeleteDataSuccess();
        });
    };
    // deleteMutiple() {
    //     this._notifierService.showDeleteConfirm().then(rs => {
    //         const obs   = new Subject<number>();
    //         let   index = 0;
    //         this.selectedItems.forEach(item => {
    //             this._baseService.delete(item.id)
    //                 .then(response => {
    //                     obs.next(++index);
    //                     // this._notifierService.showDeleteDataSuccess();
    //                 }, error => {
    //                     obs.next(++index);
    //                     // this._notifierService.showDeleteDataError();
    //                 });
    //         });
    //         const sub = obs.subscribe(indexItem => {
    //             if (indexItem === this.selectedItems.length) {
    //                 this.selectedItems.length = 0;
    //                 sub.unsubscribe();
    //                 this.getData();
    //                 this._notifierService.showDeleteDataSuccess();
    //             }
    //         });
    //     });
    // }
    SecondPageIndexBase.prototype.afterGetData = function () {
    };
    SecondPageIndexBase.prototype.onSearch = function () {
        this.page = 1;
        this.getData();
        // this.openSearchAdv = false;
    };
    SecondPageIndexBase.prototype.goToPage = function (event) {
        this.page = (event.first / event.rows) + 1;
        this.limit = event.rows;
        this.getData();
    };
    SecondPageIndexBase.prototype.onNext = function () {
        this.page++;
        this.getData();
    };
    SecondPageIndexBase.prototype.onPrev = function () {
        this.page--;
        this.getData();
    };
    SecondPageIndexBase.prototype.onSort = function (event) {
        this.sortField = event.field;
        this.isAsc = event.order === 1 ? false : true;
        this.getData();
    };
    SecondPageIndexBase.prototype.onChangeSize = function () {
        this.page = 1;
        this.getData();
    };
    SecondPageIndexBase.prototype.viewActivities = function () {
        this.isViewActivity = !this.isViewActivity;
    };
    SecondPageIndexBase.prototype.viewSettings = function () {
        this.isViewSetting = !this.isViewSetting;
    };
    SecondPageIndexBase.prototype.onPage = function (event) {
        this.page = (event.first / event.rows) + 1;
        this.limit = event.rows;
        this.getData();
    };
    SecondPageIndexBase.prototype.checkItem = function (item) {
        if (item.checked) {
            this.ids.push(item.id);
            item.checked = true;
        }
        else {
            this.ids.splice(this.ids.indexOf(item.id), 1);
            item.checked = false;
        }
        this.isMultiEdit = this.ids.length > 0 ? true : false;
        this.isCheckAll = this.ids.length === this.dataSource.length ? true : false;
    };
    SecondPageIndexBase.prototype.onCheckAll = function (ev) {
        if (ev.target.checked) {
            this.isCheckAll = true;
            this.ids = [];
            for (var i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i].checked = true;
                this.ids.push(this.dataSource[i].id);
            }
        }
        else {
            this.isCheckAll = false;
            this.ids = [];
            for (var i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i].checked = false;
            }
        }
        this.isMultiEdit = this.ids.length > 0 ? true : false;
    };
    SecondPageIndexBase.prototype.checkItemMenu = function (type) {
        this.ids = [];
        for (var i = 0; i < this.dataSource.length; i++) {
            if (type === -1) {
                this.dataSource[i].checked = true;
                this.ids.push(this.dataSource[i].id);
            }
            else if (type === -2) {
                this.dataSource[i].checked = false;
            }
            else {
                if (this.dataSource[i].trangThai === type) {
                    this.dataSource[i].checked = true;
                    this.ids.push(this.dataSource[i].id);
                }
            }
        }
        this.openSelectCheck = false;
        this.isMultiEdit = this.ids.length > 0 ? true : false;
        this.isCheckAll = this.ids.length === this.dataSource.length ? true : false;
    };
    SecondPageIndexBase.prototype.paginate = function (event) {
        this.page = event.page + 1;
        this.limit = event.rows;
        this.getData();
    };
    SecondPageIndexBase.prototype.onSelectionChanged = function (event) {
        this.selectedItems = event;
    };
    SecondPageIndexBase.prototype.containSelectItem = function (item) {
        return this.selectedItems.findIndex(function (x) { return x.id === item.id; }) > -1;
    };
    SecondPageIndexBase.prototype.onPopupClosed = function (data) {
        if (data) {
            this.getData();
        }
    };
    SecondPageIndexBase.prototype.printListLoaiDiemById = function (ids, data) {
        var str = '';
        var arr = ids.split(',');
        var _loop_1 = function (i) {
            var tg = data.filter(function (item) { return item.id === +arr[i]; });
            str += tg[0].ma + ', ';
        };
        for (var i = 0; i < arr.length - 1; i++) {
            _loop_1(i);
        }
        var tgs = data.filter(function (item) { return item.id === +arr[arr.length - 1]; });
        str += tgs[0].ma;
        return str;
    };
    SecondPageIndexBase.prototype.convertDataToOptions = function (options, data, fieldName) {
        if (data.length > 0) {
            data.map(function (item) {
                return options.push({ label: item[fieldName], value: item.id });
            });
        }
    };
    SecondPageIndexBase.prototype.getFieldById = function (data, id, fieldName) {
        for (var i in data) {
            var item = data[i];
            if (item.id === id) {
                return item[fieldName];
            }
        }
        return id;
    };
    SecondPageIndexBase.prototype.toggleColumnList = function () {
        this.openColumnList = !this.openColumnList;
    };
    SecondPageIndexBase.prototype.resetBulkSelect = function () {
        this.ids = [];
        this.selectedItems = [];
        this.isCheckAll = false;
    };
    SecondPageIndexBase.prototype.getIndexDataById = function (id) {
        return this.dataSource.findIndex(function (i) { return i.id === id; });
    };
    SecondPageIndexBase.prototype.getDataById = function (id) {
        var index = this.dataSource.findIndex(function (i) { return i.id === id; });
        return this.dataSource[index];
    };
    SecondPageIndexBase.prototype.onPrint = function () {
        var d = new Printd();
        d.print(document.getElementById('table'), [cssText]);
    };
    SecondPageIndexBase.prototype.onPrintE = function (element) {
        var d = new Printd();
        d.print(document.getElementById(element), [cssText]);
    };
    SecondPageIndexBase.prototype.onSaveSettings = function (event) {
        // if (event.checkCols === true) {
        //     this.mySettings.cols = JSON.stringify(this.cols);
        // } else {
        //     this.mySettings.cols = undefined;
        // }
        // if (event.checkSearch === true) {
        //     this.mySettings.openSearchAdv = this.openSearchAdv;
        // }
        // this._tnUserSettingsService.updateSettingForUrl(this.mySettings).then(rs => {
        //     if (rs.status) {
        //         this.mySettings = rs.data;
        //         if (this.mySettings.cols !== null && this.mySettings.cols !== undefined) {
        //             this.cols = JSON.parse(this.mySettings.cols);
        //         }
        //         this.pSetting.onLoadSetting(this.mySettings);
        //         this._notifierService.showUpdateDataSuccess();
        //     } else {
        //         this._notifierService.showUpdateDataFailed();
        //     }
        // });
    };
    SecondPageIndexBase.prototype.getMaxDialogHeight = function () {
        return (window.innerHeight - 200).toString() + 'px';
    };
    SecondPageIndexBase.prototype.onRowSelect = function (event) {
        this.ids.push(event.data.id);
        event.data.checked = true;
        this.isMultiEdit = this.ids.length > 0 ? true : false;
        this.isCheckAll = this.ids.length === this.dataSource.length ? true : false;
    };
    SecondPageIndexBase.prototype.onRowUnselect = function (event) {
        this.ids.splice(this.ids.indexOf(event.data.id), 1);
        event.data.checked = false;
        this.isMultiEdit = this.ids.length > 0 ? true : false;
        this.isCheckAll = this.ids.length === this.dataSource.length ? true : false;
    };
    // GetStatus(item: any) {
    //     if (item.idStatus === 1000) {
    //         return `<span class="order don-moi">${item.status}</span>`;
    //     }
    //     if (item.idStatus === 31 || item.idStatus === 32) {
    //         return `<span class="order don-thanhcong">${item.status}</span>`;
    //     }
    //     if (item.idStatus === 22 || item.idStatus === 33) {
    //         return `<span class="order don-nguyhiem">${item.status}</span>`;
    //     }
    //     if (item.idStatus === 4 || item.idStatus === 40 || item.idStatus === 41 || item.idStatus === 999) {
    //         return `<span class="order don-thatbai">${item.status}</span>`;
    //     } else {
    //         return `<span class="order don-danggiao">${item.status}</span>`;
    //     }
    // }
    SecondPageIndexBase.prototype.formatPhone = function (phone) {
        var re = new RegExp(/^((84|0[3|5|7|8|9])+([0-9]{8})\b)$/);
        if (re.test(phone)) {
            phone = phone.substring(0, 4) + '***' + phone.substring(7);
        }
        return phone;
    };
    SecondPageIndexBase.prototype.onChangeRowLimit = function () {
        this.onSearch();
        this.fixTableScrollProblem();
    };
    // fix vụ lệch header ở table khi xuất hiện thanh scroll
    SecondPageIndexBase.prototype.fixTableScrollProblem = function () {
        this.dataSource = this.dataSource.slice();
    };
    return SecondPageIndexBase;
}(ComponentBase));
export { SecondPageIndexBase };
