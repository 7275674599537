import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../../lib-shared/services/base.service';
var TopicUsersService = /** @class */ (function (_super) {
    tslib_1.__extends(TopicUsersService, _super);
    function TopicUsersService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapFoodEndPoint + "/TopicUsers") || this;
    }
    TopicUsersService.prototype.GetByUserId = function () {
        var queryString = this.serviceUri + "/GetByUserId";
        return this.defaultGet(queryString);
    };
    return TopicUsersService;
}(BaseService));
export { TopicUsersService };
