import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { NgbModal } from '../../../../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { UserService } from '../../../services/user.service';
import { VsFileItem } from '../../file-upload/models/file-item';
import { VsFileUploadService } from '../services/file-upload.service';
import { ConfirmationService, DialogService } from 'primeng/primeng';
import { VsFileViewerComponent } from '../vs-file-viewer/vs-file-viewer.component';
import { ComponentBase } from '../../../classes/base/component-base';
import { FilesService } from '../services/files.service';
import { FoldersService } from '../services/folders.service';
var VsListFileComponent = /** @class */ (function (_super) {
    tslib_1.__extends(VsListFileComponent, _super);
    function VsListFileComponent(_fileService, ngbModal, _userService, dialogService, confirmationService, fileServices, folderServices) {
        var _this = _super.call(this) || this;
        _this._fileService = _fileService;
        _this.ngbModal = ngbModal;
        _this._userService = _userService;
        _this.dialogService = dialogService;
        _this.confirmationService = confirmationService;
        _this.fileServices = fileServices;
        _this.folderServices = folderServices;
        _this.files = [];
        _this.folders = [];
        _this.disabled = false;
        _this.ids = [];
        _this.linkView = '';
        _this.selectedFile = new VsFileItem();
        _this.currentIds = '';
        _this.viewOnly = false;
        _this.isSingleFile = false;
        _this.isViewList = false;
        _this.onUploaded = new EventEmitter();
        _this.onRemoved = new EventEmitter();
        _this.onClick = new EventEmitter();
        _this.onSelectItem = new EventEmitter();
        _this.onChangeControl = function (obj) {
            console.log(obj);
        };
        _this.onTouched = function () { };
        return _this;
    }
    VsListFileComponent.prototype.ngOnInit = function () {
        this.userId = this._userService.getBasicUserInfo().userId;
    };
    VsListFileComponent.prototype.writeValue = function (obj) {
        if (obj) {
            this.files = obj;
            // this.folders = this.files.filter(s => s.typeId == 2)
            // console.log(this.folders);
            console.log(this.files);
        }
    };
    VsListFileComponent.prototype.registerOnChange = function (fn) {
        this.onChangeControl = fn;
    };
    VsListFileComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    VsListFileComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    // downloadFile(item) {
    //   window.open(this._fileService.getLinkDownload(item.id));
    // }
    VsListFileComponent.prototype.openFile = function (item) {
    };
    VsListFileComponent.prototype.deleteFile = function (item) {
        var _this = this;
        this.confirmationService.confirm({
            message: 'Bạn có chắc chắn muốn xóa file "' + item.rawFileName + '" không ?',
            header: 'Xác nhận xóa',
            icon: 'pi pi-trash',
            accept: function () {
                if (item.typeId === 2) {
                    _this._fileService.deleteFolder(_this.userId, item.id);
                    var fileToRemove = _this.files.findIndex(function (x) { return x.fileName === item.fileName; });
                    _this.files.splice(fileToRemove, 1);
                }
                else {
                    _this._fileService.deleteFile(item);
                    var fileToRemove = _this.files.findIndex(function (x) { return x.fileName === item.fileName; });
                    _this.files.splice(fileToRemove, 1);
                    _this.onChangeControl(_this.files);
                    _this.onRemoved.next(item);
                }
            }
        });
    };
    VsListFileComponent.prototype.deleteFileTemp = function (item) {
        if (item.typeId === 2) {
            var fileToRemove = this.files.findIndex(function (x) { return x.fileName === item.fileName; });
            this.files.splice(fileToRemove, 1);
        }
        else {
            var fileToRemove = this.files.findIndex(function (x) { return x.fileName === item.fileName; });
            this.files.splice(fileToRemove, 1);
            this.onChangeControl(this.files);
            this.onRemoved.next(item);
        }
    };
    VsListFileComponent.prototype.viewFile = function (item) {
        // Nếu file là hình ảnh hoặc video thì dùng FileViewer, Nếu là tài liệu thì dùng VsFileViewer
        if (item.type == 1 || item.type == 3)
            this._FileViewerComponent.showPopupObject(item, this.files);
        else {
            this.ref = this.dialogService.open(VsFileViewerComponent, {
                data: item,
                showHeader: true,
                header: item.fileName,
                width: '100%',
                height: 'calc(100vh - 0px)',
                styleClass: "vs-modal vs-file-viewer",
                contentStyle: { 'overflow': 'auto' },
                baseZIndex: 1010,
                closeOnEscape: true
            });
        }
    };
    VsListFileComponent.prototype.onItemClick = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.selectedFile = new VsFileItem();
                if (item.typeId === 2) {
                    this.onChangeControl(this.files);
                    this.onClick.next(item);
                }
                else {
                    this.viewFile(item);
                }
                return [2 /*return*/];
            });
        });
    };
    VsListFileComponent.prototype.checkItem = function (item) {
        if (this.isSingleFile) {
            this.files.forEach(function (element) {
                if (element.id !== item.id) {
                    element.checked = false;
                }
            });
            if (item.checked) {
                this.ids = [];
                this.ids.push(item.id);
                item.checked = true;
            }
            else {
                this.ids.splice(this.ids.indexOf(item.id), 1);
                item.checked = false;
            }
            this.onChangeControl(this.ids);
            this.onSelectItem.next(this.ids + '');
        }
        else {
            this.ids = this.currentIds.split(',').filter(function (s) { return s !== ''; });
            if (item.checked) {
                // Kiểm tra nếu Id chưa có trong currentIds thì bổ sung
                if (this.currentIds.indexOf(item.id + ',') < 0 && this.currentIds.indexOf(',' + item.id) < 0) {
                    this.ids.push(item.id);
                    item.checked = true;
                    this.onSelectItem.next(this.ids + ',');
                }
            }
            else {
                // Kiểm tra nếu Id có trong currentIds thì remove để xóa
                this.currentIds = this.currentIds.replace(item.id + ',', '');
                this.currentIds = this.currentIds.replace(',' + item.id, '');
                this.ids.splice(this.ids.indexOf(item.id), 1);
                item.checked = false;
                this.onSelectItem.next(this.currentIds);
            }
            this.onChangeControl(this.ids);
        }
        // alert(this.ids + this.currentIds);
    };
    VsListFileComponent.prototype.onClose = function () {
        this.modalReference.close();
    };
    VsListFileComponent.prototype.public = function (item) {
        var _this = this;
        this.confirmationService.confirm({
            message: 'Bạn có chắc chắn muốn chia sẻ tệp tin này không ?',
            header: 'Xác nhận chia sẻ',
            icon: 'pi pi-globe',
            accept: function () {
                // 2 là folder, 1 file
                if (item.typeId == 2) {
                    _this.folderServices.public(item.id).then(function (rs) {
                        if (rs.status) {
                        }
                        ;
                    });
                }
                else {
                    _this.fileServices.public(item.id).then(function (rs) {
                        if (rs.status) {
                        }
                        ;
                    });
                }
            }
        });
    };
    return VsListFileComponent;
}(ComponentBase));
export { VsListFileComponent };
