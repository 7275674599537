import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VsUserSettingsService = /** @class */ (function (_super) {
    tslib_1.__extends(VsUserSettingsService, _super);
    function VsUserSettingsService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapFoodEndPoint + "/tbl_DM_HeThong_Menu") || this;
    }
    VsUserSettingsService.prototype.getSettingByUrl = function (idPhanHe, url) {
        return this.defaultGet(this.serviceUri + "/GetSettingByUrl?idPhanHe=" + idPhanHe + "&url=" + url);
    };
    VsUserSettingsService.ngInjectableDef = i0.defineInjectable({ factory: function VsUserSettingsService_Factory() { return new VsUserSettingsService(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR)); }, token: VsUserSettingsService, providedIn: "root" });
    return VsUserSettingsService;
}(BaseService));
export { VsUserSettingsService };
