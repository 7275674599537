import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../../lib-shared/services/base.service';
var ProvincesService = /** @class */ (function (_super) {
    tslib_1.__extends(ProvincesService, _super);
    function ProvincesService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapFoodEndPoint + "/Provinces") || this;
    }
    ProvincesService.prototype.GetShort = function () {
        var queryString = this.serviceUri + "/GetShort";
        return this.defaultGet(queryString);
    };
    ProvincesService.prototype.GetShortProduct = function (idRegions, isSellApp) {
        var queryString = this.serviceUri + "/GetShortProduct?idRegions=" + idRegions + "&isSellApp=" + isSellApp;
        return this.defaultGet(queryString);
    };
    ProvincesService.prototype.Autocomplete = function (key, idProvince, idDistrict, idWard, offset, limit) {
        var queryString = this.serviceUri + "/Autocomplete?key=" + key + "&idProvince=" + idProvince + "&idDistrict=" + idDistrict + "&idWard=" + idWard + "&offset=" + offset + "&limit=" + limit;
        return this.defaultGet(queryString);
    };
    return ProvincesService;
}(BaseService));
export { ProvincesService };
