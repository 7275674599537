import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../lib-shared/services/base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WmTaskCommentLikesService = /** @class */ (function (_super) {
    tslib_1.__extends(WmTaskCommentLikesService, _super);
    function WmTaskCommentLikesService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapWorkEndPoint + "/WmTaskCommentLikes") || this;
    }
    WmTaskCommentLikesService.prototype.Like = function (taskCommentId, taskId) {
        var _this = this;
        return this._http
            .post(this.serviceUri + "/Like?taskCommentId=" + taskCommentId + "&taskId=" + taskId, null)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    WmTaskCommentLikesService.ngInjectableDef = i0.defineInjectable({ factory: function WmTaskCommentLikesService_Factory() { return new WmTaskCommentLikesService(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR)); }, token: WmTaskCommentLikesService, providedIn: "root" });
    return WmTaskCommentLikesService;
}(BaseService));
export { WmTaskCommentLikesService };
