import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../lib-shared/services/base.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserGlobalService extends BaseService {

  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.dapWorkEndPoint}/WmUsers`);
  }

  GetDetail(Id: number) {
    const queryString = `${this.serviceUri}/GetDetail?id=${Id}`;
    return this.defaultGet(queryString);
  }

  Gets(key: string, status: number, offset?: number, limit?: number, sortField?: string, isAsc: boolean = false, isApproved: number = -1, idClient: number = -1) {
    const queryString = `${this.serviceUri}?key=${key}&status=${status}&offset=${offset}&limit=${limit}&sortField=${sortField}&isAsc=${isAsc}&isApproved=${isApproved}&idClient=${idClient}`;
    return this.defaultGet(queryString);
  }

}
