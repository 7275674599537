import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../../lib-shared/services/base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
;
var WmProjectMembersService = /** @class */ (function (_super) {
    tslib_1.__extends(WmProjectMembersService, _super);
    function WmProjectMembersService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapWorkEndPoint + "/WmProjectMembers") || this;
    }
    WmProjectMembersService.prototype.Gets = function (key, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "?key=" + key + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    WmProjectMembersService.prototype.GetsByIdProject = function (key, idProject, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "/GetsByIdProject?key=" + key + "&idProject=" + idProject + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    WmProjectMembersService.ngInjectableDef = i0.defineInjectable({ factory: function WmProjectMembersService_Factory() { return new WmProjectMembersService(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR)); }, token: WmProjectMembersService, providedIn: "root" });
    return WmProjectMembersService;
}(BaseService));
export { WmProjectMembersService };
