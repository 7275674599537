import * as tslib_1 from "tslib";
import { AfterViewInit, Renderer2, OnDestroy, OnInit, Injector } from '@angular/core';
import { ScrollPanel } from 'primeng/primeng';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { authConfig } from './config/oidc-auth.config';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { HeartBeatService } from './services/heart-beat.service';
import { environment } from '../environments/environment';
import { VsMySetting } from './models/ccmysetting';
import { VsMySettingService } from './services/ccmysetting.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfigurationService } from './lib-shared/services/configuration.service';
import { AuthorizeService } from './lib-shared/services/authorize.service';
import { VsAuthenService } from './lib-shared/auth/authen.service';
import { GlobalService } from './services/global.service';
import { ProfileComponent } from './profile/profile.component';
import { CommonService } from './lib-shared/services/common.service';
import { HtMenuService } from './lib-shared/services/htmenu.service';
import { UserService } from './lib-shared/services/user.service';
var MenuOrientation;
(function (MenuOrientation) {
    MenuOrientation[MenuOrientation["STATIC"] = 0] = "STATIC";
    MenuOrientation[MenuOrientation["OVERLAY"] = 1] = "OVERLAY";
    MenuOrientation[MenuOrientation["SLIM"] = 2] = "SLIM";
    MenuOrientation[MenuOrientation["HORIZONTAL"] = 3] = "HORIZONTAL";
})(MenuOrientation || (MenuOrientation = {}));
var AppComponent = /** @class */ (function () {
    function AppComponent(renderer, translate, _oauthService, _userService, _activatedRoute, _router, _heartBeatService, _commonService, _menuService, _authorizeService, _mySettingService, _tnAuthenService, _globalService, injector, _configurationService) {
        var _this = this;
        this.renderer = renderer;
        this.translate = translate;
        this._oauthService = _oauthService;
        this._userService = _userService;
        this._activatedRoute = _activatedRoute;
        this._router = _router;
        this._heartBeatService = _heartBeatService;
        this._commonService = _commonService;
        this._menuService = _menuService;
        this._authorizeService = _authorizeService;
        this._mySettingService = _mySettingService;
        this._tnAuthenService = _tnAuthenService;
        this._globalService = _globalService;
        this.injector = injector;
        this._configurationService = _configurationService;
        this.layoutMode = MenuOrientation.STATIC;
        this.darkMenu = false;
        this.profileMode = 'top';
        this.staticMenuDesktopInactive = true;
        this.currentRoute = '';
        this.pageLoaded = false;
        this.showPinnedMenu = false;
        this.isInPinnedMenu = false;
        this.isSearchMenuInputFocus = false;
        this.mySetting = new VsMySetting();
        this.listAppNav = [];
        this.tokenProcessing = false;
        this.translate.setDefaultLang('vn');
        this.translate.use('vn');
        this.mySetting = this._mySettingService.getCurrentSetting();
        // this.mySetting.idHe = 2;
        // console.log(environment);
        // Config for authentication
        this.configureWithNewConfigApi();
        this._router.events.subscribe(function (val) {
            if (val instanceof NavigationEnd) {
                var newKey = _this.newGuid();
                sessionStorage.setItem(_this._configurationService.logSessionKey, newKey);
            }
        });
    }
    AppComponent.prototype.ngAfterViewInit = function () {
        // setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 100);
    };
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!environment.production) {
            // for dev evironment
            setInterval(function () {
                _this._heartBeatService.sendHeartBeat();
            }, 70000);
        }
        this.listAppNav.push({ icon: 'icon-g-qtc', text: 'Quản trị hệ thống', url: environment.clientDomain.qthtDomain });
        this.listAppNav.push({ icon: 'icon-g-qtc', text: 'Friends', url: environment.clientDomain.friendDomain });
    };
    AppComponent.prototype.configureWithNewConfigApi = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._oauthService.setStorage(localStorage);
                        this._oauthService.configure(authConfig);
                        this._oauthService.tokenValidationHandler = new JwksValidationHandler();
                        if (!!this._oauthService.hasValidAccessToken()) return [3 /*break*/, 1];
                        // if (!localStorage.getItem('currentUrl')) {
                        //     localStorage.setItem('currentUrl', top.location.href);
                        // }
                        this._oauthService.loadDiscoveryDocumentAndTryLogin().then(function (rs) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!!rs) return [3 /*break*/, 1];
                                        this._oauthService.initImplicitFlow();
                                        return [3 /*break*/, 3];
                                    case 1:
                                        this._userService.returnPromises();
                                        this._oauthService.loadDiscoveryDocument();
                                        return [4 /*yield*/, this._authorizeService.fetchAuthorization()];
                                    case 2:
                                        _a.sent();
                                        this.pageLoaded = true;
                                        this.loadMenu();
                                        this.LoadConfig();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [3 /*break*/, 3];
                    case 1:
                        this._userService.returnPromises();
                        this._oauthService.loadDiscoveryDocument();
                        return [4 /*yield*/, this._authorizeService.fetchAuthorization()];
                    case 2:
                        _a.sent();
                        this.pageLoaded = true;
                        this.loadMenu();
                        this.LoadConfig();
                        _a.label = 3;
                    case 3:
                        // Optional
                        this._oauthService.setupAutomaticSilentRefresh();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.loadMenu = function () {
        var _this = this;
        // const cacheVal = sessionStorage.getItem('htMenu' + environment.clientDomain.idPhanhe.toString());
        // if (cacheVal !== undefined && cacheVal !== null) {
        //     this.appMenuModel = JSON.parse(cacheVal);
        // } else {
        this._menuService.getByIdPhanHe(environment.clientDomain.idPhanhe, 0).then(function (rs) {
            if (rs.status) {
                _this.appMenuModel = rs.data;
                // sessionStorage.setItem('htMenu' + environment.clientDomain.idPhanhe.toString(), JSON.stringify(rs.data));
            }
        });
        // }
    };
    AppComponent.prototype.LoadConfig = function () {
        var _this = this;
        this._userService.LoadConfig().then(function (rs) {
            if (rs.status) {
                // Bắt phải logout ra
                if (rs.data.isLogout === true) {
                    _this._userService.UpdateIsLogout();
                    var authenService = _this.injector.get(VsAuthenService);
                    authenService.logout();
                }
            }
        });
    };
    // onLayoutClick() {
    //     if (!this.topbarItemClick) {
    //         this.activeTopbarItem = null;
    //         this.topbarMenuActive = false;
    //     }
    //     if (!this.menuClick) {
    //         if (this.isHorizontal() || this.isSlim()) {
    //             this.resetMenu = true;
    //         }
    //         if (this.overlayMenuActive || this.staticMenuMobileActive) {
    //             this.hideOverlayMenu();
    //         }
    //         this.menuHoverActive = false;
    //     }
    //     this.topbarItemClick = false;
    //     this.menuClick = false;
    // }
    AppComponent.prototype.onMenuButtonClick = function (event) {
        this.menuClick = true;
        this.rotateMenuButton = !this.rotateMenuButton;
        this.topbarMenuActive = false;
        if (this.layoutMode === MenuOrientation.OVERLAY) {
            this.overlayMenuActive = !this.overlayMenuActive;
        }
        else {
            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            }
            else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
            }
        }
        event.preventDefault();
    };
    AppComponent.prototype.onMenuClick = function ($event) {
        var _this = this;
        this.menuClick = true;
        this.resetMenu = false;
        if (!this.isHorizontal()) {
            setTimeout(function () { _this.layoutMenuScrollerViewChild.moveBar(); }, 450);
        }
    };
    AppComponent.prototype.onTopbarMenuButtonClick = function (event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;
        this.hideOverlayMenu();
        event.preventDefault();
    };
    AppComponent.prototype.onTopbarItemClick = function (event, item) {
        this.topbarItemClick = true;
        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        }
        else {
            this.activeTopbarItem = item;
        }
        event.preventDefault();
    };
    AppComponent.prototype.onSwitchModule = function (moduleCode) {
        switch (moduleCode) {
            case 1: {
                this._router.navigate([environment.clientDomain.qthtDomain]);
                break;
            }
            default: { // frontend
                this._router.navigate([environment.clientDomain.appDomain]);
                break;
            }
        }
    };
    AppComponent.prototype.onTopbarSubItemClick = function (event) {
        event.preventDefault();
    };
    AppComponent.prototype.onEditInfo = function (event) {
        event.preventDefault();
        this.profile.showPopup(this._userService.getBasicUserInfo().userId);
    };
    AppComponent.prototype.onChangePassword = function (event) {
        event.preventDefault();
        this.changePassword.showPopup(this._userService.getBasicUserInfo().userId);
    };
    AppComponent.prototype.onTopbarLogout = function () {
        localStorage.clear();
        sessionStorage.clear();
        this._oauthService.logOut();
        var returnUrl = "?return=" + encodeURIComponent(window.location.href);
        window.location.href = environment.apiDomain.authenticationEndpoint + "/Account/Logout?return=" + returnUrl;
    };
    AppComponent.prototype.hideOverlayMenu = function () {
        this.rotateMenuButton = false;
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    };
    AppComponent.prototype.isTablet = function () {
        var width = window.innerWidth;
        return width <= 1024 && width > 640;
    };
    AppComponent.prototype.isDesktop = function () {
        return window.innerWidth > 1024;
    };
    AppComponent.prototype.isMobile = function () {
        return window.innerWidth <= 640;
    };
    AppComponent.prototype.isOverlay = function () {
        return this.layoutMode === MenuOrientation.OVERLAY;
    };
    AppComponent.prototype.isHorizontal = function () {
        return this.layoutMode === MenuOrientation.HORIZONTAL;
    };
    AppComponent.prototype.isSlim = function () {
        return this.layoutMode === MenuOrientation.SLIM;
    };
    AppComponent.prototype.changeToStaticMenu = function () {
        this.layoutMode = MenuOrientation.STATIC;
    };
    AppComponent.prototype.changeToOverlayMenu = function () {
        this.layoutMode = MenuOrientation.OVERLAY;
    };
    AppComponent.prototype.changeToHorizontalMenu = function () {
        this.layoutMode = MenuOrientation.HORIZONTAL;
    };
    AppComponent.prototype.changeToSlimMenu = function () {
        this.layoutMode = MenuOrientation.SLIM;
    };
    AppComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._sub.unsubscribe();
    };
    AppComponent.prototype.newGuid = function () {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line:no-bitwise
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };
    // search menu
    AppComponent.prototype.onSearchMenu = function (event) {
        this.searchMenuResult = [];
        if (!event || !event.query) {
            return;
        }
        this.searchMenuResult = this.getSearchMenuResult(event.query);
    };
    AppComponent.prototype.getSearchMenuResult = function (keyword) {
        var result = [];
        this.regroupSearchMenuResult(keyword, this.appMenuModel, result);
        return result;
    };
    AppComponent.prototype.regroupSearchMenuResult = function (keyword, listToGet, listToPush) {
        var _this = this;
        listToGet.forEach(function (item) {
            if (item.items && item.items.length) {
                _this.regroupSearchMenuResult(keyword, item.items, listToPush);
            }
            if (item.trangThai === 1
                && (item.label.toUpperCase().indexOf(keyword.toUpperCase()) >= 0)) {
                listToPush.push(item);
            }
        });
    };
    AppComponent.prototype.onMouseOverPinnedMenu = function () {
        this.isInPinnedMenu = true;
    };
    AppComponent.prototype.onMouseLeavePinnedMenu = function () {
        this.isInPinnedMenu = false;
        if (this.isSearchMenuInputFocus) {
            return;
        }
        this.searchMenuInput = '';
        this.showPinnedMenu = false;
    };
    AppComponent.prototype.onSelectMenuSuggestion = function (menuItemObj) {
        this._router.navigateByUrl(menuItemObj.routerLink);
        this.searchMenuInput = '';
        this.showPinnedMenu = false;
    };
    AppComponent.prototype.onBlurSearchMenuInput = function (event) {
        this.isSearchMenuInputFocus = false;
        if (this.isInPinnedMenu) {
            return;
        }
        this.searchMenuInput = '';
        this.showPinnedMenu = false;
    };
    AppComponent.prototype.onFocusSearchMenuInput = function (event) {
        this.isSearchMenuInputFocus = true;
        this.showPinnedMenu = true;
    };
    return AppComponent;
}());
export { AppComponent };
