import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Utilities } from '../shared/utilities';
import { SecondPageEditBase } from '../lib-shared/classes/base/second-page-edit-base';
import { UserService } from '../lib-shared/services/user.service';
import { LogSmsService } from '../dapfood/services/logsms.service';
var ProfileCmtComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ProfileCmtComponent, _super);
    function ProfileCmtComponent(_injector, formBuilder, _userRoleService, _LogSmsService) {
        var _this = _super.call(this, null, _injector) || this;
        _this._injector = _injector;
        _this.formBuilder = formBuilder;
        _this._userRoleService = _userRoleService;
        _this._LogSmsService = _LogSmsService;
        _this.USER_INFO_KEY = 'user_info';
        _this.modelEdit = {};
        _this.isLoading = false;
        _this.isSaving = false;
        _this.isDeleting = false;
        _this.isView = false;
        _this.userTypeDataSource = [];
        _this.captchaUrl = '';
        _this.captchaInvalid = false;
        return _this;
    }
    ProfileCmtComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.formGroup = new FormGroup({
                    code: new FormControl(''),
                    cmt: new FormControl('', Validators.compose([Validators.required])),
                    cmtBackside: new FormControl('', Validators.compose([Validators.required])),
                    cmtFront: new FormControl('', Validators.compose([Validators.required]))
                });
                return [2 /*return*/];
            });
        });
    };
    ProfileCmtComponent.prototype.save = function () {
        var _this = this;
        this.isSaving = true;
        if (this.modelEdit.userId > 0) {
            this._userRoleService.UpdateCmt(this.modelEdit)
                .then(function (response) {
                if (response.status) {
                    _this._notifierService.showSuccess('Cập nhật CMND thành công!');
                    _this.isShow = false;
                    _this.closePopup.emit();
                }
                else {
                    _this.isSaving = false;
                    _this._notifierService.showWarning('Cập nhật thông tin CMND thất bại.\nNội dung lỗi: ' + response.message);
                }
            })
                .catch(function (error) {
                _this.isSaving = false;
                _this._notifierService.showWarning('Có lỗi xảy ra: ' + Utilities.getErrorDescription(error));
            });
        }
    };
    ProfileCmtComponent.prototype.showPopup = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isShow = true;
                if (id) {
                    this.isLoading = true;
                    this.item = id;
                    this.isView = true;
                    this._userRoleService
                        .getCurrent()
                        .then(function (response) {
                        _this.isLoading = false;
                        if (response.status) {
                            _this.modelEdit = response.data;
                        }
                        else {
                            if (response.message === 'ERR_USER_USERNAME_NOT_ALLOWED') {
                                _this._notifierService.showWarning('Tên tài khoản không hợp lệ (Tối thiểu 4 ký tự, không chứa ký tự đặc biệt trừ _ và .)');
                            }
                            else {
                                _this._notifierService.showWarning('Xảy ra lỗi không xác định.');
                            }
                        }
                    })
                        .catch(function (error) {
                        _this.isLoading = false;
                        _this._notifierService.showWarning('Có lỗi xảy ra: ' + Utilities.getErrorDescription(error));
                    });
                }
                else {
                    this.captchaUrl = this._userRoleService.getCaptchaUrl();
                    this.togglePopupDelete();
                }
                return [2 /*return*/];
            });
        });
    };
    ProfileCmtComponent.prototype.togglePopupDelete = function () {
        this.modelEdit = {};
    };
    ProfileCmtComponent.prototype.GetOTP = function () {
        var _this = this;
        this._LogSmsService.GetOtp(4).then(function (rs) {
            if (rs.status) {
                _this._notifierService.showSuccess(rs.message);
            }
            else {
                _this._notifierService.showError(rs.message);
            }
        });
    };
    return ProfileCmtComponent;
}(SecondPageEditBase));
export { ProfileCmtComponent };
