import * as tslib_1 from "tslib";
import { OnInit, Injector } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SecondPageEditBase } from '../lib-shared/classes/base/second-page-edit-base';
import { UserService } from '../lib-shared/services/user.service';
var ChangePasswordComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ChangePasswordComponent, _super);
    function ChangePasswordComponent(_userService, _injector) {
        var _this = _super.call(this, null, _injector) || this;
        _this._userService = _userService;
        _this._injector = _injector;
        _this.msgs = [];
        _this.invalid = false;
        _this.passwordIncorrect = 'PASSWORD_INCORRECT';
        _this.errors = '';
        return _this;
    }
    ChangePasswordComponent.prototype.ngOnInit = function () {
        this.msgs = [];
        this.formGroup = new FormGroup({
            oldPassword: new FormControl('', Validators.compose([Validators.required])),
            passwordHash: new FormControl('', Validators.compose([Validators.required])),
            confirmPassword: new FormControl('', Validators.compose([Validators.required]))
        });
    };
    ChangePasswordComponent.prototype.onShowPopup = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.msgs = [];
                this.invalid = this.formGroup.invalid;
                this.onReset();
                this.onBeforeShowPopUp();
                if (data > 0) {
                    this.itemDetail.id = data;
                    this.onAfterShowPopUp();
                }
                return [2 /*return*/];
            });
        });
    };
    // onShowPopup(data: any) {
    //     this.onReset();
    //     this.onBeforeShowPopUp();
    //     if (data) {
    //         this.itemDetail.id = data;
    //     }
    // }
    ChangePasswordComponent.prototype.onReset = function () {
        this.invalid = false;
        this.itemDetail = {};
        this.formGroup.reset();
    };
    ChangePasswordComponent.prototype.save = function () {
        var _this = this;
        this.msgs = [];
        if (!this.itemDetail.oldPassword) {
            this.formGroup.controls['oldPassword'].setErrors({ 'incorrect': true });
            this.formGroup.setErrors({ 'invalid': true });
            this.invalid = true;
            this.msgs.push({ severity: 'error', summary: 'Lỗi: ', detail: 'Nhập mật khẩu hiện tại' });
            return;
        }
        if (!this.itemDetail.passwordHash || this.itemDetail.passwordHash.length < 6) {
            this.formGroup.controls['passwordHash'].setErrors({ 'incorrect': true });
            this.formGroup.setErrors({ 'invalid': true });
            this.invalid = true;
            this.msgs.push({ severity: 'error', summary: 'Lỗi: ', detail: 'Nhập mật khẩu mới' });
            return;
        }
        if (this.itemDetail.oldPassword == this.itemDetail.passwordHash) {
            this.formGroup.controls['oldPassword'].setErrors({ 'incorrect': true });
            this.formGroup.setErrors({ 'invalid': true });
            this.invalid = true;
            this.msgs.push({ severity: 'error', summary: 'Lỗi: ', detail: 'Mật khẩu mới phải khác mật khẩu cũ' });
            return;
        }
        if (this.itemDetail.passwordHash !== this.itemDetail.confirmPassword) {
            this.formGroup.controls['passwordHash'].setErrors({ 'nomatch': true });
            this.formGroup.setErrors({ 'invalid': true });
            this.invalid = true;
            this.msgs.push({ severity: 'error', summary: 'Lỗi: ', detail: 'Mật khẩu không khớp!' });
            return;
        }
        this._userService.changePassword(this.itemDetail)
            .then(function (response) {
            _this.closePopupMethod(true);
            _this._notifierService.showSuccess('Đổi mật khẩu thành công');
            _this.onAfterSave();
            _this.submitting = false;
        }, function (error) {
            _this.invalid = true;
            _this.formGroup.controls['oldPassword'].setErrors({ 'incorrect': true });
            _this.formGroup.setErrors({ 'invalid': true });
            //this.showValidateMessage();
            _this.msgs.push({ severity: 'error', summary: 'Lỗi: ', detail: error.error.message });
            _this.submitting = false;
        });
    };
    ChangePasswordComponent.prototype.showValidateMessage = function () {
        this.validationSummary.showValidationSummary();
        this.submitting = false;
    };
    return ChangePasswordComponent;
}(SecondPageEditBase));
export { ChangePasswordComponent };
