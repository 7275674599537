import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { BaseService } from '../lib-shared/services/base.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserGlobalService = /** @class */ (function (_super) {
    tslib_1.__extends(UserGlobalService, _super);
    function UserGlobalService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapWorkEndPoint + "/WmUsers") || this;
    }
    UserGlobalService.prototype.GetDetail = function (Id) {
        var queryString = this.serviceUri + "/GetDetail?id=" + Id;
        return this.defaultGet(queryString);
    };
    UserGlobalService.prototype.Gets = function (key, status, offset, limit, sortField, isAsc, isApproved, idClient) {
        if (isAsc === void 0) { isAsc = false; }
        if (isApproved === void 0) { isApproved = -1; }
        if (idClient === void 0) { idClient = -1; }
        var queryString = this.serviceUri + "?key=" + key + "&status=" + status + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc + "&isApproved=" + isApproved + "&idClient=" + idClient;
        return this.defaultGet(queryString);
    };
    UserGlobalService.ngInjectableDef = i0.defineInjectable({ factory: function UserGlobalService_Factory() { return new UserGlobalService(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR)); }, token: UserGlobalService, providedIn: "root" });
    return UserGlobalService;
}(BaseService));
export { UserGlobalService };
