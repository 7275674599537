<div class="cards-comment-task">
  <ngx-avatar [name]="comment.userCreated.name"  src="{{getImageAvatar(comment.userCreated.avatar)}}" size="30"></ngx-avatar>
  <div class="box-commented">
    <div class="background-cm">
      <span class="name">{{ comment.userCreated.name }}</span>

             <!-- <a class="user_link" (mouseenter)="showPopup[0] = true" (mouseleave)="showPopup[0] = false">@{{item.assignee.name}}
            </a> -->
            <!-- <div class="popup_user" *ngIf="showPopup[0]" [ngClass]="{'popup_top': i > 3, 'popup_bottom': i <= 3}">
              <app-user-view-log [userId]="comment.userCreated.userId" [showData]="showPopup[0]"></app-user-view-log>
            </div> -->

      <div class="content">{{ comment.message }}
        <app-hq-file-view-comment [(ngModel)]="comment.image" [noDelete]="true"></app-hq-file-view-comment>
      </div>
      <div class="total-like"
        [ngClass]="{'active': comment.countLike > 0}">
        <i *ngIf="!comment.userLiked"
          class="fa fa-thumbs-o-up "
          aria-hidden="true"></i>
        <i *ngIf="comment.userLiked"
          class="fa fa-thumbs-up"
          aria-hidden="true"></i>
        <span
          *ngIf="comment.countLike > 1">{{comment.countLike}}</span>
      </div>
    </div>
    <div class="option-cmt">
      <a *ngIf="canEdit"
        (click)="setActiveComment.emit({id: comment.id, type: activeCommentType.editing})">
        Sửa
      </a>
      <a *ngIf="canDelete"
        (click)="deleteComment.emit(comment.id)">
        Xoá
      </a>
    </div>
    <div class="like-feed-user">
      <div class="time-cm">
        <!-- {{ comment.createdDate | date: 'dd/MM/yyyy HH:mm' :'UTC'}} -->
        {{calculateTime(comment.createdDate) }}
      </div>
      <a class="like-cm"
        (click)="likeComments(comment.id, comment.idTask)"
        [ngClass]="{'liked': comment.userLiked}">
        Thích
      </a>
      <a class="feedback-cm"
        (click)="setActiveComment.emit({id: comment.id, type: activeCommentType.replying})">
        Trả lời
      </a>
      <span *ngIf="comment.updatedDate !== null" style="color:#76767673;">Đã chỉnh sửa</span>
    </div>
    <!-- ------------------------------------------------------------------ -->

    <!-- ------------------------------------------------------------------ -->
    <div class="replies" *ngIf="replies.length > 0">
      <app-comment-g
        *ngFor="let reply of replies"
        [comment]="reply"
        (setActiveComment)="setActiveComment.emit($event)"
        [activeComment]="activeComment"
        (updateComment)="updateComment.emit($event)"
        (deleteComment)="deleteComment.emit($event)"
        (addComment)="addComment.emit($event)" [parentId]="comment.id"
        [replies]="[]"
        [currentUser]="currentUser"></app-comment-g>
    </div>
    <!-- ------------------------------------------------------------------ -->

    <app-comment-form-g *ngIf="isReplying()"
      (handleSubmit)="addComment.emit({ form: $event, parentId: replyId })">
    </app-comment-form-g>

    <!-- ------------------------------------------------------------------ -->
    <app-comment-form-g *ngIf="isEditing()" [initialText]="comment.message" [fileAttachment]="comment.image"
      (handleSubmit)="updateComment.emit({ form: $event, commentId: comment.id })">
    </app-comment-form-g>

  </div>
</div>
