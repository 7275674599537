import { Component, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';
import { SecondPageEditBase } from '../../lib-shared/classes/base/second-page-edit-base';
import { ActiveCommentInterface } from '../../lib-shared/types/activeComment.interface';
import { User } from '../../lib-shared/models/user';
import { ActiveCommentTypeEnum } from '../../lib-shared/types/activeCommentType.enum';
import { WmTaskCommentLikesService } from '../../work/services/WmTaskCommentLikes.service';

interface data {
  text: String,
  parentId: number
}

@Component({
  selector: 'app-comment-g',
  templateUrl: './comment-g.component.html',
  styleUrls: ['./comment-g.component.scss']
})
export class CommentGComponent extends SecondPageEditBase implements OnInit {

  @Input() comment!: any;
  @Input() replies!: any[];
  @Input() activeComment!: ActiveCommentInterface | null;
  @Input() parentId!: number;
  @Input() currentUser: User;

  @Output() deleteComment = new EventEmitter<number>();
  @Output() updateComment = new EventEmitter<{ text: string; commentId: number }>();
  @Output() addComment = new EventEmitter<{ text: string; parentId: number }>();
  @Output() setActiveComment = new EventEmitter<ActiveCommentInterface | null>();

  @Output() emitData = new EventEmitter<data>();

  activeCommentType = ActiveCommentTypeEnum;
  replyId: number = null;
  canEdit: boolean = false;
  canDelete: boolean = false;

  constructor(
    protected _injector: Injector,
    private _WmTaskCommentLikesService: WmTaskCommentLikesService,
  ) {
    super(null, _injector);
  }

  ngOnInit() {
    this.replyId = this.parentId ? this.parentId : this.comment.id;
    this.canEdit = this.currentUser.userId == this.comment.userCreated.userId;
    this.canDelete = this.currentUser.userId == this.comment.userCreated.userId;
  }

  calculateTime(createdDate: any): string {
    if (!(createdDate instanceof Date)) {
      createdDate = new Date(createdDate);
    }

    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 7);

    const timeDifference = currentDate.getTime() - createdDate.getTime();

    const secondsPerMinute = 60;
    const minutesPerHour = 60;
    const hoursPerDay = 24;
    const daysPerWeek = 7;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / secondsPerMinute);
    const hours = Math.floor(minutes / minutesPerHour);
    const days = Math.floor(hours / hoursPerDay);
    const weeks = Math.floor(days / daysPerWeek);

    if (weeks > 0) {
      return `${weeks} tuần`;
    } else if (days > 0) {
      return `${days} ngày`;
    } else if (hours > 0) {
      return `${hours} giờ`;
    } else if (minutes > 0) {
      return `${minutes} phút`;
    } else if (seconds > 0) {
      return `${seconds} giây`;
    } else {
      return "vừa xong";
    }
  }

  isReplying(): boolean {
    if (!this.activeComment) {
      return false;
    }
    return (
      this.activeComment.id === this.comment.id &&
      this.activeComment.type === 'replying'
    );
  }

  isEditing(): boolean {
    if (!this.activeComment) {
      return false;
    }
    return (
      this.activeComment.id === this.comment.id &&
      this.activeComment.type === 'editing'
    );
  }

  async likeComments(idTaskComment, idTask) {
    await this._WmTaskCommentLikesService.Like(idTaskComment, idTask)
      .then(async response => {
        if (response.status) {
          this.comment.userLiked = !response.data.isDeleted;
          if (!response.data.isDeleted) {
            this.comment.countLike++;
            this.comment.likeUser.push({
              userId: this.currentUser.userId,
              name: this.currentUser.userName
            })
          }
          else {
            this.comment.countLike--;
            let indexUser = this.comment.likeUser.findIndex(x => x.userId == this.currentUser.userId);
            this.comment.likeUser.splice(indexUser, 1);
          }

        }
      }, () => {
        this._notifierService.showHttpUnknowError();
      });
  }

}
