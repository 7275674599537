import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseService } from '../../lib-shared/services/base.service';
var WardsService = /** @class */ (function (_super) {
    tslib_1.__extends(WardsService, _super);
    function WardsService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapFoodEndPoint + "/Wards") || this;
    }
    WardsService.prototype.GetShort = function (idDistrict) {
        var queryString = this.serviceUri + "/GetShort?idDistrict=" + idDistrict;
        return this.defaultGet(queryString);
    };
    WardsService.prototype.GetShortByListId = function (lstId) {
        var queryString = this.serviceUri + "/GetShortByListId?listId=" + lstId;
        return this.defaultGet(queryString);
    };
    return WardsService;
}(BaseService));
export { WardsService };
