import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, Injector, SimpleChanges } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ConfirmationService, DialogService, FileUpload, MessageService } from 'primeng/primeng';
import { ConfigurationService } from '../../../services/configuration.service';
import { environment } from '../../../../../environments/environment';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { VsFileUploadService } from '../services/file-upload.service';
import { SecondPageEditBase } from '../../../classes/base/second-page-edit-base';
import { VsFileViewerComponent } from '../vs-file-viewer/vs-file-viewer.component';
import { FilesService } from '../services/files.service';
var HqFileUploadCommentComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HqFileUploadCommentComponent, _super);
    function HqFileUploadCommentComponent(_injector, confirmationService, messageService, _fileUploadService, _configurationService, dialogService, _fileService) {
        var _this = _super.call(this, null, _injector) || this;
        _this._injector = _injector;
        _this.confirmationService = confirmationService;
        _this.messageService = messageService;
        _this._fileUploadService = _fileUploadService;
        _this._configurationService = _configurationService;
        _this.dialogService = dialogService;
        _this._fileService = _fileService;
        _this.disabled = false;
        _this.apiUrl = '';
        _this.uploading = false;
        _this.maxFileSize = 0;
        _this.progressValue = 0;
        _this.chooseLabel = 'Chọn file';
        _this.multiple = false;
        _this.styleButton = false;
        _this.defaultImage = false;
        _this.showIconOnly = false;
        _this.icon = 'fa fa-upload';
        _this.viewOnly = false;
        _this.fileType = ''; // '' , image/* , video/* , 
        _this.iconUploadMessage = false;
        _this.onUploaded = new EventEmitter();
        _this.onChangeControl = function (obj) { };
        _this.onTouched = function () { };
        _this.apiUrl = environment.apiDomain.fileEndpoint + "/Files/upload"; // _moduleConfigService.getConfig().ApiFileUpload;
        _this.maxFileSize = _this._configurationService.maxFileSize;
        return _this;
    }
    HqFileUploadCommentComponent.prototype.ngOnInit = function () {
        // this.onUploadEventPaste(this.filePasteUpload)
        // console.log(this.filePasteUpload);
    };
    HqFileUploadCommentComponent.prototype.ngAffterViewInit = function () {
        console.log(this.filePasteUpload);
    };
    HqFileUploadCommentComponent.prototype.ngOnChanges = function (changes) {
        if (changes.filePasteUpload && changes.filePasteUpload.currentValue) {
            this.onUploadEventPaste(this.filePasteUpload);
        }
    };
    HqFileUploadCommentComponent.prototype.writeValue = function (obj) {
        var _this = this;
        this.listUploaded = [];
        if (obj) {
            this.listUploaded = obj.split(",");
            this._fileUploadService.GetsByIds(obj).then(function (rs) {
                if (rs.status) {
                    _this.listUploaded = rs.data;
                }
            }).catch(function (err) {
                console.log('Có lỗi xảy ra, vui lòng thử lại ' + err);
            });
        }
    };
    HqFileUploadCommentComponent.prototype.registerOnChange = function (fn) {
        this.onChangeControl = fn;
    };
    HqFileUploadCommentComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    HqFileUploadCommentComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    HqFileUploadCommentComponent.prototype.onUploadEvent = function (event) {
        var _this = this;
        try {
            var files = event.files;
            if (files.length > 0) {
                if (event.originalEvent.body && event.originalEvent.body.status) {
                    //Nếu là single thì clear list đi để lưu ảnh mới, nếu là multi thì push thêm ảnh
                    if (this.multiple == false)
                        this.listUploaded = [];
                    event.originalEvent.body.data.forEach(function (element) {
                        _this.listUploaded.push(element); // list object file  
                    });
                    var files_1 = this.listUploaded.map(function (x) { return x.id; }).join(",");
                    this.onChangeControl(files_1);
                    this.onUploaded.next(files_1);
                    this.progressValue = 100;
                }
            }
        }
        catch (e) {
            console.error(e);
        }
        this.uploading = false;
    };
    HqFileUploadCommentComponent.prototype.onUploadEventPaste = function (filePasteUpload) {
        try {
            if (filePasteUpload) {
                this.convertFileToBinary(filePasteUpload);
            }
        }
        catch (e) {
            console.error(e);
        }
        this.uploading = false;
    };
    HqFileUploadCommentComponent.prototype.convertFileToBinary = function (file) {
        var _this = this;
        var reader = new FileReader();
        reader.onload = function (event) {
            var binaryData;
            // kiểm tra dữ liệu đọc có fải là chuỗi ko để khởi tạo 1 Blob
            if (typeof reader.result === 'string') {
                binaryData = new Blob([reader.result], { type: 'application/octet-stream' });
            }
            else {
                binaryData = new Blob([reader.result], { type: 'application/octet-stream' });
            }
            // Tạo một đối tượng FormData để chứa dữ liệu nhị phân
            var formData = new FormData();
            formData.append('files', binaryData, file.name);
            _this.progressValue = 1;
            _this._fileService.upload(formData).then(function (res) {
                if (res.status) {
                    res.data.forEach(function (item) {
                        _this.listUploaded.push(item);
                    });
                    var files = _this.listUploaded.map(function (x) { return x.id; }).join(",");
                    _this.onChangeControl(files);
                    _this.onUploaded.next(files);
                    _this.progressValue = 100;
                }
            });
        };
        reader.readAsArrayBuffer(file);
    };
    HqFileUploadCommentComponent.prototype.onProgress = function (event) {
        // if (event.originalEvent.loaded !== 0) {
        //   this.progressValue = (event.originalEvent.loaded / event.originalEvent.total) * 100;
        // }
    };
    HqFileUploadCommentComponent.prototype.deleteFile = function (id) {
        var _this = this;
        this.confirmationService.confirm({
            message: 'Bạn có chắc chắn muốn xóa file này không ?',
            header: 'Xác nhận xóa',
            icon: 'pi pi-trash',
            accept: function () {
                var fileToRemove = _this.listUploaded.findIndex(function (x) { return x.id === id; });
                _this.listUploaded.splice(fileToRemove, 1);
                var files = _this.listUploaded.map(function (x) { return x.id; }).join(",");
                _this.onChangeControl(files);
                _this.onUploaded.next(files);
                _this.writeValue(files);
            }
        });
    };
    HqFileUploadCommentComponent.prototype.onBeforeSend = function (ev) {
        console.log(ev);
    };
    HqFileUploadCommentComponent.prototype.onError = function (ev) {
        console.log(ev);
        this.uploading = false;
    };
    HqFileUploadCommentComponent.prototype.onSelect = function (ev) {
        console.log(ev);
        // this.messageService.add({ severity: 'error', summary: 'Lỗi upload file', detail: 'Vui lòng kiểm tra lại định dạng file', life: 10000 });
    };
    HqFileUploadCommentComponent.prototype.selectFileUpload = function () {
        this.fileControl.basicFileInput.nativeElement.click();
    };
    HqFileUploadCommentComponent.prototype.onBeforeUpload = function () {
        this.progressValue = 1;
        this.uploading = true;
    };
    HqFileUploadCommentComponent.prototype.viewFile = function (item) {
        // Nếu file là hình ảnh hoặc video thì dùng FileViewer, Nếu là tài liệu thì dùng VsFileViewer
        if (item.type == 1 || item.type == 3)
            this._FileViewerComponent.showPopupObject(item, this.listUploaded);
        else {
            this.ref = this.dialogService.open(VsFileViewerComponent, {
                data: item,
                showHeader: true,
                header: item.fileName,
                width: '100%',
                height: 'calc(100vh - 0px)',
                styleClass: "vs-modal vs-file-viewer",
                contentStyle: { 'overflow': 'auto' },
                baseZIndex: 1010,
                closeOnEscape: true
            });
        }
    };
    HqFileUploadCommentComponent.prototype.downloadFileAll = function () {
        var _this = this;
        this.listUploaded.forEach(function (element) {
            _this.downloadFile(element);
        });
    };
    return HqFileUploadCommentComponent;
}(SecondPageEditBase));
export { HqFileUploadCommentComponent };
