import * as tslib_1 from "tslib";
import { Injector } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { BaseService } from '../../lib-shared/services/base.service';
var BanksService = /** @class */ (function (_super) {
    tslib_1.__extends(BanksService, _super);
    function BanksService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapFoodEndPoint + "/Banks") || this;
    }
    BanksService.prototype.Gets = function (key, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "?key=" + key + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    BanksService.prototype.GetShort = function (key) {
        var queryString = this.serviceUri + "/GetShort?key=" + key;
        return this.defaultGet(queryString);
    };
    return BanksService;
}(BaseService));
export { BanksService };
