import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { ComponentBase } from '../../classes/base/component-base';
var VsAvatarViewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(VsAvatarViewComponent, _super);
    function VsAvatarViewComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.size = 30;
        _this.fontsize = 12;
        _this.indexTable = 1;
        _this.mouseLeave = false;
        _this.isShowMess = true;
        _this.showInitials = false;
        _this.showPopup = [];
        _this.model = {
            avatar: null,
            name: "",
            color: null
        };
        _this.onChangeControl = function (obj) { };
        _this.onTouched = function () { };
        return _this;
    }
    VsAvatarViewComponent.prototype.ngOnInit = function () {
    };
    VsAvatarViewComponent.prototype.writeValue = function (obj) {
        this.model = {};
        if (obj) {
            this.model = obj;
            if (!obj.avatar) {
                this.showInitials = true;
                if (obj.name) {
                    // phải khởi tạo 1 property initName để không bị binding ngược lại model (do dùng ngModel) sau khi xử lý name ra ngoài bên component cha
                    this.model.initName = this.createInitials(obj.name);
                    this.model.color = this.getColorFromName(obj.name);
                }
                else {
                    this.model.initName = "";
                    this.model.color = "";
                }
            }
            else {
                this.showInitials = false;
            }
        }
    };
    VsAvatarViewComponent.prototype.registerOnChange = function (fn) {
        this.onChangeControl = fn;
    };
    VsAvatarViewComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    VsAvatarViewComponent.prototype.setDisabledState = function (isDisabled) {
        // this.disabled = isDisabled;
    };
    VsAvatarViewComponent.prototype.createInitials = function (nameInput) {
        if (!nameInput) {
            return "";
        }
        var initials = "";
        var cleanedName = nameInput.replace(/[^\w\s]/gi, "");
        var nameParts = cleanedName.split(" ");
        for (var i = 0; i < nameParts.length; i++) {
            var firstChar = nameParts[i].charAt(0).toUpperCase();
            initials += firstChar;
            if (initials.length === 3) {
                break;
            }
        }
        return initials;
    };
    // đoạn này xử lý hash màu theo tên để màu sắc theo tên chứ ko phải ngẫu nhiên
    // hash name thành 1 số
    VsAvatarViewComponent.prototype.hashCode = function (str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    };
    // sau khi đã hash được name thì tạo màu từ số vừa hash được từ name đến khoảng 0x00FFFFF
    VsAvatarViewComponent.prototype.intToRGB = function (i) {
        var c = (i & 0x00FFFFFF).toString(16).toUpperCase(); // "2323231"
        return "00000".substring(0, 6 - c.length) + c;
    };
    // lấy ra màu từ tên truyền vào
    VsAvatarViewComponent.prototype.getColorFromName = function (name) {
        var hash = this.hashCode(name);
        var color = "#" + this.intToRGB(hash);
        if (!this.isDark(color)) {
            color = this.adjustColor(color, -50);
        }
        return color;
    };
    // hàm này kiểm tra tương phản của màu sắc so với text bên trong màu đó => loại được trường hợp tương phản tới text màu trắng
    VsAvatarViewComponent.prototype.isDark = function (color) {
        var r = parseInt(color.substring(1, 3), 16);
        var g = parseInt(color.substring(3, 5), 16);
        var b = parseInt(color.substring(5, 7), 16);
        // tìm trên mạng được công thức màu YIQ để tính được độ tương phản
        // tham khảo link (https://users.soict.hust.edu.vn/trungtt/uploads/slides/CG11_Color.pdf)
        var brightness = (r * 299 + g * 587 + b * 114) / 1000;
        // nhỏ hơn 128 là vì theo công thức YIQ thì Y nhỏ hơn 128
        // sẽ làm màu của mình có tương phản tối hơn tránh trường hợp tương phản với màu trắng làm text ko nhìn được rõ
        return brightness < 128;
    };
    // điều chỉnh lại màu nếu mà độ tương phản vượt 128
    VsAvatarViewComponent.prototype.adjustColor = function (color, amount) {
        var r = parseInt(color.substring(1, 3), 16);
        var g = parseInt(color.substring(3, 5), 16);
        var b = parseInt(color.substring(5, 7), 16);
        r = Math.max(Math.min(255, r + amount), 0);
        g = Math.max(Math.min(255, g + amount), 0);
        b = Math.max(Math.min(255, b + amount), 0);
        var newColor = ((r << 16) | (g << 8) | b).toString(16).toUpperCase();
        return "#" + "000000".substring(0, 6 - newColor.length) + newColor;
    };
    return VsAvatarViewComponent;
}(ComponentBase));
export { VsAvatarViewComponent };
