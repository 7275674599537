import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { ModuleConfigService } from './module-config.service';
import { VsBaseService } from './psbase.service';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
var VsThongBaoService = /** @class */ (function (_super) {
    tslib_1.__extends(VsThongBaoService, _super);
    function VsThongBaoService(http, injector, config) {
        return _super.call(this, http, 'config.getConfig().', injector) || this;
    }
    VsThongBaoService.prototype.sendNotify = function (myNotify) {
        var _this = this;
        var url = environment.apiDomain.notificationEndpoint + "/notifications";
        return this.http
            .post(url, myNotify)
            .pipe(retry(3), catchError(function (err) { return _this.handleError(err, _this.injector); })).map(function (rs) { return rs; }).toPromise();
    };
    VsThongBaoService.prototype.guiThongBaoQuaEmail = function (thongbao) {
        var _this = this;
        var url = this.svUrl + "/GuiThongBaoQuaEmail";
        return this.http
            .post(url, thongbao)
            .pipe(retry(3), catchError(function (err) { return _this.handleError(err, _this.injector); })).map(function (rs) { return rs; }).toPromise();
    };
    return VsThongBaoService;
}(VsBaseService));
export { VsThongBaoService };
