import { Subject } from 'rxjs/internal/Subject';
import { OnDestroy } from '@angular/core';
import { NotifierService } from '../../services/notifier.service';
import { environment } from '../../../../environments/environment';

export abstract class ComponentBase implements OnDestroy {

    public _unsubscribeAll: Subject<any>;
    public _notifierService: NotifierService;

    constructor() {
        this._unsubscribeAll = new Subject();
    }

    /**
    * On destroy
    */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    getSelectedItemLabel(data, value) {
        const item = data.find(i => i.value === value);
        if (!item) {
            return '';
        }
        return item.label;
    }

    onCopy() {
        this._notifierService.showSuccess('Đã copy !');
    }

    urlFile(path: string) {
        if (path == null || path == undefined)
            return `/assets/images/no_image.jpg`;
        return `${environment.apiDomain.fileEndpoint}/${path}`;
    }

    urlImageAvatar(path: string) {
        if (path == null || path == undefined)
            return `/assets/images/no_image.jpg`;
        return `${environment.apiDomain.fileEndpoint}/Avatar${path}`;
    }

    urlImageMedium(path: string) {
        if (path == null || path == undefined)
            return `/assets/images/no_image.jpg`;
        return `${environment.apiDomain.fileEndpoint}/Medium${path}`;
    }

    urlImageLarge(path: string) {
        if (path == null || path == undefined)
            return `/assets/images/no_image.jpg`;
        return `${environment.apiDomain.fileEndpoint}/Large${path}`;
    }

    urlImageOriginal(path: string) {
        if (path == null || path == undefined)
            return `/assets/images/no_image.jpg`;
        return `${environment.apiDomain.fileEndpoint}/Original${path}`;
    }

    getImageAvatar(id: string) {
        return id ? `${environment.apiDomain.fileEndpoint}/files/image/Avatar/${id}` : null;
    }

    getImageMedium(id: string) {
        if (id == null || id == undefined)
            return `/assets/images/no_image.jpg`;
        return `${environment.apiDomain.fileEndpoint}/files/image/Medium/${id}`;
    }

    getImageLarge(id: string) {
        if (id == null || id == undefined)
            return `/assets/images/no_image.jpg`;
        return `${environment.apiDomain.fileEndpoint}/files/image/Large/${id}`;
    }

    getImageOriginal(id: string) {
        if (id == null || id == undefined)
            return `/assets/images/no_image.jpg`;

        return `${environment.apiDomain.fileEndpoint}/files/image/Original/${id}`;
    }

    getAvatar(id) {
        if (id) {
            return this.getImageAvatar(id);
        }
    }

    GetStatus(item: any) {
        if (item.idStatus === 1000) {
            return `<span class="order don-moi">${item.status}</span>`;
        }
        if (item.idStatus === 31 || item.idStatus === 32) {
            return `<span class="order don-thanhcong">${item.status}</span>`;
        }
        if (item.idStatus === 22 || item.idStatus === 33) {
            return `<span class="order don-nguyhiem">${item.status}</span>`;
        }
        if (item.idStatus === 4 || item.idStatus === 40 || item.idStatus === 41 || item.idStatus === 999) {
            return `<span class="order don-thatbai">${item.status}</span>`;
        } else {
            return `<span class="order don-danggiao">${item.status}</span>`;
        }
    }

    GetActions(item: any) {
        if (!item.idAction) {
            return ``;
        }
        if (item.idAction === 1 || item.idAction === 13) {
            return `<span class="order don-moi">${item.actions}</span>`;
        }
        if (item.idAction === 2 || item.idStatus === 7 || item.idStatus === 3) {
            return `<span class="order don-thanhcong">${item.actions}</span>`;
        }
        if (item.idAction === 4 || item.idStatus === 8) {
            return `<span class="order don-nguyhiem">${item.actions}</span>`;
        }
        if (item.idAction === 5 || item.idStatus === 9 || item.idAction === 14 || item.idAction === 10) {
            return `<span class="order don-thatbai">${item.actions}</span>`;
        }
        else {
            return `<span class="order don-danggiao">${item.actions}</span>`;
        }
    }


    getPriorityOption() {
        return [
            { label: 'Chọn độ ưu tiên', value: 0 },
            { label: 'Rất cao', value: 5 },
            { label: 'Cao', value: 4 },
            { label: 'Trung bình', value: 3 },
            { label: 'Thấp', value: 2 },
            { label: 'Rất thấp', value: 1 }
        ]
    }

    getStarOption() {
        return [
            { label: 'Đánh dấu sao', value: null },
            { label: 'Có', value: true },
            { label: 'Không', value: false }
        ];
    }

    getTypeOption() {
        return [
            { label: 'Chọn loại công việc', value: 0 },
            { label: 'Task', value: 1 },
            { label: 'Bug', value: 2 }
        ]
    }


    GetDate() {
        // Lấy ngày hôm nay và ngày hôm qua
        let today = new Date();
        let yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
    
        // Lấy ngày đầu tuần và cuối tuần
        let firstDayOfWeek = new Date(today);
        firstDayOfWeek.setDate(today.getDate() - today.getDay() + 1);
        let lastDayOfWeek = new Date(today);
        lastDayOfWeek.setDate(today.getDate() + (7 - today.getDay()));
    
        // Lấy ngày đầu tháng và cuối tháng
        let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    
        // Lấy ngày đầu và cuối của tuần trước
        let firstDaysWeekAgo = new Date(firstDayOfWeek);
        firstDaysWeekAgo.setDate(firstDayOfWeek.getDate() - 7);
        let lastDaysWeekAgo = new Date(lastDayOfWeek);
        lastDaysWeekAgo.setDate(lastDaysWeekAgo.getDate() - 7);
    
        // Lấy ngày đầu và cuối của tháng trước
        let firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        let lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0); // Ngày cuối của tháng trước
    
        // Lấy ngày đầu và cuối của 7 ngày trước
        let fSevenDayAgo = new Date(today);
        fSevenDayAgo.setDate(today.getDate() - 7);
        let lSevenDayAgo = new Date(today);
    
        // Lấy ngày đầu và cuối của 14 ngày trước
        let ftDayAgo = new Date(fSevenDayAgo);
        ftDayAgo.setDate(fSevenDayAgo.getDate() - 7);
        let ltDayAgo = new Date(today);
    
        // Lấy ngày đầu và cuối của 30 ngày trước
        let fttDayAgo = new Date(today);
        fttDayAgo.setDate(fttDayAgo.getDate() - 30);
        let lttDayAgo = new Date(today);
        
        return [
            {label: 'Thời gian', formDate:null, toDate:null},
            {label: 'Hôm nay', fromDate: today, toDate: today},
            {label: 'Tuần này', fromDate: firstDayOfWeek, toDate: lastDayOfWeek},
            {label: 'Tháng này', fromDate: firstDayOfMonth, toDate:lastDayOfMonth},
            {label: 'Tuần trước', fromDate: firstDaysWeekAgo,toDate: lastDaysWeekAgo},
            {label: 'Tháng trước', fromDate: firstDayOfLastMonth, toDate: lastDayOfLastMonth}
        ]
      }

    async downloadFile(item: any) {
        var url = `${environment.apiDomain.fileEndpoint}/${item.path}`;
        //if (item.extention == ".jpeg" || item.extention == ".png" || item.extention == ".jpg" || item.extention == ".bmp" || item.extention == ".gif" || item.extention == ".bmp" || item.extention == ".apng" || item.extention == ".ico" || item.extention == ".webp" || item.extention == ".avif")
        if (item.type == 1)
            url = `${environment.apiDomain.fileEndpoint}/Original/${item.path}`;

        fetch(url)
            .then(async (response) => {
                const blob = await response.blob();

                const urlDownload = window.URL.createObjectURL(blob);
                const link = document.createElement("a");

                link.href = urlDownload;
                link.setAttribute("download", item.fileName);
                link.click();
            }
            )
        return false;
    }

}
