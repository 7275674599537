import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { DialogService } from 'primeng/primeng';
import { SecondPageIndexBase } from '../lib-shared/classes/base/second-page-index-base';
import { LogActions, Status } from '../work/work.enum';
import { WmLogWorksService } from '../work/services/WmLogWorks.service';
import { TaskViewGComponent } from '../task-view-g/task-view-g.component';
var LogWorksGComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LogWorksGComponent, _super);
    function LogWorksGComponent(_injector, _WmLogWorks, dialogService) {
        var _this = _super.call(this, null, _injector) || this;
        _this._injector = _injector;
        _this._WmLogWorks = _WmLogWorks;
        _this.dialogService = dialogService;
        _this.isDashboard = false;
        _this.searchModel = {
            key: '',
            isActive: null,
            isDelete: false
        };
        _this.offset = 0;
        _this.typeStatus = -1;
        _this.logActions = LogActions;
        _this.Status = Status;
        _this.showPopup = [];
        return _this;
    }
    LogWorksGComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isDashboard) {
                            this.idTask = 0;
                            this.offset = 0;
                            this.limit = 50;
                        }
                        else {
                            this.offset = (this.page - 1) * this.limit;
                        }
                        return [4 /*yield*/, this.getData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LogWorksGComponent.prototype.showData = function (idTask, idProject, myTask) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.idTask = idTask;
                        this.idProject = idProject;
                        this.myTask = myTask;
                        return [4 /*yield*/, this.getData()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LogWorksGComponent.prototype.getData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        return [4 /*yield*/, this._WmLogWorks.Gets(this.idProject, this.idTask, this.offset, this.limit, this.sortField, this.isAsc).then(function (rs) {
                                if (rs.status) {
                                    _this.dataSource = rs.data;
                                    _this.total = rs.totalRecord;
                                    // for (let i = 0; i < this.total; i++) {
                                    //   this.showPopup[i] = false;
                                    // }
                                }
                            })];
                    case 1:
                        _a.sent();
                        this.resetBulkSelect();
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    LogWorksGComponent.prototype.getDataSort = function (type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        return [4 /*yield*/, this._WmLogWorks.GetsSort(this.idProject, this.idTask, type, (this.page - 1) * this.limit, this.limit, this.sortField, this.isAsc).then(function (rs) {
                                if (rs.status) {
                                    _this.dataSource = rs.data;
                                    _this.total = rs.totalRecord;
                                    // for (let i = 0; i < this.total; i++) {
                                    //   this.showPopup[i] = false;
                                    // }
                                }
                            })];
                    case 1:
                        _a.sent();
                        this.resetBulkSelect();
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    LogWorksGComponent.prototype.onViewTag = function (type) {
        if (type !== this.typeStatus) {
            this.typeStatus = type;
            if (type !== -1) {
                this.getDataSort(type);
            }
            else {
                this.getData();
            }
        }
    };
    LogWorksGComponent.prototype.onSearch = function () {
        this.getData();
    };
    LogWorksGComponent.prototype.onDelete = function (id) {
        var _this = this;
        this._notifierService.showConfirm('Xóa bản ghi?', 'Bạn có chắc muốn xóa bản ghi này?').then(function (rs) {
            _this._WmLogWorks.delete(id).then(function (re) {
                if (re.status) {
                    _this._notifierService.showDeleteDataSuccess();
                    _this.dataSource = _this.dataSource.filter(function (x) { return x.id !== id; });
                }
            });
        }).catch(function (err) {
            _this._notifierService.showDeleteDataError();
        });
    };
    LogWorksGComponent.prototype.showTaskView = function (item) {
        var _this = this;
        this.ref = this.dialogService.open(TaskViewGComponent, {
            data: {
                id: item.task.id,
                idProject: item.idProject
            },
            showHeader: false,
            header: '',
            width: '95%',
            height: 'calc(100vh - 50px)',
            styleClass: "vs-modal",
            contentStyle: { 'overflow': 'auto' },
            baseZIndex: 1001,
            closeOnEscape: true
        });
        this.ref.onClose.subscribe(function (re) {
            if (re != null) {
                _this.isLoading = false;
            }
        });
    };
    LogWorksGComponent.prototype.getAvatar = function (avatar) {
        if (avatar != null)
            return this.getImageAvatar(avatar);
        else
            return "/assets/images/rocket.svg";
    };
    LogWorksGComponent.prototype.getAvatarUser = function (avatar) {
        if (avatar != null)
            return this.getImageAvatar(avatar);
        else
            return "/assets/images/avatar.jpg";
    };
    return LogWorksGComponent;
}(SecondPageIndexBase));
export { LogWorksGComponent };
