import * as tslib_1 from "tslib";
import * as signalR from '@aspnet/signalr';
import { ModuleConfigService } from './module-config.service';
import { VsAuthenService } from '../auth/authen.service';
import { TopicUsersService } from './topicusers.service';
var SignalRService = /** @class */ (function () {
    function SignalRService(_moduleConfigService, _TopicUsersService, _authenService) {
        this._moduleConfigService = _moduleConfigService;
        this._TopicUsersService = _TopicUsersService;
        this._authenService = _authenService;
        this.maxRetryTimes = 10;
        this.wait = function (ms) { return new Promise(function (r) { return setTimeout(r, ms); }); };
        this._moduleConfig = this._moduleConfigService.getConfig();
    }
    SignalRService.prototype.start = function (hub, viewCode, callback) {
        var _this = this;
        // kết nối lần đầu
        this.createConnection(hub);
        this.startConnection().then(function (rs) {
            _this.subscribeViewCode(viewCode, callback);
            _this.autoReconnect(hub);
        });
        // // Cứ sau 5 phút thì refresh lại kết nối
        // setInterval(() => {
        //     this.createConnection(hub);
        //     this.startConnection().then(rs => {
        //         this.subscribeViewCode(viewCode, callback);
        //         //this.autoReconnect(hub);
        //     });
        // }, 5000)  // 5 phút reconnect lại để đảm bảo không bị mất kết nối
    };
    SignalRService.prototype.startList = function (hub, viewCode, callback) {
        var _this = this;
        this.createConnection(hub);
        this.startConnection().then(function (rs) {
            viewCode.forEach(function (element) {
                _this.subscribeViewCode(element, callback);
            });
            _this.autoReconnect(hub);
        });
    };
    SignalRService.prototype.stop = function () {
        this._hubConnection.stop();
    };
    SignalRService.prototype.subscribeViewCode = function (viewCode, callback) {
        if (this.viewCodeSubs !== undefined) {
            this.viewCodeSubs.push({ viewCode: viewCode, callback: callback });
        }
        else {
            this.viewCodeSubs = [{ viewCode: viewCode, callback: callback }];
        }
        this.subscribe(viewCode, callback);
    };
    SignalRService.prototype.createConnection = function (hub) {
        var _this = this;
        this._hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(this._moduleConfig.Services.NotificationEndpoint + "/" + hub, { accessTokenFactory: function () { return _this._authenService.getAccessToken(); } })
            .build();
    };
    SignalRService.prototype.autoReconnect = function (topic) {
        var _this = this;
        this._hubConnection.onclose(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.startConnection()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    SignalRService.prototype.startConnection = function (retryCount) {
        var _this = this;
        if (retryCount === void 0) { retryCount = 1; }
        return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                // not sure about it :)))
                // https://github.com/aspnet/SignalR/issues/2389
                Object.defineProperty(WebSocket, 'OPEN', { value: 1, });
                this._hubConnection
                    .start().then(function () {
                    resolve(true);
                })
                    .catch(function (err) {
                    if (_this.maxRetryTimes - retryCount > 0) {
                        return _this.wait(retryCount * retryCount * 1000)
                            .then(_this.startConnection.bind(_this, ++retryCount))
                            .then(resolve)
                            .catch(reject);
                    }
                    return reject(err);
                });
                return [2 /*return*/];
            });
        }); });
    };
    SignalRService.prototype.subscribe = function (viewCode, callback) {
        this._hubConnection.invoke('Join', viewCode).catch(function (err) { return console.error(err.toString()); });
        this._hubConnection.on(viewCode, function (data) {
            callback(data);
        });
    };
    //CUSTOM SEND
    SignalRService.prototype.createConnectionDapFoodAPI = function (hub) {
        this._hubConnection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Debug)
            .withUrl("https://devnotification.dapfood.net/NotificationHub", {
            // accessTokenFactory: () => this._authenService.getAccessToken(),
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        })
            .build();
    };
    SignalRService.prototype.SendDataWeb = function (hub, Method, data) {
        var _this = this;
        this.createConnectionDapFoodAPI(hub);
        this.startConnection().then(function (rs) {
            Method.forEach(function (element) {
                _this.sendDataByViewCode(element, data);
            });
            _this.autoReconnect(hub);
        });
    };
    SignalRService.prototype.sendDataByViewCode = function (Method, data) {
        this._hubConnection.invoke(Method, data).catch(function (err) { return console.error(err.toString()); });
    };
    return SignalRService;
}());
export { SignalRService };
