import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output, Injector, ElementRef, ViewChild } from '@angular/core';
import { SecondPageEditBase } from '../../lib-shared/classes/base/second-page-edit-base';
import { User } from '../../lib-shared/models/user';
import { UserService } from '../../lib-shared/services/user.service';
import { ConfigurationService } from '../../lib-shared/services/configuration.service';

@Component({
  selector: 'app-comment-form-g',
  templateUrl: './comment-form-g.component.html',
  styleUrls: ['./comment-form-g.component.scss']
})
export class CommentFormGComponent extends SecondPageEditBase implements OnInit {
  @Input() initialText: string = '';
  @Input() item: any;
  @Input() fileAttachment: any;
  @Output() handleSubmit = new EventEmitter<{ form: any }>();

  form!: FormGroup;
  attachment: any;

  isOpenEmoji: boolean = false;
  filePaste: any;

  apiUrl = '';
  maxFileSize = 0;

  @ViewChild('textareaRef') textareaRef!: ElementRef;

  currentUser = new User;
  constructor(
    private _userService: UserService,
    protected _injector: Injector,
    private _configurationService: ConfigurationService,
    private fb: FormBuilder,
  ) {
    super(null, _injector);
  }

  ngOnInit() {
    this.currentUser = this._userService.getBasicUserInfo();

    this.form = this.fb.group({
      message: [this.initialText],
      image: [this.fileAttachment]
    });

    this.form.get('image').valueChanges.subscribe(attachment => {
      if (attachment) {
        this.form.get('message').clearValidators();
        this.form.get('message').updateValueAndValidity();
      } else {
        this.form.get('message').setValidators(Validators.required);
        this.form.get('message').updateValueAndValidity();
      }
    });
  }

  ngAfterViewInit() {
    this.textareaRef.nativeElement.addEventListener('input', () => {
      this.adjustTextareaHeight();
    });
  }

  openEmoji() {
    console.log(!this.isOpenEmoji);
  }

  adjustTextareaHeight() {
    const textarea = this.textareaRef.nativeElement;
    if (!this.form.get('message').value && !this.initialText) {
      textarea.style.height = 'auto';
    } else {
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }

  submit(event: Event) {
    event.preventDefault();
    this.onSubmit();
  }

  onSubmit(): void {
    this.handleSubmit.emit(this.form.value);
    this.form.reset();
  }

  onPaste(file: any) {
    this.filePaste = file
  }
}
