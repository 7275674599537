import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../lib-shared/services/base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WmProjectColsService = /** @class */ (function (_super) {
    tslib_1.__extends(WmProjectColsService, _super);
    function WmProjectColsService(http, injector) {
        return _super.call(this, http, injector, environment.apiDomain.dapWorkEndPoint + "/WmProjectCols") || this;
    }
    WmProjectColsService.prototype.Gets = function (key, idProject, offset, limit, sortField, isAsc) {
        if (isAsc === void 0) { isAsc = false; }
        var queryString = this.serviceUri + "?key=" + key + "&idProject=" + idProject + "&offset=" + offset + "&limit=" + limit + "&sortField=" + sortField + "&isAsc=" + isAsc;
        return this.defaultGet(queryString);
    };
    WmProjectColsService.prototype.GetByProjects = function (idProject, idProjectCol) {
        var queryString = this.serviceUri + "/GetByProjects?idProject=" + idProject + "&idProjectCol=" + idProjectCol;
        return this.defaultGet(queryString);
    };
    WmProjectColsService.prototype.GetDetail = function (Id) {
        var queryString = this.serviceUri + "/GetDetail?id=" + Id;
        return this.defaultGet(queryString);
    };
    WmProjectColsService.prototype.ChangeDelete = function (Id, idProjectColMove) {
        var _this = this;
        return this._http
            .post(this.serviceUri + "/ChangeDelete?id=" + Id + "&idProjectColMove=" + idProjectColMove, Id)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    WmProjectColsService.prototype.ChangeActive = function (Id) {
        var _this = this;
        return this._http
            .post(this.serviceUri + "/ChangeActive?id=" + Id, Id)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    WmProjectColsService.prototype.ChangeSort = function (Id, Sort) {
        var _this = this;
        return this._http
            .post(this.serviceUri + "/ChangeSort?id=" + Id + "&sort=" + Sort, null)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    WmProjectColsService.prototype.Save = function (item) {
        var _this = this;
        return this._http
            .post(this.serviceUri, item)
            .pipe(catchError(function (err) { return _this.handleError(err, _this._injector); })).toPromise();
    };
    WmProjectColsService.ngInjectableDef = i0.defineInjectable({ factory: function WmProjectColsService_Factory() { return new WmProjectColsService(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR)); }, token: WmProjectColsService, providedIn: "root" });
    return WmProjectColsService;
}(BaseService));
export { WmProjectColsService };
