import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/primeng';
import { SecondPageEditBase } from '../lib-shared/classes/base/second-page-edit-base';
import { UserGlobalService } from '../services/user-global.service';
var popupUserInfo = /** @class */ (function (_super) {
    tslib_1.__extends(popupUserInfo, _super);
    function popupUserInfo(_injector, wmUserService, config, ref) {
        var _this = _super.call(this, null, _injector) || this;
        _this._injector = _injector;
        _this.wmUserService = wmUserService;
        _this.config = config;
        _this.ref = ref;
        _this.modelEdit = {};
        return _this;
    }
    popupUserInfo.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.showData) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getDetailUser(this.userId)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    popupUserInfo.prototype.getDetailUser = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.wmUserService.GetDetail(id).then(function (rs) {
                            if (rs.status) {
                                _this.modelEdit = rs.data;
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    popupUserInfo.prototype.getAvatar = function (avatar) {
        if (avatar != null)
            return this.getImageAvatar(avatar);
        else
            return "/assets/images/avatar.jpg";
    };
    popupUserInfo.prototype.urlMess = function () {
        return "/chat/" + this.userId;
    };
    return popupUserInfo;
}(SecondPageEditBase));
export { popupUserInfo };
