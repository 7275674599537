import { Component, OnInit, Injector, Input, SimpleChanges } from '@angular/core';
import { SecondPageEditBase } from '../lib-shared/classes/base/second-page-edit-base';
import { ActiveCommentInterface } from '../lib-shared/types/activeComment.interface';
import { User } from '../lib-shared/models/user';
import { UserService } from '../lib-shared/services/user.service';
import { WmTaskCommentsService } from '../work/services/WmTaskComments.service';

@Component({
  selector: 'app-comments-g',
  templateUrl: './comments-g.component.html',
  styleUrls: ['./comments-g.component.scss']
})
export class CommentsGComponent extends SecondPageEditBase implements OnInit {
  @Input() item?: any;
  comments: any[];
  activeComment: ActiveCommentInterface | null = null;
  modelEdit: any = {
    id: 0,
    content: '',
    idParent: null,
  };

  listComment: any = {
    id: 0,
    message: '',
    idParent: null,
  };


  currentUser: User;
  constructor(
    protected _injector: Injector,
    private _userService: UserService,
    private _WmTaskCommentsService: WmTaskCommentsService,
  ) {
    super(null, _injector);
  }

  async ngOnInit() {
    this.currentUser = await this._userService.getCurrentUser();
  }

  async showData(idTask) {
    this.item.id = idTask;
    await this.getComments();
    await this.getRootComments();
  }

  async getComments() {
    await this._WmTaskCommentsService.Gets('', this.item.id, 0, 100, '', false)
      .then(res => {
        if (res.status) {
          this.item.comments = res.data.reverse();
          this.item.remainingComments = res.totalRecord - 1;
        }
        else {
          this._notifierService.showError(res.message);
        }
      });
  }

  getRootComments(): any[] {
    if (this.item && this.item.comments) {
      return this.item.comments.filter((comment) => comment.idParent === null);
    }
    return [];
  }


  getReplies(commentId: number) {
    return this.item.comments
      .filter((comment) => comment.idParent === commentId)
      .sort(
        (a, b) =>
          new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
      );
  }

  setActiveComment(activeComment: ActiveCommentInterface | null) {
    this.activeComment = activeComment;
  }

  deleteComment(commentId: number): void {
    this._notifierService.showConfirm('Xoá bình luận này', 'Bạn có chắc muốn xóa ?').then(rs => {
      this._WmTaskCommentsService.delete(commentId).then(re => {
        if (re.status) {
          this.item.comments = this.item.comments.filter(
            (comment) => comment.id !== commentId
          );
          this._notifierService.showSuccess(re.message);
        }
        else this._notifierService.showError(re.message);
      })
    });
  }

  updateComment({ form, commentId }: { form: any; commentId: number; }) {
    let index = this.item.comments.findIndex(x => x.id === commentId);
    if (index !== -1) {
      this.item.comments[index].message = form.message;
      this.item.comments[index].image = form.image;
      this.modelEdit.id = commentId;
      this.modelEdit.message = form.message;
      this.modelEdit.image = form.image;

      this._WmTaskCommentsService.Save2(this.modelEdit).then(re => {
        if (re.status) {
          this.item.comments[index].updatedDate = re.data.commentInfo.updatedDate;
          this.activeComment = null;
        } else {
          console.log('Error:', re.message);
        }
      });
    } else {
      console.log('ko bình luận được trên id:', commentId);
    }
  }


  addComment({ form, parentId }: { form: any; parentId: number; }) {
    this.modelEdit = {};
    this.modelEdit.idTask = this.item.id;
    this.modelEdit.message = form.message;
    this.modelEdit.idParent = parentId;
    this.modelEdit.image = form.image;

    this._WmTaskCommentsService.Save2(this.modelEdit).then(re => {
      if (re.status) {
        this.item.comments.push({
          ...re.data.commentInfo,
          countLike: 0,
          userLiked: false,
          likeUser: []
        });
        this.activeComment = null;
        this._notifierService.showSuccess('Cập nhật thành công');
      }
      else {
        this._notifierService.showError(re.message);
      }
    });
  }
}
