import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FileUpload } from 'primeng/primeng';
import { ComponentBase } from '../../../classes/base/component-base';
var VsViewImageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(VsViewImageComponent, _super);
    function VsViewImageComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.file = '';
        _this.width = 80;
        _this.height = 80;
        _this.class = '';
        _this.size = 'avatar';
        _this.defaultNoImageUrl = 'assets/images/no_image.jpg';
        _this.rawFileName = '';
        _this.apiUrl = '';
        _this.uploading = false;
        return _this;
    }
    // constructor(
    // ) {
    // }
    VsViewImageComponent.prototype.ngOnInit = function () { };
    VsViewImageComponent.prototype.getImageUrl = function () {
        if (this.file) {
            if (this.file.indexOf('=') > 0) {
                return this.file;
            }
            else {
                if (this.size === 'avatar' || this.size === 'a') {
                    return this.getImageAvatar(this.file);
                }
                else if (this.size === 'medium' || this.size === 'm') {
                    return this.getImageMedium(this.file);
                }
                else if (this.size === 'large' || this.size === 'l') {
                    return this.getImageLarge(this.file);
                }
                else if (this.size === 'original' || this.size === 'o') {
                    return this.getImageOriginal(this.file);
                }
            }
        }
        else {
            return this.defaultNoImageUrl;
        }
    };
    VsViewImageComponent.prototype.onError = function (ev) {
        ev.srcElement.src = this.defaultNoImageUrl;
    };
    return VsViewImageComponent;
}(ComponentBase));
export { VsViewImageComponent };
