import * as tslib_1 from "tslib";
import { Title } from '@angular/platform-browser';
import { OnInit, OnDestroy, AfterViewInit, EventEmitter } from '@angular/core';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { GlobalService } from './services/global.service';
import { VsMySettingService } from './services/ccmysetting.service';
import { VsMySetting } from './models/ccmysetting';
import { CustomRouterService } from './lib-shared/services/custom-router.service';
import { NotifierService } from './lib-shared/services/notifier.service';
import { CommonService } from './lib-shared/services/common.service';
import { UserService } from './lib-shared/services/user.service';
import { StatementsService } from './dapfood/services/statements.service';
import 'rxjs/add/operator/toPromise';
import { SignalRService } from './lib-shared/services/signalr.service';
import { OmiCallsService } from './lib-shared/services/omicall.service';
import { EventEmitterService } from './services/eventemitter.service';
var AppTopBarComponent = /** @class */ (function () {
    function AppTopBarComponent(app, _activatedRoute, _customRouteService, _StatementsService, _router, _userService, _globalService, _mySettingService, _signalRService, _commonService, _EventEmitterService, _omiCallsService, _notifierService, titleService) {
        this.app = app;
        this._activatedRoute = _activatedRoute;
        this._customRouteService = _customRouteService;
        this._StatementsService = _StatementsService;
        this._router = _router;
        this._userService = _userService;
        this._globalService = _globalService;
        this._mySettingService = _mySettingService;
        this._signalRService = _signalRService;
        this._commonService = _commonService;
        this._EventEmitterService = _EventEmitterService;
        this._omiCallsService = _omiCallsService;
        this._notifierService = _notifierService;
        this.titleService = titleService;
        this.environment = environment;
        this.searchKey = '';
        this._unSubscribeAll = new Subject();
        this.currentRoute = '';
        this.fileApi = '';
        this.position = '';
        this.avatarUrl = 'assets/images/avatar.jpg';
        this.mySetting = new VsMySetting();
        this.mySettingEdit = new VsMySetting();
        this.formGroup = [];
        this.balance = 0;
        this.balanceBlock = 0;
        this.titleChange = false;
        this.vsclosePopup = new EventEmitter();
        this.fileApi = this.environment.apiDomain.fileEndpoint;
    }
    AppTopBarComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, config;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.mySetting = this._mySettingService.getCurrentSetting();
                        _a = this;
                        return [4 /*yield*/, this._userService.getCurrentUser()];
                    case 1:
                        _a.crrUser = _b.sent();
                        this.crrUser.name = this.crrUser.displayName;
                        this._EventEmitterService.updateCountIconMessageChat.subscribe(function (item) { return _this.mySetting = item; });
                        this.getImageUrl();
                        this.loadSatement();
                        this._signalRService.start(environment.signalrConfig.hub.notification, this.crrUser.userId.toString(), this.notifyTrigger.bind(this));
                        config = {
                            theme: 'default',
                            options: {
                                draggable: true,
                                showNoteInput: true,
                                hideCallButton: true
                            },
                            ringtoneVolume: 0.1,
                            classes: {
                                dialog: 'custom-dialog-call',
                            },
                            styles: {
                                dialog: {
                                    'z-index': '9999',
                                    'margin-bottom': '50px',
                                },
                                btnToggle: {
                                    bottom: '50px',
                                    right: '20px',
                                }
                            },
                            callbacks: {
                                register: function (data) {
                                    // Sự kiện xảy ra khi trạng thái kết nối tổng đài thay đổi
                                    // console.log('register:', data);
                                },
                                connecting: function (data) {
                                    // Sự kiện xảy ra khi bắt đầu thực hiện cuộc gọi ra
                                    // console.log('connecting:', data);
                                },
                                invite: function (data) {
                                    // Sự kiện xảy ra khi có cuộc gọi tới
                                    // console.log('invite:', data);
                                },
                                inviteRejected: function (data) {
                                    // Sự kiện xảy ra khi có cuộc gọi tới, nhưng bị tự động từ chối
                                    // trong khi đang diễn ra một cuộc gọi khác
                                    // console.log('inviteRejected:', data);
                                },
                                ringing: function (data) {
                                    // Sự kiện xảy ra khi cuộc gọi ra bắt đầu đổ chuông
                                    // console.log('ringing:', data);
                                },
                                accepted: function (data) {
                                    // Sự kiện xảy ra khi cuộc gọi vừa được chấp nhận
                                    // console.log('accepted:', data);
                                },
                                incall: function (data) {
                                    // Sự kiện xảy ra mỗi 1 giây sau khi cuộc gọi đã được chấp nhận
                                    // console.log('incall:', data);
                                },
                                acceptedByOther: function (data) {
                                    // Sự kiện dùng để kiểm tra xem cuộc gọi bị kết thúc
                                    // đã được chấp nhận ở thiết bị khác hay không
                                    // console.log('acceptedByOther:', data);
                                },
                                ended: function (data) {
                                    // Sự kiện xảy ra khi cuộc gọi kết thúc
                                    // gọi thành công xác nhận lại đơn hàng
                                    var _startTime = new Date(data.startTime);
                                    data.id = 0;
                                    data.startTime = _startTime.toLocaleDateString('en-US') + ' ' + _startTime.toTimeString().substring(0, _startTime.toTimeString().indexOf("GMT"));
                                    var _endTime = new Date(data.endTime);
                                    data.endTime = _endTime.toLocaleDateString('en-US') + ' ' + _endTime.toTimeString().substring(0, _endTime.toTimeString().indexOf("GMT"));
                                    //console.log('ended:', JSON.stringify(data));
                                    _this._omiCallsService.SaveOmiCallLogs(data).then(function (res) {
                                        if (res.status && data.direction == 'outbound') {
                                            if (Object.keys(res.data).length != 0) {
                                                // console.log("res log " + JSON.stringify(res.data));
                                                _this._EventEmitterService.omicall.emit(res.data);
                                            }
                                        }
                                    });
                                },
                                holdChanged: function (status) {
                                    // Sự kiện xảy ra khi trạng thái giữ cuộc gọi thay đổi
                                    // console.log('on hold:', status);
                                },
                                saveCallInfo: function (data) {
                                    // let { callId, note, ...formData } = data;
                                    // Sự kiện xảy ra khi cuộc gọi đã có đổ chuông hoặc cuộc gọi tới, khi user có nhập note input mặc định hoặc form input custom
                                    // console.log('on save call info:', data);
                                    if (data.callId && data.note) {
                                        _this._omiCallsService.UpdateNoteOmicallLog(data.callId, data.note);
                                    }
                                },
                            }
                        };
                        if (this.crrUser.isOmiCall) {
                            omiSDK.init(config, function () {
                                omiSDK.register({
                                    domain: _this.crrUser.omiCallDomain,
                                    username: _this.crrUser.omiCallSipUser,
                                    password: _this.crrUser.omiCallSecretKey
                                });
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AppTopBarComponent.prototype.notifyTrigger = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var obj;
            return tslib_1.__generator(this, function (_a) {
                // Notification
                if (data.type === 1) {
                    this._EventEmitterService.notification.emit(data);
                }
                // Update statement (update data mà không hiển thị noti)
                if (data.type === 3) {
                    if (data.data) {
                        if (data.data.balance) {
                            this.loadSatement();
                        }
                    }
                    this._EventEmitterService.event.emit(data);
                }
                // Chat
                else if (data.type === 4) {
                    obj = JSON.parse(data.data.object);
                    //  Nếu đang mở ở trang khác hiển thị noti và messge
                    if (this._router.url.includes("/chat") == false) {
                        this.mySetting.totalMessNotReaded += 1;
                        this._mySettingService.setCurrentSetting(this.mySetting);
                        this._notifierService.showChat(obj.message);
                        this.setTitlePage();
                    }
                    else {
                        if (this._router.url.includes("/chat/" + this.crrUser.userId) == false) {
                            this.mySetting.totalMessNotReaded += 1;
                            this._mySettingService.setCurrentSetting(this.mySetting);
                            this.setTitlePage();
                        }
                        this._EventEmitterService.chat.emit(data);
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    AppTopBarComponent.prototype.setTitlePage = function () {
        var _this = this;
        this.titleChange = false;
        clearInterval(this.interval);
        if (this.mySetting.totalMessNotReaded > 0) {
            this.interval = setInterval(function () {
                if (_this._router.url.includes("/chat/")) {
                    clearInterval(_this.interval);
                    _this.titleService.setTitle("Work management");
                }
                if (_this.titleChange) {
                    _this.titleService.setTitle("Tin nh\u1EAFn m\u1EDBi (" + _this.mySetting.totalMessNotReaded + ")");
                }
                else {
                    _this.titleService.setTitle("Work management");
                }
                _this.titleChange = !_this.titleChange;
            }, 1000);
        }
        else {
            clearInterval(this.interval);
            this.titleService.setTitle("Work management");
        }
    };
    AppTopBarComponent.prototype.onMessagesClick = function () {
        this.mySetting.totalMessNotReaded = 0;
        this._mySettingService.setCurrentSetting(this.mySetting);
    };
    AppTopBarComponent.prototype.getImageUrl = function () {
        if (this.crrUser && this.crrUser.avatar) {
            this.avatarUrl = environment.apiDomain.fileEndpoint + "/files/image/Avatar/" + this.crrUser.avatar; // `${this.fileApi}/files/getfile?id=${this.currentUser.avatar}`;
        }
        else {
            this.avatarUrl = 'assets/images/avatar.jpg';
        }
    };
    AppTopBarComponent.prototype.loadSatement = function () {
        var _this = this;
        this._StatementsService.GetBalance().then(function (rs) {
            if (rs.status) {
                _this.balance = rs.data.balance;
                _this.balanceBlock = rs.data.cashInTransit;
            }
        });
    };
    AppTopBarComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // first time
        var url = top.location.href;
        if (url.indexOf('#searchKey=') > -1) {
            var obj = this._customRouteService.parseFragment(url.split('#')[1]);
            if (obj.searchKey) {
                this.searchKey = decodeURIComponent(obj.searchKey.trim());
            }
        }
        this._sub = this._router.events.pipe(filter(function (e) { return e instanceof NavigationEnd; })).subscribe(function (r) {
            var fragment = _this._customRouteService.parseFragment(location.hash.substring(1));
            if (fragment.searchKey) {
                _this.searchKey = decodeURIComponent(fragment.searchKey);
            }
            else {
                _this.searchKey = '';
            }
            _this._globalService.setSearchBoxState(true);
        });
    };
    AppTopBarComponent.prototype.ngOnDestroy = function () {
        this._unSubscribeAll.next();
        this._unSubscribeAll.complete();
        if (this._sub) {
            this._sub.unsubscribe();
        }
    };
    AppTopBarComponent.prototype.onTopbarSearchClick = function (event, searchKey) {
        if (searchKey.value !== '') {
            this._router.navigate([location.pathname], { relativeTo: this._activatedRoute, fragment: "searchKey=" + searchKey.value });
        }
        else {
            this._router.navigate([location.pathname], { relativeTo: this._activatedRoute });
        }
    };
    AppTopBarComponent.prototype.showDialog = function () {
        this.display = true;
    };
    AppTopBarComponent.prototype.onCloseDialog = function () {
        this.display = false;
    };
    AppTopBarComponent.prototype.onSaveSettings = function () {
        // const namhoc = this.namHoc_options.find(s => s.value === this.mySettingEdit.idNamHoc);
        // const hocky = this.hocKy_options.find(s => s.value === this.mySettingEdit.idHocKy);
        // const dothi = this.dotThi_options.find(s => s.value === this.mySettingEdit.idDotThi);
        // if (namhoc == null || hocky == null || dothi == null) {
        //     this.notifierService.showError('Bạn phải chọn đợt thi');
        // } else {
        // this.mySetting.idNamHoc = namhoc.value;
        // this.mySetting.idHocKy = hocky.value;
        // this.mySetting.idDotThi = dothi.value;
        // this.mySetting.tenNamHoc = namhoc.label;
        // this.mySetting.khHocKy = hocky.label;
        // this.mySetting.tenDotThi = dothi.label;
        // this._mySettingService.setCurrentSetting(this.mySetting);
        // this.notifierService.showSuccess('success', 'Lưu thiết lập thành công');
        // this.onCloseDialog();
        // window.location.reload();
        // }
    };
    AppTopBarComponent.prototype.onReturn = function () {
        // if (this.mySetting.idNamHoc == null || this.mySetting.idHocKy == null || this.mySetting.idDotThi == null) {
        //     this.notifierService.showError('Bạn chưa thiết lập đợt thi');
        //     return;
        // } else {
        //     this.onCloseDialog();
        // }
    };
    return AppTopBarComponent;
}());
export { AppTopBarComponent };
