import { ElementRef } from "@angular/core";
var AutofocusDirective = /** @class */ (function () {
    function AutofocusDirective(el) {
        this.el = el;
        this.focus = true;
    }
    AutofocusDirective.prototype.ngOnInit = function () {
        var _this = this;
        if (this.focus) {
            //Otherwise Angular throws error: Expression has changed after it was checked.
            window.setTimeout(function () {
                _this.el.nativeElement.focus(); //For SSR (server side rendering) this is not safe. Use: https://github.com/angular/angular/issues/15008#issuecomment-285141070)
            });
        }
    };
    Object.defineProperty(AutofocusDirective.prototype, "autofocus", {
        set: function (condition) {
            this.focus = condition !== false;
        },
        enumerable: true,
        configurable: true
    });
    return AutofocusDirective;
}());
export { AutofocusDirective };
