import { environment } from '../../../environments/environment';
var ConfigurationService = /** @class */ (function () {
    function ConfigurationService() {
    }
    Object.defineProperty(ConfigurationService.prototype, "maxFileSize", {
        get: function () {
            return 20000000000;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "dateFormat", {
        get: function () {
            return 'dd/MM/yyyy';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "dateTimeFormat", {
        get: function () {
            return 'dd/MM/yyyy HH:mm:ss';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "logSessionKey", {
        //   get apiLogs(): string {
        //     return `${environment.apiDomain.logEndpoint}/Logs`;
        //   }
        //   get loginUrl(): string {
        //     return `${environment.apiDomain.remoteStorageOrigin}/dang-nhap`;
        //   }
        get: function () {
            return 'log_session_key';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "defaultImageUrl", {
        get: function () {
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "apiFsFile", {
        get: function () {
            return environment.apiDomain.fileEndpoint + "/Files";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "apiFsFolder", {
        get: function () {
            return environment.apiDomain.fileEndpoint + "/Folders";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "fsAppFolder", {
        get: function () {
            return 'dapfood';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "googleViewOnline", {
        get: function () {
            return 'https://docs.google.com/viewerng/viewer?embedded=false&url=';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "fileUploadUrl", {
        get: function () {
            return environment.apiDomain.fileEndpoint + "/upload";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "notificationUrl", {
        get: function () {
            return environment.apiDomain.dapFoodEndPoint + "/thongbao";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "calendarVietnamese", {
        get: function () {
            return {
                firstDayOfWeek: 0,
                dayNames: [
                    'Chủ nhật',
                    'Thứ hai',
                    'Thứ ba',
                    'Thứ tư',
                    'Thứ năm',
                    'Thứ 6',
                    'Thứ 7'
                ],
                dayNamesShort: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
                dayNamesMin: ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'],
                monthNames: [
                    'Tháng một',
                    'Tháng  hai',
                    'Tháng ba',
                    'Tháng tư',
                    'Tháng năm',
                    'Tháng sáu',
                    'Tháng bảy',
                    'Tháng tám',
                    'Tháng chín',
                    'Tháng mười',
                    'Tháng mười một',
                    'Tháng mười hai'
                ],
                monthNamesShort: [
                    'T1',
                    'T2',
                    'T3',
                    'T4',
                    'T5',
                    'T6',
                    'T7',
                    'T8',
                    'T9',
                    'T10',
                    'T11',
                    'T12'
                ],
                today: 'Hôm nay',
                clear: 'xóa'
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "authConfig", {
        get: function () {
            return {
                issuer: environment.authenticationSettings.issuer,
                clientId: environment.authenticationSettings.clientId,
                redirectUri: window.location.origin,
                silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
                requireHttps: true,
                scope: 'openid profile email',
                showDebugInformation: true,
                sessionChecksEnabled: false,
                requestAccessToken: true
            };
        },
        enumerable: true,
        configurable: true
    });
    return ConfigurationService;
}());
export { ConfigurationService };
