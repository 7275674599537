import * as tslib_1 from "tslib";
import { Injector, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/primeng';
import { SecondPageEditBase } from '../lib-shared/classes/base/second-page-edit-base';
import { CommentsGComponent } from '../comments-g/comments-g.component';
import { WmTasksService } from '../work/services/WmTasks.service';
import { WmProjectColsService } from '../work/services/WmProjectCols.service';
import { WmProjectMembersService } from '../work/services/WmProjectMembers.service';
import { UserService } from '../lib-shared/services/user.service';
// import { CommentsTaskComponent } from '../comments-task/comments-task.component';
var TaskViewGComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TaskViewGComponent, _super);
    function TaskViewGComponent(_injector, formBuilder, _WmTasksService, _WmProjectColsService, _WmProjectMembersService, _UserService, config, ref) {
        var _this = _super.call(this, null, _injector) || this;
        _this._injector = _injector;
        _this.formBuilder = formBuilder;
        _this._WmTasksService = _WmTasksService;
        _this._WmProjectColsService = _WmProjectColsService;
        _this._WmProjectMembersService = _WmProjectMembersService;
        _this._UserService = _UserService;
        _this.config = config;
        _this.ref = ref;
        _this.isLoading = false;
        _this.modelEdit = {
            percent: 0,
            type: 1,
            priority: 3,
            user: {
                avatar: null
            },
            projectCol: {
                label: ""
            },
            assignee: {
                label: "",
                avatar: ""
            }
        };
        _this.crrUser = {};
        _this.participant = [];
        _this.projectCol_options = [];
        _this.priority_options = _this.getTypeOption();
        _this.type_options = _this.getTypeOption();
        _this.star_options = _this.getStarOption();
        _this.users_options = [];
        _this.openDetail = true;
        _this.openTime = true;
        _this.activityType = 1;
        _this.isEdit = false;
        _this.formGroup = _this.formBuilder.group({
            name: [''],
            description: [''],
            detail: [''],
            endDate: [''],
            completedDate: [''],
            startDate: [''],
            percent: [''],
            priority: [''],
            idStatus: [''],
            attachment: [''],
            type: [''],
            project: [''],
            idAssignee: [''],
            participant: ['']
        });
        return _this;
    }
    TaskViewGComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this._UserService.getCurrentUser()];
                    case 1:
                        _a.crrUser = _b.sent();
                        this.modelEdit.user = this.crrUser;
                        this.modelEdit.idStatus = this.config.data.idStatus == 0 ? null : this.config.data.idStatus;
                        this.modelEdit.idProject = this.config.data.idProject;
                        return [4 /*yield*/, this.getProjectColOption()];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, this.GetsByIdProject()];
                    case 3:
                        _b.sent();
                        return [4 /*yield*/, this.GetDetail(this.config.data.id)];
                    case 4:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TaskViewGComponent.prototype.GetsByIdProject = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.users_options = [];
                        return [4 /*yield*/, this._WmProjectMembersService.GetsByIdProject("", this.config.data.idProject, 0, 1000).then(function (rs) {
                                if (rs.status) {
                                    rs.data.forEach(function (value) {
                                        _this.users_options.push({ label: value.name, value: value.userId, avatar: value.avatar });
                                    });
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TaskViewGComponent.prototype.getProjectColOption = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._WmProjectColsService.Gets('', this.config.data.idProject, 0, 100, '', true).then(function (rs) {
                            if (rs.status) {
                                rs.data.forEach(function (value) {
                                    _this.projectCol_options.push({ label: value.name, value: value.id });
                                });
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TaskViewGComponent.prototype.getAvatar = function (avatar) {
        if (avatar != null)
            return this.getImageAvatar(avatar);
        else
            return "/assets/images/avatar.jpg";
    };
    TaskViewGComponent.prototype.GetDetail = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.modelEdit = {};
                        if (!(id > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._WmTasksService.getDetail(id).then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var _this = this;
                                return tslib_1.__generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!response.status) return [3 /*break*/, 3];
                                            this.modelEdit = response.data;
                                            console.log(response.data);
                                            this.modelEdit.completedDate = this.modelEdit.completedDate ? new Date(this.modelEdit.completedDate) : null;
                                            this.modelEdit.endDate = this.modelEdit.endDate ? new Date(this.modelEdit.endDate) : null;
                                            this.modelEdit.startDate = this.modelEdit.startDate ? new Date(this.modelEdit.startDate) : null;
                                            this.participant = [];
                                            this.modelEdit.wmParticipants.forEach(function (value) {
                                                _this.participant.push(value.userId);
                                            });
                                            if (!(this.activityType == 1)) return [3 /*break*/, 2];
                                            return [4 /*yield*/, this._CommentsGComponent.showData(id)];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2:
                                            // await this._LabelComponent.LoadData(this.modelEdit.idProject);
                                            // Nếu tab log đang được chọn thì show log
                                            // if (this.activityType == 2)
                                            //   await this._LogWorkComponent.showData(this.modelEdit.id, this.modelEdit.idProject, false); // show log
                                            this.setDataModel();
                                            _a.label = 3;
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            }); }, function () {
                                _this._notifierService.showHttpUnknowError();
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    TaskViewGComponent.prototype.getMaxDialogHeight = function () {
        return (window.innerHeight - 200).toString() + 'px';
    };
    TaskViewGComponent.prototype.getUserNameById = function (id) {
        var dataUser = this.users_options.filter(function (x) { return x.value == id; })[0];
        if (dataUser)
            return dataUser.label;
        else
            return "";
    };
    TaskViewGComponent.prototype.getAvatarById = function (id) {
        var dataUser = this.users_options.filter(function (x) { return x.value == id; })[0];
        if (dataUser && dataUser.avatar != null)
            return this.getImageAvatar(dataUser.avatar);
        else
            return "/assets/images/avatar.jpg";
    };
    TaskViewGComponent.prototype.closePopupMethod = function () {
        this.ref.close(true);
    };
    TaskViewGComponent.prototype.setDataModel = function () {
        var _this = this;
        this.modelEdit = tslib_1.__assign({}, this.modelEdit, { projectCol: this.projectCol_options.filter(function (x) { return x.value == _this.modelEdit.idProjectCol; })[0], assignee: this.users_options.filter(function (x) { return x.value == _this.modelEdit.idAssignee; })[0] });
    };
    return TaskViewGComponent;
}(SecondPageEditBase));
export { TaskViewGComponent };
