import { Injector } from '@angular/core';
import { DatePipe } from '@angular/common';
import { VsAuthenService } from '../auth/authen.service';
import * as i0 from "@angular/core";
var MenuOrientation;
(function (MenuOrientation) {
    MenuOrientation[MenuOrientation["STATIC"] = 0] = "STATIC";
    MenuOrientation[MenuOrientation["OVERLAY"] = 1] = "OVERLAY";
    MenuOrientation[MenuOrientation["SLIM"] = 2] = "SLIM";
    MenuOrientation[MenuOrientation["HORIZONTAL"] = 3] = "HORIZONTAL";
})(MenuOrientation || (MenuOrientation = {}));
var CommonService = /** @class */ (function () {
    function CommonService() {
        this.layoutMode = MenuOrientation.STATIC;
    }
    CommonService.prototype.guid = function () {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    };
    CommonService.prototype.stringFormat = function (inputString) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        // tslint:disable-next-line:forin
        for (var index in args) {
            inputString = inputString.replace("{" + index + "}", args[index]);
        }
        return inputString;
    };
    CommonService.prototype.htmlEncode = function (str) {
        return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&quot;');
    };
    // Lấy về giá trị theo path ''
    CommonService.prototype.getValueByPath = function (obj, path) {
        var paths = path.split('.');
        for (var i = 0; i < paths.length; i++) {
            try {
                obj = obj[paths[i]];
            }
            catch (err) {
                obj = null;
            }
        }
        return obj;
    };
    CommonService.prototype.addDays = function (date, days) {
        var cloned = new Date(date);
        cloned.setDate(date.getDate() + days);
        return cloned;
    };
    CommonService.prototype.addWorkDays = function (date, days) {
        var cloned = new Date(date);
        while (days > 0) {
            cloned.setDate(cloned.getDate() + 1);
            if (cloned.getDay() !== 0 && cloned.getDay() !== 6) {
                days -= 1;
            }
        }
        return cloned;
    };
    CommonService.prototype.exportToCSV = function (datas, columns, fileName) {
        var _this = this;
        var headerString = '';
        // Duyệt cột để thêm vào header
        columns.forEach(function (c) {
            headerString += c.header + ',';
        });
        var rowsString = [];
        datas.forEach(function (d) {
            var rowString = '';
            columns.forEach(function (c) {
                // rowString += (typeof d[c.field]).toString() + ',';
                var colVal = '';
                if (c.dataPath) {
                    var colValTmp = _this.getValueByPath(d, c.dataPath);
                    if (colValTmp) {
                        colVal = colValTmp;
                    }
                }
                else if (d[c.field]) {
                    colVal = d[c.field];
                }
                // Format Date
                if (c.dateFormat) {
                    var datePipe = new DatePipe('en-US');
                    colVal = datePipe.transform(colVal, c.dateFormat);
                }
                // Format mapping
                if (c.dataMapping) {
                    c.dataMapping.forEach(function (dm) {
                        if (dm.value === d[c.field]) {
                            colVal = dm.label.toString().replace(',', '.').replace('\n', '').replace('\r', '');
                        }
                    });
                }
                if (colVal) {
                    rowString += colVal.toString().replace(',', '.').replace('\n', '').replace('\r', '') + ',';
                }
                else {
                    rowString += '' + ',';
                }
            });
            rowsString.push(rowString);
        });
        var csv = headerString + '\n';
        for (var _i = 0, rowsString_1 = rowsString; _i < rowsString_1.length; _i++) {
            var row = rowsString_1[_i];
            csv += row + '\n';
        }
        var blob = new Blob(['\uFEFF', csv], { type: 'text/csv' });
        var link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', fileName + '.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
    };
    CommonService.prototype.exportToCSV2 = function (datas, columns, fileName) {
        var _this = this;
        var headerString = '';
        // Duyệt cột để thêm vào header
        columns.forEach(function (c) {
            headerString += c.header + ',';
        });
        var rowsString = [];
        datas.forEach(function (d) {
            var rowString = '';
            columns.forEach(function (c) {
                // rowString += (typeof d[c.field]).toString() + ',';
                var colVal = '';
                if (c.dataPath) {
                    var colValTmp = _this.getValueByPath(d, c.dataPath);
                    if (colValTmp) {
                        colVal = colValTmp;
                    }
                }
                else if (d[c.field]) {
                    colVal = d[c.field];
                }
                // Format Date
                if (c.dateFormat) {
                    var datePipe = new DatePipe('en-US');
                    colVal = datePipe.transform(colVal, c.dateFormat);
                }
                // Format mapping
                if (c.dataMapping) {
                    c.dataMapping.forEach(function (dm) {
                        if (dm.id === d[c.field]) {
                            colVal = dm.name.toString().replace(',', '.').replace('\n', '').replace('\r', '');
                        }
                    });
                }
                if (colVal) {
                    rowString += colVal.toString().replace(',', '.').replace('\n', '').replace('\r', '') + ',';
                }
                else {
                    rowString += '' + ',';
                }
            });
            rowsString.push(rowString);
        });
        var csv = headerString + '\n';
        for (var _i = 0, rowsString_2 = rowsString; _i < rowsString_2.length; _i++) {
            var row = rowsString_2[_i];
            csv += row + '\n';
        }
        var blob = new Blob(['\uFEFF', csv], { type: 'text/csv' });
        var link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', fileName + '.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
    };
    CommonService.prototype.exportToCSVGroup = function (datas, columns, fileName) {
        var _this = this;
        var headerString = '';
        // Duyệt cột để thêm vào header
        for (var i = 0; i < columns.length; i++) {
            headerString += columns[i].header + ',';
        }
        var rowsString = [];
        datas.forEach(function (subData) {
            rowsString.push(subData.groupName);
            rowsString.push(headerString);
            subData.data.forEach(function (d) {
                var rowString = '';
                columns.forEach(function (c) {
                    var colVal = '';
                    if (c.dataPath) {
                        var colValTmp = _this.getValueByPath(d, c.dataPath);
                        if (colValTmp) {
                            colVal = colValTmp;
                        }
                    }
                    else if (d[c.field]) {
                        colVal = d[c.field];
                    }
                    // Format Date
                    if (c.dateFormat) {
                        var datePipe = new DatePipe('en-US');
                        colVal = datePipe.transform(colVal, c.dateFormat);
                    }
                    // Format mapping
                    if (c.dataMapping) {
                        c.dataMapping.forEach(function (dm) {
                            if (dm.id === d[c.field]) {
                                colVal = dm.name.toString().replace(',', '.').replace('\n', '').replace('\r', '');
                            }
                        });
                    }
                    if (colVal) {
                        rowString += colVal.toString().replace(',', '.').replace('\n', '').replace('\r', '') + ',';
                    }
                    else {
                        rowString += '' + ',';
                    }
                });
                rowsString.push(rowString);
            });
            rowsString.push('\n');
        });
        // let csv = headerString + '\n';
        var csv = '';
        for (var _i = 0, rowsString_3 = rowsString; _i < rowsString_3.length; _i++) {
            var row = rowsString_3[_i];
            csv += row + '\n';
        }
        var blob = new Blob(['\uFEFF', csv], { type: 'text/csv' });
        var link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(blob));
        link.setAttribute('download', fileName + '.csv');
        document.body.appendChild(link); // Required for FF
        link.click();
    };
    CommonService.prototype.convertToSelectItem = function (data, valueField, textField, textField2, prefixTextField2, allowNull) {
        if (textField === void 0) { textField = ''; }
        if (textField2 === void 0) { textField2 = ''; }
        if (prefixTextField2 === void 0) { prefixTextField2 = ''; }
        if (allowNull === void 0) { allowNull = true; }
        if (!textField || textField === '') {
            textField = valueField;
        }
        var tempArr = [];
        if (allowNull) {
            tempArr.push({ label: '', value: '' });
        }
        // tslint:disable-next-line:forin
        for (var ind in data) {
            var item = data[ind];
            tempArr.push({ label: this.getLabel(item, textField, textField2, prefixTextField2), value: item[valueField] });
        }
        return tempArr;
    };
    CommonService.prototype.getLabel = function (item, textField, textField2, prefixTextField2) {
        if (textField2) {
            return item[textField] + ' - ' + prefixTextField2 + ' ' + item[textField2];
        }
        else {
            return item[textField];
        }
    };
    CommonService.prototype.convertToSelectItemMasterData = function (data, valueField, textField) {
        if (valueField === void 0) { valueField = 'id'; }
        if (textField === void 0) { textField = 'name'; }
        return this.convertToSelectItem(data, valueField, textField);
    };
    CommonService.prototype.handleError = function (error, injector) {
        // console.error('Có lỗi xảy ra', error);
        if (error.status === 401) {
            var authenService = injector.get(VsAuthenService);
            authenService.logout();
        }
        else {
        }
        return Promise.reject(error);
    };
    CommonService.prototype.onTopbarItemClick = function (event, item) {
        this.topbarItemClick = true;
        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        }
        else {
            this.activeTopbarItem = item;
        }
        event.preventDefault();
    };
    CommonService.prototype.isHorizontal = function () {
        return this.layoutMode === MenuOrientation.HORIZONTAL;
    };
    CommonService.prototype.isSlim = function () {
        return this.layoutMode === MenuOrientation.SLIM;
    };
    CommonService.prototype.hideOverlayMenu = function () {
        this.rotateMenuButton = false;
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    };
    CommonService.prototype.onLayoutClick = function () {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }
        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.resetMenu = true;
            }
            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }
            this.menuHoverActive = false;
        }
        this.topbarItemClick = false;
        this.menuClick = false;
    };
    CommonService.ngInjectableDef = i0.defineInjectable({ factory: function CommonService_Factory() { return new CommonService(); }, token: CommonService, providedIn: "root" });
    return CommonService;
}());
export { CommonService };
