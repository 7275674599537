<p-dialog #dialog [header]="'Xác thực Thông tin người dùng'" styleClass="vs-modal force-full-width" positionTop="50" [(visible)]="isShow" [style]="{'max-width': '1100px'}" [contentStyle]="{'max-height': getMaxDialogHeight()}" [modal]="true" appendTo="body">
    <div class="vs-form-container default-control-label-width vs-modal-content-panel">
        <form action="" [formGroup]="formGroup">
            <div class="vs-form-container default-control-label-width">
                <div class="ui-g row">
                    <div class="ui-g-6">
                        <div class="ui-g-12 ui-lg-12">
                            <div class="vs-form-control ui-g">
                                <div class="__form-control-label ui-g-12 ui-md-4">Số CMND</div>
                                <div class="__form-control-inputs ui-g-12 ui-md-8">
                                    <div class="__input-wrapper">
                                        <input type="text" formControlName="cmt" [(ngModel)]="modelEdit.cmt" placeholder="Nhập số CMND" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ui-g-3">
                        Mặt trước
                        <div style="width: 150px;">
                            <app-vs-file-upload [(ngModel)]="modelEdit.cmtFront" [defaultImage]="true" formControlName="cmtFront" [fileType]='"image/*"'></app-vs-file-upload>
                        </div>
                    </div>
                    <div class="ui-g-3">
                        Mặt sau
                        <div style="width: 150px;">
                            <app-vs-file-upload [(ngModel)]="modelEdit.cmtBackside" [defaultImage]="true" formControlName="cmtBackside" [fileType]='"image/*"'></app-vs-file-upload>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <p-footer>
        <div class="ui-g row">
            <div class="ui-g-12">
                <button class="vs-btn vs-modal-btn vs-btn-action-edit" [disabled]="isLoading || !formGroup.valid" (click)="save()" type="button">
                    <span class="__icon fa fa-floppy-o"></span>
                    <span class="__label">Lưu</span>
                </button>

                <button type="button" (click)="closePopupMethod(null)" class="vs-btn vs-modal-btn vs-btn-action-back">
                    <span class="__icon fa fa-times"></span>
                    <span class="__label">Đóng</span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>