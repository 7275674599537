import * as tslib_1 from "tslib";
import { OnInit, Injector, EventEmitter } from '@angular/core';
import { SecondPageEditBase } from '../../lib-shared/classes/base/second-page-edit-base';
import { User } from '../../lib-shared/models/user';
import { ActiveCommentTypeEnum } from '../../lib-shared/types/activeCommentType.enum';
import { WmTaskCommentLikesService } from '../../work/services/WmTaskCommentLikes.service';
var CommentGComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CommentGComponent, _super);
    function CommentGComponent(_injector, _WmTaskCommentLikesService) {
        var _this = _super.call(this, null, _injector) || this;
        _this._injector = _injector;
        _this._WmTaskCommentLikesService = _WmTaskCommentLikesService;
        _this.deleteComment = new EventEmitter();
        _this.updateComment = new EventEmitter();
        _this.addComment = new EventEmitter();
        _this.setActiveComment = new EventEmitter();
        _this.emitData = new EventEmitter();
        _this.activeCommentType = ActiveCommentTypeEnum;
        _this.replyId = null;
        _this.canEdit = false;
        _this.canDelete = false;
        return _this;
    }
    CommentGComponent.prototype.ngOnInit = function () {
        this.replyId = this.parentId ? this.parentId : this.comment.id;
        this.canEdit = this.currentUser.userId == this.comment.userCreated.userId;
        this.canDelete = this.currentUser.userId == this.comment.userCreated.userId;
    };
    CommentGComponent.prototype.calculateTime = function (createdDate) {
        if (!(createdDate instanceof Date)) {
            createdDate = new Date(createdDate);
        }
        var currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 7);
        var timeDifference = currentDate.getTime() - createdDate.getTime();
        var secondsPerMinute = 60;
        var minutesPerHour = 60;
        var hoursPerDay = 24;
        var daysPerWeek = 7;
        var seconds = Math.floor(timeDifference / 1000);
        var minutes = Math.floor(seconds / secondsPerMinute);
        var hours = Math.floor(minutes / minutesPerHour);
        var days = Math.floor(hours / hoursPerDay);
        var weeks = Math.floor(days / daysPerWeek);
        if (weeks > 0) {
            return weeks + " tu\u1EA7n";
        }
        else if (days > 0) {
            return days + " ng\u00E0y";
        }
        else if (hours > 0) {
            return hours + " gi\u1EDD";
        }
        else if (minutes > 0) {
            return minutes + " ph\u00FAt";
        }
        else if (seconds > 0) {
            return seconds + " gi\u00E2y";
        }
        else {
            return "vừa xong";
        }
    };
    CommentGComponent.prototype.isReplying = function () {
        if (!this.activeComment) {
            return false;
        }
        return (this.activeComment.id === this.comment.id &&
            this.activeComment.type === 'replying');
    };
    CommentGComponent.prototype.isEditing = function () {
        if (!this.activeComment) {
            return false;
        }
        return (this.activeComment.id === this.comment.id &&
            this.activeComment.type === 'editing');
    };
    CommentGComponent.prototype.likeComments = function (idTaskComment, idTask) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this._WmTaskCommentLikesService.Like(idTaskComment, idTask)
                            .then(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var indexUser;
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                if (response.status) {
                                    this.comment.userLiked = !response.data.isDeleted;
                                    if (!response.data.isDeleted) {
                                        this.comment.countLike++;
                                        this.comment.likeUser.push({
                                            userId: this.currentUser.userId,
                                            name: this.currentUser.userName
                                        });
                                    }
                                    else {
                                        this.comment.countLike--;
                                        indexUser = this.comment.likeUser.findIndex(function (x) { return x.userId == _this.currentUser.userId; });
                                        this.comment.likeUser.splice(indexUser, 1);
                                    }
                                }
                                return [2 /*return*/];
                            });
                        }); }, function () {
                            _this._notifierService.showHttpUnknowError();
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return CommentGComponent;
}(SecondPageEditBase));
export { CommentGComponent };
