<div *ngIf="apiUrl == ''">
  <span>Vui lòng cấu hình địa chỉ file upload từ module</span>
</div>
<div *ngIf="apiUrl != ''">
          
      <div class="box-file-upload">
          <div *ngFor="let item of listUploaded" class="item">
            <a *ngIf="item.type == 1">
                <img src="{{urlImageAvatar(item.path)}}" />
            </a>
            <a *ngIf="item.type != 1">
                <img src="/assets/images/file-ext/icon{{item.extention}}.png" />
            </a>
            <div class="overlay" (click)="viewFile(item) ">
                <div class="fileName">
                    {{item.fileName}}
                </div>
                  <!-- <button type="button" (click)="viewFile(item)" class="vs-btn icon-only view" pTooltip="Xem">
                      <span class="__icon fa fa-eye"></span>
                  </button> -->
              </div>
              <div class="tool">
                <button *ngIf="multiple == false" type="button" (click)="selectFileUpload(item)" pTooltip="Tải lên ảnh khác" class="vs-btn">
                    <span class="__icon fa fa-upload"></span>
                </button>
                <button type="button" (click)="downloadFile(item)" pTooltip="Tải xuống" class="vs-btn ml-5">
                    <span class="__icon fa fa-download"></span>
                </button>
                  <button type="button" (click)="deleteFile(item.id)" pTooltip="Xóa ảnh" [hidden]="viewOnly" class="vs-btn delete ml-5">
                      <span class="__icon fa fa-times"></span>
                  </button>
                </div>
              <!-- <div class="overlay">
              </div>                  -->
          </div>

          <div class="option-upload">
            <div *ngIf="styleButton == false && (multiple == true || (multiple == false && listUploaded.length == 0))" class="item-null {{defaultImage == true ? 'defaultImage' : ''}}" (click)="selectOptionFileUpload($event)" [hidden]="viewOnly">
                <span *ngIf="defaultImage == false && (progressValue == 0 || progressValue == 100)" class="fa fa-plus"></span>
                <svg  *ngIf="defaultImage == false && (progressValue != 0 && progressValue != 100)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;display:block;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                  <g transform="rotate(0 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#CCC">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
                    </rect>
                  </g><g transform="rotate(30 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#CCC">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                    </rect>
                  </g><g transform="rotate(60 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#CCC">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
                    </rect>
                  </g><g transform="rotate(90 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#CCC">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                    </rect>
                  </g><g transform="rotate(120 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#CCC">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
                    </rect>
                  </g><g transform="rotate(150 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#CCC">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
                    </rect>
                  </g><g transform="rotate(180 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#CCC">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
                    </rect>
                  </g><g transform="rotate(210 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#CCC">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                    </rect>
                  </g><g transform="rotate(240 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#CCC">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
                    </rect>
                  </g><g transform="rotate(270 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#CCC">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                    </rect>
                  </g><g transform="rotate(300 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#CCC">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
                    </rect>
                  </g><g transform="rotate(330 50 50)">
                    <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#CCC">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  </svg>
            </div>         
            <div class="select-option" *ngIf="showOption == true">
              <div class="btn upload-local" (click)="selectFileUpload()"><i class="fa fa-desktop"></i>Chọn từ máy</div>
              <div class="btn upload-lib" (click)="selectFileUploadLib()"><i class="fa fa-file"></i> Chọn trong tài liệu</div>
            </div>
          </div>

          <div *ngIf="styleButton == true && (multiple == true || (multiple == false && listUploaded.length == 0))">
            <a [hidden]="viewOnly" (click)="selectFileUpload()" class="btn" pTooltip="Tải file">
                <i class="fa fa-upload"></i>Tải lên
            </a>
          </div>

          <!-- <div class="item-download-all" *ngIf="filesUploaded.length > 0" [hidden]="!viewOnly">
              <span (click)="downloadFileAll()" class="fa fa-download" pTooltip="Tải tất cả"></span>
          </div> -->
          <!-- <div class="item-download-all" [hidden]="!viewOnly">
              <button type="button" (click)="downloadFile(item)" class="vs-btn icon-only download" pTooltip="Tải ảnh">
                  <span class="__icon fa fa-download"></span>
              </button>
          </div> -->
 
      </div>

  <div [hidden]="true"> <!--  *ngIf="isNullValued" -->
      <p-fileUpload (onBeforeUpload)="onBeforeUpload()" [ngStyle]="{'display': showIconOnly? 'none' : ''}" id="single-file-upload"
          #fileControl [disabled]="disabled" [multiple]="multiple" (onSelect)="onSelect($event)" (onError)="onError($event)" (onBeforeSend)="onBeforeSend($event)"
          [chooseLabel]="chooseLabel" accept="{{fileType}}" (onUpload)="onUploadEvent($event)" mode="basic" name="files" url="{{apiUrl}}" maxFileSize="maxFileSize"
          auto="true" (onProgress)="onProgress($event)">
          <!-- allowTypes="/(\.|\/)(gif|jpe?g|png|doc|docx|pdf|xls|xlsx|ppt|pptx|rtf|rar|zip)$/" -->
          <!-- 10 MB -->
      </p-fileUpload>
      <div *ngIf="showIconOnly">
          <a (click)="selectFileUpload()">
              <i class="{{icon}}"></i>
          </a>
      </div>
  </div>
</div>
<file-viewer></file-viewer>
