import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/primeng';
import { environment } from '../../../../../environments/environment';
import { ComponentBase } from '../../../classes/base/component-base';
var VsFileViewerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(VsFileViewerComponent, _super);
    function VsFileViewerComponent(config, ref) {
        var _this = _super.call(this) || this;
        _this.config = config;
        _this.ref = ref;
        _this.listFile = [];
        _this.file = {
            filenName: '',
            path: '',
            type: 1,
            url: ''
        };
        return _this;
    }
    VsFileViewerComponent.prototype.ngOnInit = function () {
        var file = this.config.data;
        var fileType = this.getFileType(file.fileName);
        var documentType = this.getDocumentType(fileType);
        var configsssss = {
            'documentType': "" + documentType,
            'document': {
                'fileType': "" + fileType,
                "key": "" + file.id,
                'title': "" + file.fileName,
                'url': "" + environment.apiDomain.fileEndpoint + file.path
            },
            "editorConfig": {
                "mode": "view",
            }
            // "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.e30.LwimMJA3puF3ioGeS-tfczR3370GXBZMIL-bdpu4hOU"
        };
        var docEditor = new DocsAPI.DocEditor('fileviewer', configsssss);
    };
    VsFileViewerComponent.prototype.onNext = function () {
        var currentIndex = this.listFile.indexOf(this.file);
        var nextIndex = (currentIndex + 1) % this.listFile.length;
        this.file = this.listFile[nextIndex];
        this.file.url = this.urlImageOriginal(this.file.path);
    };
    VsFileViewerComponent.prototype.onPrev = function () {
        var currentIndex = this.listFile.indexOf(this.file);
        var prevIndex = 0;
        if (currentIndex <= 0)
            prevIndex = this.listFile.length - 1;
        else
            prevIndex = currentIndex - 1;
        // prevIndex = (currentIndex - 1) % this.listFile.length;
        this.file = this.listFile[prevIndex];
        this.file.url = this.urlImageOriginal(this.file.path);
    };
    VsFileViewerComponent.prototype.onKeydownHandler = function (event) {
        // event.preventDefault();
        if (event.key === 'Escape') {
            this.ref.close(null);
        }
    };
    VsFileViewerComponent.prototype.closePopup = function () {
        this.ref.close(null);
    };
    VsFileViewerComponent.prototype.getFileType = function (fileName) {
        var arr = fileName.split('.');
        return arr[arr.length - 1];
    };
    VsFileViewerComponent.prototype.getDocumentType = function (fileType) {
        if ((/(gif|jpg|jpeg|tiff|png)$/i).test(fileType)) {
            return 'image';
        }
        else if ((/(doc|docm|docx|dot|dotm|dotx|epub|fodt|htm|html|mht|odt|ott|pdf|rtf|txt|djvu|xps)$/i).test(fileType)) {
            return 'text';
        }
        else if ((/(csv|fods|ods|ots|xls|xlsm|xlsx|xlt|xltm|xltx)$/i).test(fileType)) {
            return 'spreadsheet';
        }
        else if ((/(fodp|odp|otp|pot|potm|potx|pps|ppsm|ppsx|ppt|pptm|pptx)$/i).test(fileType)) {
            return 'presentation';
        }
        return '';
    };
    return VsFileViewerComponent;
}(ComponentBase));
export { VsFileViewerComponent };
