
import { Component, OnInit, Input, EventEmitter, Output, forwardRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgbModalRef, NgbModal } from '../../../../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { UserService } from '../../../services/user.service';
import { VsFileItem } from '../../file-upload/models/file-item';
import { FileViewerType, VsFileUploadService } from '../services/file-upload.service';
import { ConfirmationService, DialogService, DynamicDialogRef } from 'primeng/primeng';
import { VsFileViewerComponent } from '../vs-file-viewer/vs-file-viewer.component';
import { ComponentBase } from '../../../classes/base/component-base';
import { environment } from '../../../../../environments/environment';
import { FilesService } from '../services/files.service';
import { FoldersService } from '../services/folders.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-vs-list-file',
  templateUrl: './vs-list-file.component.html',
  styleUrls: ['./vs-list-file.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VsListFileComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class VsListFileComponent extends ComponentBase implements OnInit, ControlValueAccessor {
  files: VsFileItem[] = [];
  folders: VsFileItem[] = [];
  disabled = false;
  ids = [];
  userId: number;
  linkView = '';
  selectedFile: VsFileItem = new VsFileItem();
  modalReference: NgbModalRef;
  ref: DynamicDialogRef;
  @ViewChild('fileViewer') fileViewer: NgbModal;

  @Input() currentIds = '';
  @Input() viewOnly = false;
  @Input() isSingleFile = false;
  @Input() isViewList = false;
  @Output() onUploaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectItem: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(FileViewerComponent) _FileViewerComponent: FileViewerComponent;

  constructor(
    private _fileService: VsFileUploadService,
    private ngbModal: NgbModal,
    private _userService: UserService,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private fileServices: FilesService,
    private folderServices: FoldersService
  ) {
    super();
  }

  ngOnInit() {
    this.userId = this._userService.getBasicUserInfo().userId;
  }

  onChangeControl = (obj: any) => {
    console.log(obj);
  };
  onTouched = () => { };

  writeValue(obj: VsFileItem[]): void {
    if (obj) {
      this.files = obj;
      // this.folders = this.files.filter(s => s.typeId == 2)
      // console.log(this.folders);
      console.log(this.files);

    }
  }

  registerOnChange(fn: any): void {
    this.onChangeControl = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // downloadFile(item) {
  //   window.open(this._fileService.getLinkDownload(item.id));
  // }

  openFile(item: VsFileItem) {

  }

  deleteFile(item: VsFileItem) {
    this.confirmationService.confirm({
      message: 'Bạn có chắc chắn muốn xóa file "' + item.rawFileName + '" không ?',
      header: 'Xác nhận xóa',
      icon: 'pi pi-trash',
      accept: () => {
        if (item.typeId === 2) {
          this._fileService.deleteFolder(this.userId, item.id);
          const fileToRemove = this.files.findIndex(x => x.fileName === item.fileName);
          this.files.splice(fileToRemove, 1);
        } else {
          this._fileService.deleteFile(item);
          const fileToRemove = this.files.findIndex(x => x.fileName === item.fileName);
          this.files.splice(fileToRemove, 1);
          this.onChangeControl(this.files);
          this.onRemoved.next(item);
        }
      }
    });
  }

  deleteFileTemp(item: VsFileItem) {
    if (item.typeId === 2) {
      const fileToRemove = this.files.findIndex(x => x.fileName === item.fileName);
      this.files.splice(fileToRemove, 1);
    } else {
      const fileToRemove = this.files.findIndex(x => x.fileName === item.fileName);
      this.files.splice(fileToRemove, 1);
      this.onChangeControl(this.files);
      this.onRemoved.next(item);
    }
  }

  viewFile(item: VsFileItem) {
    // Nếu file là hình ảnh hoặc video thì dùng FileViewer, Nếu là tài liệu thì dùng VsFileViewer
    if (item.type == 1 || item.type == 3)
      this._FileViewerComponent.showPopupObject(item, this.files);
    else {
      this.ref = this.dialogService.open(VsFileViewerComponent, {
        data: item,
        showHeader: true,
        header: item.fileName,
        width: '100%',
        height: 'calc(100vh - 0px)',
        styleClass: "vs-modal vs-file-viewer",
        contentStyle: { 'overflow': 'auto' }, //'max-height': 'calc(100vh - 180px);', 
        baseZIndex: 1010,
        closeOnEscape: true
      });
    }
  }

  async onItemClick(item: VsFileItem) {
    this.selectedFile = new VsFileItem();
    if (item.typeId === 2) {
      this.onChangeControl(this.files);
      this.onClick.next(item);
    } else {
      this.viewFile(item);
    }
  }

  checkItem(item) {
    if (this.isSingleFile) {
      this.files.forEach(element => {
        if (element.id !== item.id) {
          element.checked = false;
        }
      });
      if (item.checked) {
        this.ids = [];
        this.ids.push(item.id);
        item.checked = true;
      } else {
        this.ids.splice(this.ids.indexOf(item.id), 1);
        item.checked = false;
      }
      this.onChangeControl(this.ids);
      this.onSelectItem.next(this.ids + '');
    } else {
      this.ids = this.currentIds.split(',').filter(s => s !== '');
      if (item.checked) {
        // Kiểm tra nếu Id chưa có trong currentIds thì bổ sung
        if (this.currentIds.indexOf(item.id + ',') < 0 && this.currentIds.indexOf(',' + item.id) < 0) {
          this.ids.push(item.id);
          item.checked = true;
          this.onSelectItem.next(this.ids + ',');
        }
      } else {
        // Kiểm tra nếu Id có trong currentIds thì remove để xóa
        this.currentIds = this.currentIds.replace(item.id + ',', '');
        this.currentIds = this.currentIds.replace(',' + item.id, '');
        this.ids.splice(this.ids.indexOf(item.id), 1);
        item.checked = false;
        this.onSelectItem.next(this.currentIds);
      }
      this.onChangeControl(this.ids);
    }


    // alert(this.ids + this.currentIds);
  }

  onClose() {
    this.modalReference.close();
  }

  public(item: any) {
    this.confirmationService.confirm({
      message: 'Bạn có chắc chắn muốn chia sẻ tệp tin này không ?',
      header: 'Xác nhận chia sẻ',
      icon: 'pi pi-globe',
      accept: () => {
        // 2 là folder, 1 file
        if (item.typeId == 2) {
          this.folderServices.public(item.id).then(rs => {
            if (rs.status) {

            };
          });
        }
        else {
          this.fileServices.public(item.id).then(rs => {
            if (rs.status) {

            };
          });
        }
      }
    });
  }

}
