import { Component, OnInit, forwardRef, Input, ViewChild, Injector, ElementRef, QueryList, ViewChildren, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ConfirmationService, DialogService, DynamicDialogRef, MessageService } from 'primeng/primeng';
import { environment } from '../../../../../environments/environment';
import { FileViewerComponent } from '../../file-upload/file-viewer/file-viewer.component';
import { VsFileUploadService } from '../../file-upload/services/file-upload.service';
import { SecondPageEditBase } from '../../../classes/base/second-page-edit-base';
import { VsFileViewerComponent } from '../vs-file-viewer/vs-file-viewer.component';

@Component({
  selector: 'app-vs-file-view-feed',
  templateUrl: './vs-file-view-feed.component.html',
  styleUrls: ['./vs-file-view-feed.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VsFileViewFeedComponent),
      multi: true
    }
  ]
})
export class VsFileViewFeedComponent extends SecondPageEditBase implements OnInit, ControlValueAccessor {
  disabled = false;
  listUploaded: any[];
  progressValue = 0;
  ref: DynamicDialogRef;

  @Input() chooseLabel = 'Chọn file';
  @Input() multiple = false;
  @Input() styleButton = false;
  @Input() defaultImage = false;
  @Input() showIconOnly = false;
  @Input() icon = 'fa fa-upload';
  @Input() viewOnly = false;
  @Input() fileType = '';    // '' , image/* , video/* , 
  videos: any;
  @ViewChild('videoElement') videoElement: ElementRef;

  @ViewChild(FileViewerComponent) _FileViewerComponent: FileViewerComponent;
  constructor(
    protected _injector: Injector,
    private confirmationService: ConfirmationService,
    private _fileUploadService: VsFileUploadService,
    public dialogService: DialogService,
    private elementRef: ElementRef,
  ) {
    super(null, _injector);
  }

  ngOnInit() {
    this.waitForVideosToLoad();
  }

  isVerticalVideo(item: any): boolean {
    if (!item || !item.path) return false;
    if (!this.videoElement) return false;
    const video: HTMLVideoElement = this.videoElement.nativeElement;
    if (video.videoWidth > video.videoHeight) {
      return false;
    } else {
      return true;
    }
  }
  waitForVideosToLoad() {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const videos = this.elementRef.nativeElement.querySelectorAll('video');
          if (videos.length > 0) {
            this.setupIntersectionObserver(videos);
            observer.disconnect();
            this.fakeUserInteraction();
            break;
          }
        }
      }
    });

    observer.observe(this.elementRef.nativeElement, { childList: true, subtree: true });
  }

  fakeUserInteraction() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  }

  handleScroll() {
    console.log(1);
  }

  setupIntersectionObserver(videos: NodeListOf<Element>) {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        const videoElement = entry.target as HTMLVideoElement;
        if (entry.isIntersecting) {
          this.playVideo(videoElement);
        } else {
          this.pauseVideo(videoElement);
        }
      });
    }, { threshold: 0.5 });

    videos.forEach(video => {
      observer.observe(video);
    });
  }

  playVideo(videoElement: HTMLVideoElement) {
    videoElement.muted = true;
    videoElement.play()
    // .catch(error => {
    //   console.error('Error playing video:', error);
    // });
  }

  pauseVideo(videoElement: HTMLVideoElement) {
    videoElement.pause();
  }




  onChangeControl = (obj: any) => { };
  onTouched = () => { };

  writeValue(obj: any): void {
    this.listUploaded = [];
    if (obj) {
      this.listUploaded = obj.split(",");

      this._fileUploadService.GetsByIds(obj).then(rs => {
        if (rs.status) {
          this.listUploaded = rs.data.reverse();

        }
      }).catch(err => {
        console.log('Có lỗi xảy ra, vui lòng thử lại ' + err);
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeControl = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  deleteFile(id) {
    this.confirmationService.confirm({
      message: 'Bạn có chắc chắn muốn xóa file này không ?',
      accept: () => {
        const fileToRemove = this.listUploaded.findIndex(x => x.id === id);
        this.listUploaded.splice(fileToRemove, 1);
        let files = this.listUploaded.map(x => x.id).join(",");
        this.onChangeControl(files);
        this.writeValue(files);
      }
    });
  }

  viewFile(item) {
    // Nếu file là hình ảnh hoặc vido thì dùng FileViewer, Nếu là tài liệu thì dùng VsFileViewer
    if (item.type == 1 || item.type == 3)
      this._FileViewerComponent.showPopupObject(item, this.listUploaded);
    else {
      this.ref = this.dialogService.open(VsFileViewerComponent, {
        data: item,
        showHeader: true,
        header: item.fileName,
        width: '100%',
        height: 'calc(100vh - 0px)',
        styleClass: "vs-modal vs-file-viewer",
        contentStyle: { 'overflow': 'auto' }, //'max-height': 'calc(100vh - 180px);', 
        baseZIndex: 1010,
        closeOnEscape: true
      });

    }
  }

  async downloadFile(item: any) {
    var url = `${environment.apiDomain.fileEndpoint}/${item.path}`;
    //if (item.extention == ".jpeg" || item.extention == ".png" || item.extention == ".jpg" || item.extention == ".bmp" || item.extention == ".gif" || item.extention == ".bmp" || item.extention == ".apng" || item.extention == ".ico" || item.extention == ".webp" || item.extention == ".avif")
    if (item.type == 1)
      url = `${environment.apiDomain.fileEndpoint}/Original/${item.path}`;

    fetch(url)
      .then(async (response) => {
        const blob = await response.blob();

        const urlDownload = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = urlDownload;
        link.setAttribute("download", item.fileName);
        link.click();
      }
      )
    return false;
  }

  downloadFileAll() {
    this.listUploaded.forEach(element => {
      this.downloadFile(element);
    });
  }

}
